import React from "react";

import { Box, Button, Card, Dialog, Grid, Typography } from '@mui/material';
import { TCInput, TCSelect } from "components/TopCourtComponents/Input/TopCourtInput";
import { getPlayers } from "services/playerServices";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { orderStatusOption, orderTypeOption } from "constant/constant";
import { TCSelectStyle } from "components/TopCourtComponents/Input/TopCourtInput";
import DateRangeInput from "components/TopCourtComponents/Input/DateRangeFilter";

export default function OrderFilter({
    filter,
    onChangeFilter,
    clearFilter
}) {
    const loadUserOption = async (input) => {
        if (input.length < 3) {
            return
        }
        try {
            let { data, error } = await getPlayers({
                name: input
            });
            if (error) throw error
            if (data) {
                if (data.length <= 0) return
                return data.map(value => {
                    return {
                        label: value.name,
                        value: value._id
                    }
                })
            }
        } catch (error) {
            console.log(error.message || error)
            return ([])
        }
    }

    return (
        <Card sx={{ mb: 1 }}
            className="custom-card-style">
            <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                {/* filter date, booking id, user, type, status */}
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} md={3} sx={{ mb: 1 }}>
                        <DateRangeInput
                            label={'Booking Date'}
                            showLabel={true}
                            startDate={filter.startCreatedDate}
                            endDate={filter.endCreatedDate}
                            dateRangeType={filter.createdDateVariable}
                            onChangeSelect={(e) => onChangeFilter({ createdDateVariable: e })}
                            onChangeStartDate={(e) => onChangeFilter({ startCreatedDate: e })}
                            onChangeEndDate={(e) => onChangeFilter({ endCreatedDate: e })}
                        />
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ mb: 1 }}>
                        <TCInput placeHolder="Booking Id" onChange={(e) => onChangeFilter({ bookingId: e.currentTarget.value.toUpperCase() })} value={filter.bookingId} label={"Booking Id"} />
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ mb: 1 }}>
                        <TCLabel style={{fontSize: 13, color: "rgb(133, 133, 133)"}}>User</TCLabel>
                        <AsyncSelect
                            styles={{
                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                ...TCSelectStyle,
                            }}
                            placeholder={'Select User...'}
                            isClearable
                            async
                            defaultOptions
                            loadOptions={loadUserOption}
                            value={filter.user} onChange={(e) => { onChangeFilter({ user: e }) }}
                        />
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ mb: 1 }}>
                        <TCSelect label={'Order Type'} options={orderTypeOption} value={filter.type} onChange={(e) => {
                            onChangeFilter({ type: e })
                        }} />
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ mb: 1 }}>
                        <TCSelect label={'Status'} options={orderStatusOption} value={filter.status} onChange={(e) => {
                            onChangeFilter({ status: e })
                        }} />
                    </Grid>
                    <Grid item xs={12} md={1} sx={{ mb: 1, textAlign: 'right' }} >
                        <Button sx={{ mt: 2, border: '0.5px solid #2196f3', borderRadius: 4 }}
                            onClick={clearFilter}
                        >Clear</Button>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    )
}
