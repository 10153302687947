import React, { useState, useEffect } from "react";

import { dayOptions, timeOptions, operationalHours } from "constant/constant";
import { Grid, Switch } from "@mui/material";
import Select from 'react-select';
import { maxWidth } from "@mui/system";
import { TCLabel, TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";

function OperationalHoursComponent({
    setCourt,
    currOpsHours,
    showSummary,
    setShowSummary
}) {
    const [disableSummary, setDisableSummary] = useState(false)
    const [ status, setStatus ] = useState(true)
    const [ reload, setReload ] = useState(Date.now())
    const [ opsHours, setOpsHours ] = useState(operationalHours)

    useEffect(() => {
        if (currOpsHours.length > 0) {
            setOpsHours(currOpsHours)
        }
    }, [currOpsHours])

    useEffect(()=>{
        setShowSummary({showSummary: false})
    },[disableSummary===false])

    useEffect(() => {
        checkOpsHours()
    }, [opsHours, reload])

    const onChangeOperationalHours = (e, index) => {
        let currData = opsHours[index];
        currData = {
            ...currData,
            ...e
        }
        let newOps = opsHours;
        newOps.splice(index, 1, currData)
        setOpsHours(prev => (newOps))
        setCourt({
            operationalHours: newOps
        })
        setReload(Date.now())
    }

    const checkOpsHours = () => {
        let allow = true
        opsHours.forEach((value, index)=>{
            if(value.isActive!==true){
                allow = false
            }
        })
        setDisableSummary(!allow)
    }

    return (
        <Grid>
            <Switch checked={status} onChange={() => setStatus(!status)}/>
            {
                opsHours.map( (value, index) => {
                    return(
                        <Grid sx={{
                            mt:1
                        }}>
                            <TCLabel>{value.day}</TCLabel>
                            <div style={{display: 'flex'}}>
                                <Grid sx={{
                                    minWidth: 100,
                                    maxWidth: 100,
                                    mr: 1
                                }}>
                                    <Select isDisabled={!status || !value.isActive} value={{
                                        label: value.operationalHours.start,
                                        value: value.operationalHours.start,
                                    }} onChange={(e) => {
                                        console.log(e)
                                        onChangeOperationalHours({operationalHours: {
                                            start: e.value,
                                            end: value.operationalHours.end
                                        }}, index)
                                    }} options={timeOptions}/>
                                </Grid>
                                <Grid sx={{
                                    minWidth: 100,
                                    maxWidth: 100
                                }}>
                                    <Select isDisabled={!status || !value.isActive} value={{
                                        label: value.operationalHours.end,
                                        value: value.operationalHours.end,
                                    }} onChange={(e) => {
                                        console.log(e)
                                        onChangeOperationalHours({operationalHours: {
                                            start: value.operationalHours.start,
                                            end: e.value
                                        }}, index)
                                    }} options={timeOptions}/>
                                </Grid>
                                <Switch checked={value.isActive} onChange={() => onChangeOperationalHours({isActive: !value.isActive}, index)}/>
                            </div>
                        </Grid>
                    )
                })
            }
            <Grid sx={{mt:1}}>
                <TCLabel >Summarize Operational Hours</TCLabel>
                <Switch value={showSummary} disabled={disableSummary} checked={showSummary} onChange={()=>setShowSummary({showSummary: !showSummary})}/>
            </Grid>
        </Grid>
    )
}

export default OperationalHoursComponent