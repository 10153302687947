import React from "react";

import { Box, Button, Card, Dialog, Grid, Typography } from '@mui/material';
import { TCInput, TCSelect } from "components/TopCourtComponents/Input/TopCourtInput";
import { getPlayers } from "services/playerServices";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { AddonTypeOption, activeOption, orderStatusOption, orderTypeOption } from "constant/constant";
import NumberRangeInput from "components/TopCourtComponents/Input/NominalRangeFilter";

export default function AddonFilter({
    filter,
    onChangeFilter,
    clearFilter
}) {

    return (
        <Card sx={{ mb: 1 }}
            className="custom-card-style">
            <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                {/* filter date, booking id, user, type, status */}
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} md={2} sx={{ mb: 1 }}>
                        <TCInput placeHolder="Name" onChange={(e) => onChangeFilter({ name: e.currentTarget.value })} value={filter.name} label={"Name"} />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ mb: 1 }}>
                        <NumberRangeInput
                            showLabel={true}
                            label={"Stock"}
                            placeholderStart={'Min'}
                            placeholderEnd={'Max'}
                            allowZero={false}
                            numberRangeType={filter.stockType}
                            start={filter.stockMin}
                            end={filter.stockMax}
                            onChangeSelect={(e) => onChangeFilter({ stockType: e })}
                            onChangeStart={(e) => onChangeFilter({ stockMin: e.replace(/[^0-9]+/ig, '') })}
                            onChangeEnd={(e) => onChangeFilter({ stockMax: e.replace(/[^0-9]+/ig, '') })}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ mb: 1 }}>
                        <TCSelect label={'Type'} options={AddonTypeOption} value={filter.type} 
                            isMulti={true}
                            onChange={(e) => {
                            onChangeFilter({ type: e })
                        }} />
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ mb: 1 }}>
                        <TCSelect label={'Status'} options={activeOption} value={filter.status} onChange={(e) => {
                            onChangeFilter({ status: e })
                        }} />
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ mb: 1, textAlign: 'right' }} >
                        <Button sx={{mt: 2, border: '0.5px solid #2196f3', borderRadius: 4}}
                            onClick={clearFilter}
                        >Clear</Button>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    )
}
