import React, { useState, useEffect} from "react"

import { Button, Card, CardContent, Grid } from "@mui/material"
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput"
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel"

import { courtTypeOption, dayOptions, timeOptions } from "constant/constant"

import Select from "react-select";

function PriceListComponent ({
    data,
    isException,
    index,
    savePrice
}) {

    const [ priceForm, setPriceForm ] = useState({
        price: 0,
        ...data,
        days: (data && data.dayString) ? getOptionObject({value: data.dayString,options: dayOptions}) : "",
        type: (data && data.courtType) ? getOptionObject({value: data.courtType,options: courtTypeOption}) : "",
        startHours: (data && data.startHours) ? getOptionObject({value: data.startHours,options: timeOptions}) : "",
        endHours: (data && data.endHours) ? getOptionObject({value: data.endHours,options: timeOptions}) : "",
    })

    // useEffect(() => {
    //     setPriceForm({
    //         ...data,
    //         // type
    //     })
    // }, [data])

    const onChangeForm = (e) => {
        setPriceForm(prev => ({
            ...prev,
            ...e
        }))
    }

    useEffect(() => {
        console.log('here is priceForm', priceForm)
        savePrice(priceForm, index)
    }, [priceForm])
    
    return (
        <Card sx={{
            mt: 1,
            boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;"
          }}>
            <Grid p={2} container>
                {/* <div style={{
                    width: '100%',
                    textAlign: 'right'
                }}>{data._id ? "Delete" : "X"}</div> */}
                <Grid item xs={12} sx={{textAlign: 'right'}}>{data._id ? "Delete" : "X"}</Grid>
                <Grid item container xs={12}>
                    <Grid item xs={12} md={2} sx={{p: 1}}>
                        <TCLabel>Day</TCLabel>
                        <Select 
                            menuPortalTarget={document.body}
                            isDisabled={!isException}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            value={!isException ? {
                                value: 'all',
                                label: "All Days"
                            } : priceForm.days}
                            onChange={(e) => {
                                onChangeForm({
                                    days: e
                            }) }}
                            options={dayOptions}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} sx={{p: 1}}>
                        <TCLabel>Court Type</TCLabel>
                        <Select 
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            value={priceForm.type} onChange={(e) => {
                            onChangeForm({
                                type: e
                            }) }} options={courtTypeOption}/>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{p: 1}}>
                        <TCLabel>Range Hours</TCLabel>
                        <Grid container direction={"row"}>
                            <Grid item xs={6} pr={1}>
                                <Select
                                    menuPortalTarget={document.body}
                                    value={priceForm.startHours}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    options={timeOptions}
                                    onChange={(e) => {
                                        onChangeForm({startHours: e})
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} pl={1}>
                                <Select
                                    menuPortalTarget={document.body}
                                    value={priceForm.endHours}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    options={timeOptions}
                                    onChange={(e) => {
                                        onChangeForm({endHours: e})
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{p: 1}}>
                        <TCLabel>Price</TCLabel>
                        <TCInput value={priceForm.price} onChange={(e) => {
                            onChangeForm({
                                price: e.currentTarget.value
                            })
                        }} placeHolder="Input price"></TCInput>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}

function getOptionObject ({
    value, options
}) {

    console.log(value, options)

    let selectedValue = null
    for (let i = 0; i < options.length; i ++) {
        if (options[i].value.toString() === value.toString()) {
            selectedValue = options[i]
        }
    }
    console.log('here is selectedValue', selectedValue)
    return selectedValue
}

export default PriceListComponent