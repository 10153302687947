import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";
import { client, errorValidation } from "./service.js";

export async function login({
    username, password
}) {
    try {
        let response = await client.post(`${adminUrl}/login`, {
            username, password
        });

        if (response.data.statusCode !== 200) {
            throw response.data
        }

        return { data: response.data };
    } catch (e) {
        return errorValidation(e)
    }
}

export async function logout() {
    try {
        // let response = await client.get(`${adminUrl}/logout`);
        localStorage.clear()
        window.location.href = "/login"

        // if (response.data.statusCode !== 200) {
        //     throw response.data
        // }

        // return { data: response.data };
        return { data: null };
    } catch (e) {
        return errorValidation(e)
    }
}