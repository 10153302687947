import React, { useEffect, useState } from 'react';
import Select from 'react-select';

// material-ui
import { Box, Button, Card, Grid, Input, Typography, TextareaAutosize, TextField, Switch, FormControlLabel, Checkbox, Icon } from '@mui/material';

// project imports
import { TCInput, TCSelect } from 'components/TopCourtComponents/Input/TopCourtInput';
import { TCLabel, TCTitleLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';
import { getParamsFromURL } from 'helper/Params';
import { createAdmin, getAdmin, updateAdmin } from 'services/adminServices';
import { roleOption } from 'constant/constant';
import { Confirm } from 'helper/Alert';
import { validation } from 'helper/Form';

import { IconLock, IconLockOpen } from '@tabler/icons';

import { toast } from 'react-toastify';

//start here
export default function AddAdmin() {
    const [form, setForm] = useState({
        username: '',
        name: '',
        password: '',
        phoneNumber: '',
        limitedAccess: '',
        role: null,
        isActive: true
    })
    const [confirmPassword, setConfirmPassword] = useState('')
    const [joi, setJOI] = useState({
        name: "",
        username: "",
        role: null,
    })

    useEffect(() => {
        let { params } = getParamsFromURL(window);
        if (params) getAdminDetail(params)
    }, [])

    useEffect(() => {
        setJOI(prev => ({
            name: !validation.isNotEmpty(form.name) ? "Required" : "",
            username: !validation.isNotEmpty(form.username) ? "Required" : "",
            role: !form.role || !validation.isNotEmpty(form.role) ? "Required" : "",
            confirmPassword: form.password ? (form.password === confirmPassword) ? null : " must be same as Password" : ""
        }))
    }, [form, confirmPassword])

    const onChange = (e) => {
        if (e.phoneNumber && e.phoneNumber.length > 14) {
            setForm(prev => ({
                ...prev,
            }))
        } else if(e.name && e.name.length>=21){
            setForm(prev => ({
                ...prev,
            })) 
        } else if(e.limitedAccess && e.limitedAccess.length>=21){
            setForm(prev => ({
                ...prev,
            })) 
        } else {
            setForm(prev => ({
                ...prev,
                ...e
            }))
        }
    }

    const getAdminDetail = async (id) => {
        try {
            let { data, error } = await getAdmin(id)
            if (error) throw error
            if (data) {
                let currRole = roleOption.filter(value => { if (value.value === data.role) { return value } })
                setForm({ ...data, role: currRole, password:'' })
            }
        } catch (error) {
            console.log(error)
        }
    }


    const updateOrCreate = () => {
        if (form._id) {
            update()
        } else {
            create()
        }
    }

    const create = async () => {
        try {
            if (form.password && confirmPassword) {
                let body = { ...form }
                if (body.role) {
                    body.role = body.role.value
                }
                let { data, error } = await createAdmin(body)
                if (error) throw error
                if (data) {
                    setForm(data)
                    Confirm("", "Admin created")
                    window.location.replace(`/admins/edit/${data._id}`)
                }
            }
            else {
                console.log('required password')
                return
            }
        } catch (error) {
            console.log(error)
        }
    }

    const update = async () => {
        try {
            let body = { ...form }
            console.log('here body', body)
            if (body.role) {
                body.role = body.role.value
            }
            let { data, error } = await updateAdmin(body)
            if (error) throw error
            if (data) {
                // setForm(data)
                console.log('success')
                window.location.replace(`/admins/edit/${body._id}`)
            }
        } catch (error) {
            console.log('here error', error)
        }
    }

    return (
        <>
            <Grid item container xs={12} md={12} lg={12}>
                <Grid xs={12} md={6} lg={6} sx={{ p: 1 }}>
                    <Card sx={{ mb: 3 }}>
                        <Box sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: 3
                        }}>
                            <Grid container sx={{ p: 2 }}>
                                {/* <Grid xs={12} md={12} sx={{ p: 1 }}>
                                    <TCTitleLabel>{form._id ? 'Update Admin' : 'Add Admin'}</TCTitleLabel>
                                </Grid> */}
                                <Grid item container xs={12} md={12}>
                                    <Grid xs={10} md={10} sx={{ p: 1 }}>
                                        <TCInput disabled={getParamsFromURL(window).params ? true : false}
                                            showAlert={joi.username}
                                            label={"Username*"} onChange={(e) => onChange({ username: e.currentTarget.value.trim() })} value={form.username} />
                                    </Grid>
                                    <Grid xs={2} md={2} sx={{ p: 1 }} >
                                        <TCLabel style={{ marginLeft: 10 }}>Status</TCLabel>
                                        <Switch checked={form.isActive} onChange={() => onChange({ isActive: !form.isActive })} />
                                    </Grid>
                                </Grid>
                                <Grid xs={12} md={12} sx={{ p: 1 }}>
                                    <TCInput label={"Name*"} showAlert={joi.name} onChange={(e) => onChange({ name: e.currentTarget.value.trimStart() })} value={form.name} />
                                </Grid>
                                <Grid xs={12} md={12} sx={{ p: 1 }}>
                                    <TCSelect label={"Role*"} options={roleOption} showAlert={joi.role} value={form.role} onChange={(e) => {
                                        onChange({ role: e })
                                    }} />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ p: 1 }}>
                                    <TCInput label={"Phone Number"} value={form.phoneNumber} onChange={(e) => onChange({ phoneNumber: e.currentTarget.value.replace(/[^0-9]+/ig, '') })} />
                                </Grid>
                                <Grid xs={12} md={12} sx={{ p: 1 }}>
                                    <TCLabel>Limited Access</TCLabel>
                                    <Grid container spacing={1}>
                                        <Grid item mt={0.8}>
                                            {
                                                form.limitedAccess ? <IconLock color={"#f44336"} /> : <IconLockOpen color={"#69f0ae"} />
                                            }
                                        </Grid>
                                        <Grid item xs>
                                            <TCInput label={""} value={form.limitedAccess} onChange={(e) => onChange({ limitedAccess: e.currentTarget.value.trim() })} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ p: 1 }}>
                                    <TCInput label={"Password"} type={"password"} value={form.password} onChange={(e) => onChange({ password: e.currentTarget.value})} />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ p: 1 }}>
                                    <TCInput label={"Confirm Password"} showAlert={joi.confirmPassword} type={"password"} value={confirmPassword} onChange={(e) => setConfirmPassword(e.currentTarget.value)} />
                                </Grid>
                            </Grid>
                            <Grid container direction={"row"} sx={{
                                pl: 3
                            }}>
                                <Button
                                    variant="contained"
                                    onClick={() => { updateOrCreate() }}
                                    disabled={
                                        (joi.name || joi.username || joi.role) ? true :
                                            form._id ? form.password === confirmPassword ? false : true : form.password && confirmPassword && form.password === confirmPassword? false : true
                                    }
                                >
                                    {form._id ? "Update" : "Create"}
                                </Button>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}
