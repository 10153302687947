import React, { useEffect, useState } from 'react';

// material-ui
import { Box, Button, Card, Grid, Input, Typography, TextareaAutosize, TextField, Switch, FormControlLabel, Checkbox } from '@mui/material';

// project imports
import Select from 'react-select';
import { discountType } from 'constant/constant';
import { TCInput, TCSelect } from 'components/TopCourtComponents/Input/TopCourtInput';
import { createCoupons, getCoupon, updateCoupon, getAutoPopupService } from 'services/couponServices';
import { TCLabel, TCTitleLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';
import { getParamsFromURL } from 'helper/Params';
import moment from 'moment';
import AsyncSelect from 'react-select/async';

import { getPlayers } from 'services/playerServices';
import { Confirm } from 'helper/Alert';
import { useNavigate } from 'react-router';
import { toastMessage } from 'components/Toast/Toast';

const minimumTypeOptions = [
  {value: "purchase", label: "Purchase"},
  {value: "session", label: "Session"}
]

function AddCoupon ({

}) {

  const navigate = useNavigate()

  const [ form, setForm ] = useState({
    name: "",
    promoCode: "",
    promoCodeType: discountType[0],
    paymentType: null, // VA, Bank Transfer
    amount: null,
    minimumPurchase: null,
    minimumSessions: null,
    startDate: null,
    endDate: null,
    limit: null,
    limitUser: null,
    user: null,
    isActive: false,
    autoPopup: false,
    minimumType: null
  })

  const [ disablePopup, setDisablePopup ] = useState(true)

  const [ joiForm, setJoiForm ] = useState({
    name: form.name ? null : "*",
    promoCode: form.promoCode ? null : "*",
    amount: form.amount ? null : "*",
    promoCodeType: form.promoCodeType ? null : "*",
    minimumPurchase: null,
    minimumSessions: null,
    startDate: form.startDate ? null : "*",
    endDate: form.endDate ? null : "*",
  })

  const onChange = (e) => {
    setForm(prev => ({
      ...prev,
      ...e
    }))
  }

  useEffect(() => {
    setJoiForm({
      name: form.name ? null : "*",
      promoCode: form.promoCode ? null : "*",
      amount: form.amount ? null : "*",
      promoCodeType: form.promoCodeType ? null : "*",
      startDate: form.startDate ? null : "*",
      endDate: form.endDate ? null : "*",
    })
  }, [form])

  useEffect(() => {
    let { isEdit, params } = getParamsFromURL(window);
    getAutoPopupInfo()
    if (isEdit) { getCouponInfo(params) }
  }, [])

  const getAutoPopupInfo = async () => {
    try {
      let { data, error } = await getAutoPopupService();
      console.log(error, data)
      setDisablePopup(data)
    } catch (error) {
      console.log(error)
    }
  }

  const getCouponInfo = async (id) => {
    try {
      let { data, error } = await getCoupon(id)
      let discountObject = null
      let minimumObject = null
      discountType.map( value => {
        if (value.value == data.promoCodeType) { discountObject = value }
      })
      console.log('here discountType', discountObject)
      console.log('here check minimumtype', minimumTypeOptions)
      setForm(prev => ({
        ...prev,
        ...data,
        startDate: moment(data.startDate).format('YYYY-MM-DD'),
        endDate: moment(data.endDate).format('YYYY-MM-DD'),
        user: data.user ? data.user.map(value => {return { value: value._id, label: value.name } }) : null,
        promoCodeType: discountObject,
        minimumType: data.minimumPurchase ? minimumTypeOptions[0] : data.minimumSessions ? minimumTypeOptions[1] : null
      }))
    } catch (error) {
      console.log('Hello World')
    }
  }

  const saveCodePromo = async () => {
    let body = form
    body.promoCodeType = body.promoCodeType ? body.promoCodeType.value : null
    // body.user = body.user ? body.user.value : null
    body.user = body.user ? body.user.map(value => { return value.value }) : null

    if (body.minimumType == "session") {
      body.minimumPurchase = null
    }

    if (body.minimumType == "purchase") {
      body.minimumSessions = null
    }

    if (form._id) {
      let { data, error } = await updateCoupon(form)
      if (error) { toastMessage(error, {
        toastId: "player-success",
        type: "error"
      }) }
      if (data) { window.location.href = "/coupons" }
    } else {
      let { data, error } = await createCoupons(form)
      if (error) {  toastMessage(error, {
        toastId: "player-success",
        type: "error"
      }) }
      if (data) { window.location.href = "/coupons" }
    }
  }

  const loadUserOption = async (input) => {
    if (input.length < 3) {
        return
    }
    try {
        let { data, error } = await getPlayers({
            name: input
        });
        if (error) throw error
        if (data) {
            if (data.length <= 0) return
            return data.map(value => {
                return {
                    label: value.name,
                    value: value._id
                }
            })
        }
    } catch (error) {
        console.log(error.message || error)
        return ([])
    }
}

const checkDisabled = () => {
  return joiForm.name || joiForm.promoCode || joiForm.amount || joiForm.startDate
  || joiForm.endDate || joiForm.promoCodeType
}

  return (
    <>
      <Grid container xs={12} md={8} lg={6}>
        <Card sx={{ mb: 3 }}>
            <Box
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
            <Grid container sx={{
                p: 2
            }}>
                {/* <Grid xs={12} md={12} sx={{p: 1}}>
                  <TCTitleLabel>Coupon Information</TCTitleLabel>
                </Grid> */}
                <Grid item xs={12} md={12} sx={{p: 1}}>
                    <TCInput showAlert={joiForm.name} onChange={(e) => onChange({name: e.currentTarget.value})} value={form.name} label={"Promo Title"}/>
                </Grid>
                <Grid item xs={12} md={12} sx={{p: 1}}>
                    <TCInput showAlert={joiForm.promoCode} onChange={(e) => onChange({promoCode: e.currentTarget.value})} value={form.promoCode} label={"Promo Code"}/>
                </Grid>
                <Grid item container xs={12} md={12}>
                    <Grid xs={6} md={6}sx={{p: 1}}>
                      <TCLabel>Discount Type <span style={{color: '#f44336'}}>{joiForm.promoCodeType}</span></TCLabel>
                        <Select options={discountType} value={form.promoCodeType} onChange={(e) => {
                          onChange({promoCodeType: e})
                        }}/>
                    </Grid>
                    <Grid xs={6} md={6}sx={{p: 1}}>
                        <TCInput showAlert={joiForm.amount} type={"number"} value={form.amount} onChange={(e) => {
                          if (e.currentTarget.value != '' && (e.currentTarget.value * 1) <= 0) { return; }
                          onChange({amount: e.currentTarget.value})
                        }} label={"Value"}/>
                    </Grid>
                </Grid>
                <Grid item container xs={12} md={12} sx={{p: 1, pl: 0, pr: 0}}>
                    {/* <TCInput type={"number"} value={form.minimumPurchase} onChange={(e) => onChange({minimumPurchase: e.currentTarget.value})} label={"Min Purchase"}/> */}
                    <Grid item xs={12} md={6} sx={{p: 1}}>
                      <TCSelect
                        label={"Minimum requirement"}
                        value={form.minimumType}
                        onChange={(e) => {
                          onChange({
                            minimumType: e
                          })
                        }}
                        options={minimumTypeOptions}
                      />
                    </Grid>
                    {
                      (form.minimumType && form.minimumType.value == "purchase") &&
                      <Grid item xs={12} md={6} sx={{p: 1}}>
                        <TCInput type={"number"} value={form.minimumPurchase} onChange={(e) => onChange({minimumPurchase: e.currentTarget.value})} label={"Min Purchase"}/>
                      </Grid>
                    }
                    {
                      (form.minimumType && form.minimumType.value == "session") &&
                      <Grid item xs={12} md={6} sx={{p: 1}}>
                          <TCInput type={"number"} value={form.minimumSessions} onChange={(e) => onChange({minimumSessions: e.currentTarget.value})} label={"Min Sessions"}/>
                      </Grid>
                    }
                </Grid>
                <Grid container item xs={12} md={12}>
                    <Grid xs={6} md={6}sx={{p: 1}}>
                      <TCInput showAlert={joiForm.startDate} type={"date"} value={form.startDate} onChange={(e) => onChange({startDate: e.currentTarget.value})} label={"Start Date"}/>
                    </Grid>
                    <Grid xs={6} md={6}sx={{p: 1}}>
                      <TCInput showAlert={joiForm.endDate} type={"date"} value={form.endDate} onChange={(e) => onChange({endDate: e.currentTarget.value})} label={"Expiry Date"}/>
                    </Grid>
                </Grid>
                <Grid container item xs={12} md={12}>
                    <Grid xs={6} md={6}sx={{p: 1}}>
                      <TCInput type={"number"} value={form.limit} onChange={(e) => onChange({limit: e.currentTarget.value})} label={"Limit Global"}/>
                    </Grid>
                    <Grid xs={6} md={6}sx={{p: 1}}>
                      <TCInput type={"number"} value={form.limitUser} onChange={(e) => onChange({limitUser: e.currentTarget.value})} label={"Limit User / Month"}/>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} sx={{p: 1}}>
                  <TCLabel>User</TCLabel>
                  <AsyncSelect
                    placeholder={'Select User...'}
                    isClearable
                    async
                    defaultOptions
                    loadOptions={loadUserOption}
                    isMulti={true}
                    value={form.user} onChange={(e) => { onChange({ user: e }) }}
                  />
                </Grid>
                {/* <Grid item xs={12} md={12} sx={{p: 1}}>
                    <FormControlLabel control={<Switch checked={form.isActive} onChange={() => {
                        onChange({isActive: !form.isActive})
                    }} />} label={<TCLabel>Active</TCLabel>} />
                </Grid> */}
                <Grid item xs={12} md={12} sx={{p: 1}}>
                    <FormControlLabel control={<Checkbox checked={form.autoPopup} 
                    disabled={disablePopup && (disablePopup._id !== form._id)}
                    onChange={() => {
                        onChange({autoPopup: !form.autoPopup})
                    }} />} label={<TCLabel>Auto Pop Up</TCLabel>} />
                    {
                      (disablePopup && (disablePopup._id !== form._id)) && 
                      <TCLabel subtitle style={{
                        color: 'rgba(0, 0, 0, 0.38)'
                      }}>
                        AUTO POP UP IS CONFIGURED FOR <span style={{color: '#2196f3', 
                        cursor: 'pointer',
                        textDecoration: 'underline'}}
                        onClick={() => {
                          // navigate()
                          window.location.href = `/coupons/edit/${disablePopup._id}`
                        }}
                      >{disablePopup.name}</span> PROMO CODE</TCLabel>
                    }
                </Grid>
            </Grid>
            {/* <Grid container direction={"row"} sx={{
                p: 2
            }}>
            </Grid> */}
            <Grid container direction={"row"} sx={{
                pl: 3, pb: 3
            }}>
                <Button variant="contained" disabled={checkDisabled()} onClick={() => {
                  saveCodePromo()
                }}>{ form._id ? "Update" : "Add"}</Button>
            </Grid>         
            </Box>
          </Card>
      </Grid>
    </>
  )
}

export default AddCoupon;
