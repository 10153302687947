import React, { useState, useEffect } from 'react';

// material-ui
import { Box, Card, Grid, Typography, Avatar, Button, Switch } from '@mui/material';

import { currencyFormat } from 'helper/NumberFormat';

// project imports
import MainCard from 'ui-component/cards/MainCard';

import { getCoupons } from 'services/couponServices';
import { TCLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';
import TableComponent from 'components/TopCourtComponents/Table/TableComponent';
import CouponFilter from './couponFilter';
import moment from 'moment';

const initFilter = {
    name: '',
    codePromo: '',
    discountType: null,
    status:null,
    createdDate: null,
    expiredDate: null
}

export default function Coupon({

}) {
    const [rows, setRows] = useState([])
    const [filter, setFilter] = useState(initFilter)

    useEffect(() => {
        loadData()
    }, [filter])

    const loadData = async () => {
        let { data, error } = await getCoupons({
            name: filter.name? filter.name : '',
            promo: filter.codePromo? filter.codePromo : '',
            type: filter.discountType? filter.discountType.value : '',
            isActive: filter.status? filter.status.value: '',
            createdDate: filter.createdDate ? filter.createdDate : '',
            expiredDate: filter.expiredDate ? filter.expiredDate : ''
        });
        console.log(data)
        setRows(data)
    }

    const valueCustomator = (row) => {
        return ({
            createdDate: <TCLabel>{moment(row.createdDate).format("YYYY/MM/DD HH:mm:ss")}</TCLabel>,
            name: <span className='span-link' onClick={() => {window.location.href = `/coupons/edit/${row._id}`}}>{row.name}</span>,
            promo: <TCLabel>{row.promoCode}</TCLabel>,
            type: <TCLabel>{row.promoCodeType.charAt(0).toUpperCase() + row.promoCodeType.slice(1)}</TCLabel>,
            amount: <TCLabel>{row.promoCodeType == 'percentage' ? `${row.amount}%` : currencyFormat(row.amount)}</TCLabel>,
            minRequirement: <TCLabel>{
                row.minimumPurchase ? currencyFormat(row.minimumPurchase) :
                row.minimumSession ? row.minimumSession : '-'
            }</TCLabel>,
            startDate: <TCLabel>{moment(row.startDate).format("YYYY/MM/DD")}</TCLabel>,
            expiryDate: <TCLabel>{moment(row.endDate).format("YYYY/MM/DD")}</TCLabel>,
            total: <TCLabel>{row.limit ? `${row.usage || 0}/${row.limit}` : `-`}</TCLabel>,
            // action: <Switch checked={row.isActive} />,
            // total: <TCLabel>{row.totalSession}</TCLabel>,
        })
    }

    const onChangeFilter = (e) => {
        setFilter(prev => ({
            ...prev,
            ...e
        }))
    }

    const clearFilter = () => {
        setFilter(initFilter)
    }

    return (
        <>
            <Button sx={{ mb: 2 }} variant="contained" disableElevation onClick={() => {
                window.location.href = "/coupons/add"
            }}>Add Promo Code</Button>
            <CouponFilter filter={filter} onChangeFilter={onChangeFilter} clearFilter={clearFilter} />
            <TableComponent
                columns={[
                    { label: "Created Date" },
                    { label: "Promo Title" },
                    { label: "Promo Code" },
                    { label: "Disc Type" },
                    { label: "Disc Amount" },
                    { label: "Min Requirement" }, // purchase ttp ID, session pakai x sessions
                    { label: "Start Date" },
                    { label: "Expiry Date" },
                    { label: "Limit Global" }, // ikutin ideal datanya
                    // { label: "" },
                ]}
                rows={rows}
                valueCustomator={valueCustomator}
            />
        </>
    )
}