import React, { useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel, Grid, Switch } from "@mui/material";
import { TCInput, TCSelect } from "components/TopCourtComponents/Input/TopCourtInput";
import { Confirm } from "helper/Alert";
import { getManualOTP, updateManualOTP } from "services/playerServices";
import { IconCheck, IconCross, IconX } from '@tabler/icons';

import { toastMessage } from "components/Toast/Toast";

export default function PlayerOTP({
    playerId,
    manualOTP
}) {
    const [otp, setOtp] = useState(manualOTP)

    const onChangeOTP = (e) => {
        console.log('ini cek e', e)
        if (e.length > 6) {
            return
        } else setOtp(e.replace(/[^0-9]+/ig, ''))
    }

    const updateOTP = async (value) => {
        try {
            let { data, error } = await updateManualOTP({ _id: playerId, otp: value })
            if (error) throw error
            if (data) {
                toastMessage('Manual OTP Updated', {
                    toastId: "otp-success",
                    type: "success"
                });
            }
        } catch (error) {
            console.log(error)
        }
    }

    const resetOTP = async () => {
        try {
            let { data, error } = await updateManualOTP({ _id: playerId, otp: '' })
            if (error) throw error
            if (data) {
                toastMessage('OTP Reset Successfully', {
                    toastId: "otp-success",
                    type: "success"
                });
                setOtp('')
            }
        } catch (error) {
            console.log(error)
            setOtp('')
        }
    }

    return (
        <Grid container md={12} spacing={2} sx={{ margin: '-1rem', padding: '1rem' }}>
            <Grid item container sm={6} md={4}>
                <Grid item xs md>
                    <TCInput value={otp} label={'OTP (CAN ONLY BE USED ONCE)'} onChange={(e) => onChangeOTP(e.target.value)} />
                </Grid>
                {otp ?
                    <Grid style={{
                        position: 'relative',
                    }} item pt={3.5} pl={0.5} mr={3}>
                        <IconX size={15} style={{cursor:'pointer', position: 'absolute', 
                            color: 'grey',
                            right: 10}} onClick={()=> resetOTP()}/>
                    </Grid>
                :''}
            </Grid>
            <Grid item xs={12} md={12} >
                <Button variant="contained" onClick={() => updateOTP(otp)}>Update OTP</Button>
            </Grid>
        </Grid>
    )
}