// assets
import { IconSettings } from '@tabler/icons';

// constant
const icons = {
    IconSettings
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const settings = {
    id: 'settings',
    title: 'Setting',
    caption: '',
    type: 'group',
    children: [
        {
            id: 'courts',
            title: 'Court',
            type: 'item',
            url: '/courts',
            icon: icons.IconSettings,
            children: [
                {
                    id: 'courtadd',
                    title: 'Add',
                    type: 'item',
                    url: '/add',
                },
                {
                    id: 'courtedit',
                    title: 'Edit',
                    type: 'item',
                    url: '/edit',
                },
            ]
        },
        // {
        //     id: 'counters',
        //     title: 'Counter',
        //     type: 'item',
        //     url: '/counter',
        //     icon: icons.IconSettings,
        //     children: [
        //         {
        //             id: 'counteradd',
        //             title: 'Add',
        //             type: 'item',
        //             url: '/add',
        //         },
        //         {
        //             id: 'counteredit',
        //             title: 'Edit',
        //             type: 'item',
        //             url: '/edit',
        //         },
        //     ]
        // },
        {
            id: 'category',
            title: 'Category',
            type: 'item',
            url: '/categories',
            icon: icons.IconSettings,
            children: [
                {
                    id: 'categoryadd',
                    title: 'Add',
                    type: 'item',
                    url: '/add',
                },
                {
                    id: 'categoryedit',
                    title: 'Edit',
                    type: 'item',
                    url: '/edit',
                },
            ]
        },
    ]
};

export default settings;
