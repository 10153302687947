import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import admins from './admins';
import settings from './settings';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  // items: [dashboard, pages, utilities, other]
  // items: [dashboard, admins, settings]
  items: [admins, settings]
};

export default menuItems;