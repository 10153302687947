import React from "react";

import { Box, Button, Card, Dialog, Grid, Typography } from '@mui/material';
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { currencyFormat } from "helper/NumberFormat";
import { fontSize } from "@mui/system";

import icon from "../../../assets/money-in.png";


export default function PaymentSummmary({
    summary
}) {

    const styles = {
        summary: {
            minWidth: 50,
            height: 50,
            opacity: '0.3',
            marginLeft: 10,
            background: `url(${icon}) no-repeat top right`,
            backgroundSize: 'contain',
            marginRight: 10
        }
    }

    return (
        <Card sx={{ mb: 1 }} className="custom-card-style">
            <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ justifyContent: 'space-between', alignItems: 'center',
                    display: 'flex' }}>
                    <div style={{ width: '100%' }}>
                        <Grid container columnSpacing={2} sx={{ p: 2 }}>
                            <Grid item xs={12} md={4} sx={{ mb: 1 }}>
                                <Card sx={{ justifyContent: 'center', alignItems: 'center', background: 'whiteSmoke', height: 80, padding: 1, borderRadius: 0, 
                                    borderLeft: '5px solid rgb(66, 181, 73)'
                                }}>
                                    <TCLabel style={{
                                        fontSize: 12,
                                        color: 'rgb(133, 133, 133)'
                                    }}>Grand Total</TCLabel>
                                    <TCLabel bold style={{
                                        fontSize: 15
                                    }}>{`${summary && summary.grandTotal ? currencyFormat(summary.grandTotal) : '-'}`}</TCLabel>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ mb: 1 }}>
                                <Card sx={{ justifyContent: 'center', alignItems: 'center', background: 'whiteSmoke', minHeight: 80, padding: 1, borderRadius: 0,
                                    borderLeft: '5px solid rgb(66, 181, 73)'
                                }}>
                                    <TCLabel style={{
                                        fontSize: 12,
                                        color: 'rgb(133, 133, 133)'
                                    }}>Total Payment (STC HO) :</TCLabel>
                                    <TCLabel bold style={{
                                        fontSize: 15
                                    }}>{`${summary && summary.totalSTCHO ? summary.totalSTCHO.totalOrder > 0 ? currencyFormat(summary.totalSTCHO.totalOrder) : '-' : '-'}`}</TCLabel>
                                    {/* <Grid container mt={1} sx={{color: '#4DBD74'}}>
                                        <Grid container item xs={6} spacing={0.2}>
                                            <Grid item>
                                                <TCLabel>Value Pack : </TCLabel>
                                            </Grid>
                                            <Grid item>
                                                <TCLabel>{summary && summary.totalSTCHO ? currencyFormat(summary.totalSTCHO.valuePack) : '-'}</TCLabel>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={6} spacing={0.2}>
                                            <Grid item>
                                                <TCLabel>Booking : </TCLabel>
                                            </Grid>
                                            <Grid item>
                                                <TCLabel>{summary && summary.totalSTCHO ? currencyFormat(summary.totalSTCHO.booking) : '-'}</TCLabel>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={6} spacing={0.2}>
                                            <Grid item>
                                                <TCLabel>Service : </TCLabel>
                                            </Grid>
                                            <Grid item>
                                                <TCLabel>{summary && summary.totalSTCHO ? currencyFormat(summary.totalSTCHO.service) : '-'}</TCLabel>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={6} spacing={0.2}>
                                            <Grid item>
                                                <TCLabel>Class : </TCLabel>
                                            </Grid>
                                            <Grid item>
                                                <TCLabel>{summary && summary.totalSTCHO ? currencyFormat(summary.totalSTCHO.class) : '-'}</TCLabel>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={6} spacing={0.2}>
                                            <Grid item>
                                                <TCLabel>Rent : </TCLabel>
                                            </Grid>
                                            <Grid item>
                                                <TCLabel>{summary && summary.totalSTCHO ? currencyFormat(summary.totalSTCHO.rent) : '-'}</TCLabel>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={6} spacing={0.2}>
                                            <Grid item>
                                                <TCLabel>Buy : </TCLabel>
                                            </Grid>
                                            <Grid item>
                                                <TCLabel>{summary && summary.totalSTCHO ? currencyFormat(summary.totalSTCHO.buy) : '-'}</TCLabel>
                                            </Grid>
                                        </Grid>
                                    </Grid> */}
                                </Card>
                            </Grid>
                            {/* <Grid item xs={4} md={4} sx={{ mb: 1 }}>
                                <Card sx={{ justifyContent: 'center', alignItems: 'center', background: 'whiteSmoke', height: 80, padding: 1, borderRadius: 0 }}>
                                </Card>
                            </Grid> */}
                        </Grid>
                    </div>
                    <div style={styles.summary}></div>
                </div>
            </Box>
        </Card>
    )
}