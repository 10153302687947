import React, { useEffect, useState } from 'react';

import { dayOptions, timeOptions, operationalHours } from "constant/constant";
// material-ui
import { Box, Button, Card, Grid, Input, Typography, TextareaAutosize, TextField, Switch, FormControlLabel, Checkbox } from '@mui/material';

// project imports
import { AddonTypeOption, userStatusObj, userStatusOptions } from 'constant/constant';
import { TCInput, TCSelect } from 'components/TopCourtComponents/Input/TopCourtInput';
import { TCLabel, TCTitleLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';
import { getParamsFromURL } from 'helper/Params';
import { createAddon, getAddon, getServices, getHistory, updateAddon, updateAddonService } from 'services/addonServices';
import DropZoneComponent from 'components/DropzoneComponent';
import { handleUpload } from 'helper/S3';
import moment from 'moment';
import { Confirm, ConfirmationOneButton } from 'helper/Alert';
import { getCategories } from 'services/categoryServices';
import { getPlayers } from 'services/playerServices';

import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router';

import Select from 'react-select';
import DateRangeInput from 'components/TopCourtComponents/Input/DateRangeFilter';
import { getPlaceDetail } from 'services/placeServices';

import Delete from '@mui/icons-material/Delete';
import ScheduleHoursComponent from '../courts/component/scheduleHoursComponent';
import ReactQuill from 'react-quill';

export default function AddAddOn() {
  const [stockHistory, setStockHistory] = useState([])
  const [form, setForm] = useState({
    category: null,
    name: "",
    isActive: true,
    itemImages: [],
    schedule: [],
    scheduleOff: [],
    type: null,
    price: null,
    description: "",
    stock: null,
    validity: null,
    lockEdit: true
  })
  const [ players, setPlayers ] = useState([])
  const [ categories, setCategories ] = useState([])
  const [ reload, setReload ] = useState(Date.now)

  const navigate = useNavigate()

  const [ joiForm, setJoiForm ] = useState({
    category: form.category ? null : "required",
    type: form.type ? null : "required",
    name: form.name ? null : "required",
    validity: form.validity ? null : "required",
    price: form.price ? null : "required",
    images: form.itemImages ? null : "required"
  })

  const [ advancedDays, setAdvancedDays ] = useState(14)
  const [ orderSchedule, setOrderSchedule] = useState([])

  useEffect(() => {
    let { isEdit, params } = getParamsFromURL(window);
    if (isEdit) { getAddonDetail(params) }
    categoriesLoad()
  }, [])

  useEffect(() => {
    console.log('here check form', form)
  }, [form])

  const categoriesLoad = async () => {
    let { data, error } = await getCategories()
    if (data) { 
      let newData = []
      for (let i = 0; i < data.length; i ++) {
        newData.push({
          value: data[i]._id,
          label: data[i].name,
          data: data[i]
        })
      }
      setCategories(newData) 
    }
  }

  useEffect(() => {
    setJoiForm({
      category: form.category ? null : "required",
      type: form.type ? null : "required",
      name: form.name ? null : "required",
      validity: form.validity ? null : "required",
      price: form.price ? null : "required",
      stock: form.stock ? null : form.type == AddonTypeOption[0] ? null : "required",
      images: (form.itemImages && form.itemImages.length > 0) ? null : (form.type && form.type.value !== AddonTypeOption[0].value) ? "required" : null
    })
    // setReload(Date.now)
  }, [form])

  const onChange = (e) => {
    console.log('here check change', e)
    // if (e.user && e.user.data.coachStatus === true) {
    //   Confirm("", "Can't select coach");
    //   return;
    // }
    if (e.type && e.type.value == AddonTypeOption[0].value) {
      setForm(prev => ({
        ...prev,
        validity: 14
      }))
    } else if (e.type && e.type.value == AddonTypeOption[1].value) {
      setForm(prev => ({
        ...prev,
        validity: 7
      }))
    }
    if (e.type && e.type.value !== AddonTypeOption[0].value &&
      e.type && e.type.value !== AddonTypeOption[1].value) {
      setForm(prev => ({
        ...prev,
        ...e,
        validity: null
      }))
    } else {
      setForm(prev => ({
        ...prev,
        ...e
      }))
    }
  }

  const getAddonDetail = async (id) => {
    try {
      let { data, error } = await getAddon(id)
      if (error) throw error
      if (data) {
        let type = AddonTypeOption[0]
        
        for (let i = 0; i < AddonTypeOption.length; i ++) {
          if (data.type == AddonTypeOption[i].value) { type = AddonTypeOption[i] }
        }

        if (data.type === "service") {
          let services = await getServices(data.service)
          console.log('services', services.data)
          setPlayers(services.data)
        }
        
        if (data.type === 'service') {
          setForm(prev => ({
            ...prev,
            validity: 14
          }))
        }

        console.log('here check offschdule data', data)
        
        setForm({
          ...data,
          type,
          user: data.user ? { _id: data.user._id, label: data.user.name } : null,
          category: data.category ? { _id: data.category._id, label: data.category.name, data: data.category } : null,
          lockEdit: (data.lockSchedule === false || data.lockSchedule === true) ? data.lockSchedule : true,
          scheduleOff: data.scheduleOff ? data.scheduleOff : []
        })
        setOrderSchedule(data.orderSchedule)
        getAddonHistory(id)
        setReload(Date.now)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAddonHistory = async (id) => {
    try {
      let { data, error } = await getHistory(id)
      if (error) throw error
      if (data) {
        console.log('ini cek data', data)
      }
    } catch (error) {
      console.log('ini cek error', error)
    }
  }

  const loadUserOption = async (input) => {
    if (input.length < 3) {
        return
    }
    try {
        let { data, error } = await getPlayers({
            name: input
        });
        if (error) throw error
        if (data) {
            if (data.length <= 0) return
            return data.map(value => {
                return {
                    label: value.name,
                    value: value._id,
                    data: value
                }
            })
        }
    } catch (error) {
        console.log(error.message || error)
        return ([])
    }
}

  const updateOrCreate = () => {
    if (form._id) {
      updateExistingAddon()
    } else {
      createNewAddon()
    }
  }

  const deleteAddonImages = (index) => {
    let currImages = form.itemImages
    currImages.splice(index, 1);
    onChange({ itemImages: currImages })
  }

  const createNewAddon = async () => {
    try {
      let body = {
        ...form
      }
      body.category = form.category ? form.category.value : null
      body.user = form.user ? form.user.value : null
      if (form.itemImages) {
        let courtImgs = []
        for (let i = 0; i < form.itemImages.length; i++) {
          if (form.itemImages[i].name) {
            let images = await handleUpload(form.itemImages[i])
            courtImgs = courtImgs.concat(images)
          } else {
            courtImgs = courtImgs.concat(form.itemImages[i])
          }
        }
        body.itemImages = courtImgs
      }
      body.type = body.type ? body.type.value : ''

      // if (form.type == AddonTypeOption[1]) {
      //   body.stock = 1
      // }

      let { data, error } = await createAddon({
        ...body,
        lockSchedule: form.lockEdit
      })
      if (error) throw error
      if (data) {
        // setForm(data)
        // navigate('/addon')
        window.location.replace(`/addon/edit/${data._id}`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const updateExistingAddon = async () => {
    try {
      let body = { ...form }
      if (form.itemImages) {
        let courtImgs = []
        for (let i = 0; i < form.itemImages.length; i++) {
          if (form.itemImages[i].name) {
            let images = await handleUpload(form.itemImages[i])
            courtImgs = courtImgs.concat(images)
          } else {
            courtImgs = courtImgs.concat(form.itemImages[i])
          }
        }
        body.itemImages = courtImgs
      } else body.itemImages = []
      body.type = body.type ? body.type.value : ''
      body.category = form.category ? form.category.value : null
      body.user = form.user ? form.user.value : null

      // if (form.type == AddonTypeOption[1]) {
      //   body.stock = 1
      // }

      console.log('here check body', body)

      let { data, error } = await updateAddon({
        ...body, 
        lockSchedule: form.lockEdit
      })
      if (error) {
        ConfirmationOneButton("", error.message ? error.message : 'Something went wrong. Please try again.', "OK", () => {
          let { isEdit, params } = getParamsFromURL(window);
          if (isEdit) { getAddonDetail(params) }
          // window.location.replace(`/addon/edit/${data._id}`)
        })
      }
      if (data) {
        // setForm(data)
        window.location.replace(`/addon/edit/${data._id}`)
      }
    } catch (error) {
      console.log(error)
    }
  }
  
  const checkButtonDisabled = () => {
    if (form.type != AddonTypeOption[0]) {
      if (form.type ===AddonTypeOption[2]) {
        return joiForm.category || joiForm.images || joiForm.name || joiForm.price || joiForm.type || joiForm.stock
      } else {
        return joiForm.category || joiForm.images || joiForm.name || joiForm.price || joiForm.type || joiForm.stock || joiForm.validity
      }
    } else {
      return joiForm.category || joiForm.images || joiForm.name || joiForm.price || joiForm.type || joiForm.validity
    }
  }

  return (
    <>
      <Card>
        <Grid item container xs={12} md={12} lg={12}>
          <Grid xs={12} md={6} lg={6} sx={{ p: 1 }}>
            <Box
              sx={{
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Grid container sx={{
                p: 2
              }}>
                {/* <Grid xs={12} md={12} sx={{ p: 1 }}>
                  <TCTitleLabel>{form._id ? 'Update Add-On' : 'Add New Add-On'}</TCTitleLabel>
                </Grid> */}
                <Grid item xs={12} md={12} sx={{ p: 1 }}>
                  <TCSelect label={"Category*"} showAlert={joiForm.category} options={categories} value={form.category} onChange={(e) => {
                    onChange({ category: e, price: e.data.defaultPrice ? e.data.defaultPrice : null })
                  }}/>
                </Grid>
                <Grid container item xs={12} md={12} sx={{ p: 1 }} spacing={1}>
                  <Grid item xs>
                    <TCSelect label={"Add-On Type*"} showAlert={joiForm.type} options={AddonTypeOption} value={form.type} onChange={(e) => {
                      if (e.value === "rent") {
                        onChange({ type: e, stock: 1 })
                      } else {
                        onChange({ type: e, stock: '' })
                      }
                    }}/>
                  </Grid>
                  {
                    (form.type == AddonTypeOption[0]) &&
                    <Grid item xs>
                      <TCLabel>{"User*"}</TCLabel>
                      <AsyncSelect
                          placeholder={'Select User...'}
                          isClearable
                          async
                          defaultOptions
                          loadOptions={loadUserOption}
                          value={form.user} onChange={(e) => { onChange({ user: e, name: e ? e.label : '' }) }}
                      />
                    </Grid>
                  }
                </Grid>
                <Grid item container xs={12} md={12}>
                  <Grid xs={10} md={10} sx={{ p: 1 }}>
                    <TCInput onChange={(e) => onChange({ name: e.currentTarget.value })}
                      // disabled={form.type == AddonTypeOption[0]} 
                      value={form.name} showAlert={joiForm.name}  label={"Name*"} />
                  </Grid>
                  <Grid xs={2} md={2} sx={{ p: 1 }} >
                    <TCLabel style={{ marginLeft: 10 }}>Status</TCLabel>
                    <Switch checked={form.isActive} onChange={() => onChange({ isActive: !form.isActive })} />
                  </Grid>
                </Grid>
                {
                  // form.type !== AddonTypeOption[0] &&
                  <Grid item xs={12} md={12} sx={{ p: 1 }}>
                    <Grid item container spacing={1}>
                      <Grid item>
                        <TCLabel>Images*</TCLabel>
                      </Grid>
                      {
                        joiForm.images && 
                        <Grid item pb={0}>
                          <TCLabel style={{fontSize: 11, color: '#f44336'}}>{joiForm.images}</TCLabel>
                        </Grid>
                      }
                    </Grid>
                    <DropZoneComponent
                      className="drop-zone"
                      uploadName='addonImage'
                      file={form.itemImages}
                      isMultiple={true}
                      onDrop={(files, event) => {
                        let currImages = form.itemImages ? form.itemImages : []
                        for (let i = 0; i < files.length; i++) {
                          currImages = currImages.concat(files[i])
                        }
                        onChange({ itemImages: currImages })
                      }}
                    >
                      <div className='dropzone mb-0' style={{
                        textAlign: 'center',
                        minHeight: '150px'
                      }}>
                        <div style={{ padding: 10, alignItems: 'center' }}>
                          <label>
                            <i className='fa fa-upload mr-1' />
                            Drop itemImages to upload
                          </label>
                          <br />
                          <label
                            htmlFor={"addonImage"}
                            style={{ cursor: 'pointer' }}
                            className='dropzone-child mb-0'>
                            or Click to Browse
                          </label>
                          <div style={{ overflowX: 'auto' }}>
                            {
                              (form.itemImages && form.itemImages.length > 0) &&
                              <div style={{ textAlign: 'left' }}>
                                Uploaded file :
                                {
                                  form.itemImages.map((value, index) => {
                                    return (
                                      <div key={`${value.name}${index}`} className='d-flex mb-1' style={{ textAlign: 'left' }}>
                                        <img src={(!value.name && value.includes("https")) ? value : URL.createObjectURL(value)} style={{ minWidth: '100px', maxHeight: '50px', objectFit: 'contain' }}
                                          onClick={() => {
                                          }}
                                        />
                                        <label className='mt-1 mb-0 ml-1' style={{ alignItems: 'flex-end' }}>
                                          {value.name}
                                          <span style={{ marginLeft: 5, color: 'gray', cursor: 'pointer' }} onClick={() => {
                                            deleteAddonImages(index)
                                          }}>X</span>
                                        </label>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </DropZoneComponent>
                  </Grid>
                }
                <Grid item container xs={12} md={12} sx={{ p: 1 }} spacing={2}>
                  <Grid item xs={9} md={9}>
                    <TCInput value={form.price} showAlert={joiForm.price} onChange={(e) => onChange({ price: e.currentTarget.value.replace(/\D/g, '') })} label={"Price*"} />
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <div style={{ fontWeight: 'bold', marginTop: 35 }}>{(form.type === AddonTypeOption[0] || form.type === AddonTypeOption[1]) ? ' / Session' : ' / Item'}</div>
                  </Grid>
                </Grid>
                {
                  form.type != AddonTypeOption[0] &&
                  <Grid item xs={12} md={12} sx={{ p: 1 }}>
                    <TCInput label={"Stock*"}  value={form.stock} showAlert={joiForm.stock} onChange={(e) => onChange({ stock: e.currentTarget.value.replace(/\D/g, '') })} />
                  </Grid>
                }
                {
                  (form.type && form.type.value != AddonTypeOption[2].value) &&
                  <Grid item container xs={12} md={12}  sx={{ p: 1 }} spacing={2}>
                    <Grid item xs={10} md={10}>
                      <TCInput disabled={form.type && form.type.value === AddonTypeOption[0].value ? true : form.type && form.type.value === AddonTypeOption[2].value ? true : false} type={"number"} value={form.validity} onChange={(e) => onChange({ validity: e.currentTarget.value })} label={"Advanced Booking"} />
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <div style={{ fontWeight: 'bold', marginTop: 35 }}>{'Days'}</div>
                    </Grid>
                  </Grid>
                }
                <Grid item xs={12} md={12} sx={{ p: 1 }}>
                  <TCLabel>Description</TCLabel>
                  <ReactQuill theme="snow" value={form.description} onChange={(e) => onChange({description: e})} />
                  {/* <TCInput value={form.description} fullWidth={true} label={"Description"} type={'textarea'} rows={4} onChange={(e) => onChange({ description: e.currentTarget.value })} /> */}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {form._id && form.type.value !== 'service' &&
            <Grid xs={12} md={6} lg={6} sx={{ p: 1 }}>
              <Card sx={{ overflowY: 'scroll' }}>
                <Box
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Grid container sx={{
                    p: 2
                  }}>
                    <Grid xs={12} md={12} sx={{ p: 1 }}>
                      <TCTitleLabel>Stock History</TCTitleLabel>
                    </Grid>
                    {
                      stockHistory.map((v, index) => {
                        return (
                          <Grid key={index} xs={12} md={12} sx={{ p: 1 }}>
                            <Card style={{ padding: 5, boxShadow: '2px 4px 6px 0px rgba(87,87,87,0.2)', borderRadius: 1 }}>
                              <TCLabel>{`${v.title} (${moment(v.date).format("YYYY/MM/DD HH:mm:ss")})`}</TCLabel>
                              {v.content}
                            </Card>
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                </Box>
              </Card>
            </Grid>
          }
          {
            // (form._id && form.type.value === 'service') &&
            (form.type && form.type.value === 'service') &&
            <Grid xs={12} md={6} lg={6} sx={{ p: 1 }}>
              <Card sx={{ overflowY: 'scroll' }}>
                <Box
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Grid sx={{ p: 2 }}>
                    <SetOffDay reload={reload} data={form} setAddOnForm={setForm} orderSchedule={orderSchedule} />
                  </Grid>
                </Box>
              </Card>
            </Grid>
          }
          <Grid container direction={"row"} sx={{
            mb: 4,
            pl: 4
          }}>
            <Button 
            disabled={checkButtonDisabled()}
            variant="contained" onClick={() => {
              updateOrCreate()
            }}>{form._id ? "Update" : "Create"}</Button>
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

function SetOffDay ({
  data,
  setAddOnForm,
  orderSchedule,
  reload
}) {

    const [ form, setForm ] = useState({
        lockEdit: (data.lockSchedule === false || data.lockSchedule === true) ? data.lockSchedule : true,
        operationalHours: data.schedule ? data.schedule : [
          {
              day: "Monday",
              isActive: true,
              operationalHours: {
                  start: "08:00",
                  end: "23:00"
              },
              hours: []
          },
          {
              day: "Tuesday",
              isActive: true,
              operationalHours: {
                  start: "08:00",
                  end: "23:00"
              },
              hours: []
          },
          {
              day: "Wednesday",
              isActive: true,
              operationalHours: {
                  start: "08:00",
                  end: "23:00"
              },
              hours: []
          },
          {
              day: "Thursday",
              isActive: true,
              operationalHours: {
                  start: "08:00",
                  end: "23:00"
              },
              hours: []
          },
          {
              day: "Friday",
              isActive: true,
              operationalHours: {
                  start: "08:00",
                  end: "23:00"
              },
              hours: []
          },
          {
              day: "Saturday",
              isActive: true,
              operationalHours: {
                  start: "08:00",
                  end: "23:00"
              },
              hours: []
          },
          {
              day: "Sunday",
              isActive: true,
              operationalHours: {
                  start: "08:00",
                  end: "23:00"
              },
              hours: []
          },
        ],
        scheduleOff: data.scheduleOff ? data.scheduleOff : [],
    })
    const [ offScheduleForm, setOffScheduleForm ] = useState({
      date: null,
      day: null,
      startHours: null,
      endHours: null
    })
    const [ listHours, setListHours ] = useState(timeOptions)
    const [ listEndHours, setListEndHours ] = useState(timeOptions)
    const [ placeInfo, setPlaceInfo ] = useState(null)

    // load current court information

    useEffect(() => {
      console.log('here check form', form)
      setAddOnForm(prev => ({
        ...prev,
        schedule: form.operationalHours,
        lockEdit: form.lockEdit,
        scheduleOff: form.scheduleOff
      }))
    }, [form])

    useEffect(() => {
      console.log('here check form', data.scheduleOff)
      setForm({
          lockEdit: (data.lockSchedule === false || data.lockSchedule === true) ? data.lockSchedule : true,
          operationalHours: data.schedule ? data.schedule : [
            {
                day: "Monday",
                isActive: true,
                operationalHours: {
                    start: "08:00",
                    end: "23:00"
                },
                hours: []
            },
            {
                day: "Tuesday",
                isActive: true,
                operationalHours: {
                    start: "08:00",
                    end: "23:00"
                },
                hours: []
            },
            {
                day: "Wednesday",
                isActive: true,
                operationalHours: {
                    start: "08:00",
                    end: "23:00"
                },
                hours: []
            },
            {
                day: "Thursday",
                isActive: true,
                operationalHours: {
                    start: "08:00",
                    end: "23:00"
                },
                hours: []
            },
            {
                day: "Friday",
                isActive: true,
                operationalHours: {
                    start: "08:00",
                    end: "23:00"
                },
                hours: []
            },
            {
                day: "Saturday",
                isActive: true,
                operationalHours: {
                    start: "08:00",
                    end: "23:00"
                },
                hours: []
            },
            {
                day: "Sunday",
                isActive: true,
                operationalHours: {
                    start: "08:00",
                    end: "23:00"
                },
                hours: []
            },
          ],
          scheduleOff: data.scheduleOff ? data.scheduleOff : [],
      })
      setOffScheduleForm({
        date: null,
        day: null,
        startHours: null,
        endHours: null
      })
    }, [reload])

    useEffect(() => {
        checkAvailableHours(offScheduleForm.date, timeOptions, setListHours, false)
    }, [offScheduleForm.date])

    useEffect(() => {
        if (offScheduleForm.startHours) {
            let hours = []
            let start = false
            for (let i = 0; i < timeOptions.length; i ++) {
                if (start) { hours.push(timeOptions[i]) }
                if (timeOptions[i].value == offScheduleForm.startHours.value) { start = true }
            }
            checkAvailableHours(offScheduleForm.date, hours, setListEndHours, true)
        }
    }, [offScheduleForm.startHours])

    const saveSchedule = async () => {
        if (!offScheduleForm.date) { Confirm("", "Select date off"); return; }
        if (!offScheduleForm.startHours) { Confirm("", "Select start hours"); return; }
        if (!offScheduleForm.endHours) { Confirm("", "Select end hours"); return; }
        let newForm = {
            ...offScheduleForm
        }
        newForm.startHours = offScheduleForm.startHours ? offScheduleForm.startHours.value : ''
        newForm.endHours = offScheduleForm.endHours ? offScheduleForm.endHours.value : ''
        let newOffSchedule = form.scheduleOff
        newOffSchedule = checkAddHours(
          {
            date: newForm.date,
            day: newForm.day,
            startHours: newForm.startHours,
            endHours: newForm.endHours
          }, 
          form.scheduleOff
        )
        setForm(prev => ({
          ...prev,
          scheduleOff: newOffSchedule
        }))
    }

    const removeOffSchedule = async (e) => {
      let newOffSchedule = form.scheduleOff
      newOffSchedule.splice(e, 1)
      setForm(prev => ({
        ...prev,
        scheduleOff: newOffSchedule
      }))
    }

    const onChange = (e) => {
        console.log('check form information', e)
        setOffScheduleForm(prev => ({
            ...prev,
            ...e
        }))
    }

    const timeStringFormat = (e) => {
      let value = e
      console.log(value)
      return `${value < 10 ? `0${value}` : value}:00`
    }

    const checkAvailableHours = (e, hours, setHours, disableEnd) => {
      console.log('Check Available Hours - order schedule', e, orderSchedule)
      let similiar = false
      for (let i = 0; i < orderSchedule.length; i ++) {
          if (orderSchedule[i].date == e) {
              let newHours = []
              let disable = false
              console.log('Check Available Hours', orderSchedule[i])
              for (let j = 0; j < hours.length; j++) {
                if (orderSchedule[i].hours.indexOf(hours[j].value) >= 0) {
                    // if (!disableEnd) { 
                    //   newHours.push({
                    //     ...hours[j],
                    //     isDisabled: true
                    //   })
                    // } else { newHours.push(hours[j]) }
                    if (!disable) { 
                      if (disableEnd) { 
                        disable = true 
                        newHours.push({
                          ...hours[j]
                        })
                      } else {
                        newHours.push({
                          ...hours[j],
                          isDisabled: true
                        })
                      }
                    } else {
                      newHours.push({
                        ...hours[j],
                        isDisabled: true
                      })
                    }
                } else if (disableEnd && disable) {
                  newHours.push({
                    ...hours[j],
                    isDisabled: true
                  })
                } else {
                  newHours.push(hours[j])
                }
              }
              similiar = true
              // console.log('Check Available Hours', newHours)
              setHours(newHours)
          }
      }
      if (!similiar) {
          setHours(hours)
      }
      // setListHours
   }

    return (
        <>
            <Grid item container p={2} pt={0}>
                <Grid item xs={12}><TCTitleLabel>My Schedule</TCTitleLabel></Grid>
                <Grid item container pt={2} xs={12} alignItems={"center"}>
                  <Grid item>
                    <TCLabel>Lock Edit</TCLabel>
                  </Grid>
                  <Grid item>
                    <Switch checked={form.lockEdit} onChange={() => {
                      setForm(prev => ({
                        ...prev,
                        lockEdit: !form.lockEdit
                      }))
                    }}/>
                  </Grid>
                </Grid>
                <Grid pt={1} item container xs={12}>
                    {/* List Inputan Date dan Hours */}
                    <Grid item>
                      <ScheduleHoursComponent setCourt={(e) => {
                        setForm(prev => ({
                          ...prev,
                          ...e
                        }))
                      }} 
                        currOpsHours={form.operationalHours}
                        setShowSummary={() => {}}
                        exceptionHours={orderSchedule}
                      />
                    </Grid>
                    <Grid item container xs={12} spacing={1}>
                        <Grid item xs={12}>
                          <TCTitleLabel bold>Exclude (Off)</TCTitleLabel>
                        </Grid>
                        <Grid item container xs={12} spacing={0.5}>
                            <Grid item xs={12}><TCLabel>Date</TCLabel></Grid>
                            <Grid item xs={12} md={12} lg={6}>
                              <TCInput value={offScheduleForm.date} 
                                  style={{weekStart: 1}}
                                  type="date" 
                                  onChange={(e) => {
                                      onChange({date: e.currentTarget.value, 
                                          day: moment(e.currentTarget.value).format("dddd")})
                                  }}
                                  inputProps={{
                                      InputProps: {
                                          // inputProps: { min: moment(new Date()).format("YYYY-MM-DD"), max: checkAdvancedDays()}
                                          inputProps: { min: moment(new Date()).format("YYYY-MM-DD") }
                                      }
                                  }}
                              />
                            </Grid>
                            <Grid item container xs spacing={1}>
                                <Grid item xs>
                                    <TCSelect value={offScheduleForm.startHours} 
                                    disabled={!offScheduleForm.date}
                                    options={listHours}
                                        onChange={(e) => {
                                            onChange({startHours: e, endHours: null})
                                        }}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <TCSelect value={offScheduleForm.endHours} 
                                    disabled={!offScheduleForm.startHours}
                                    options={listEndHours}
                                        onChange={(e) => {
                                            onChange({endHours: e})
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid pt={1} item xs={12} textAlign={"left"}>
                            <Button variant='contained' color="primary" onClick={() => {
                                saveSchedule()
                            }}>Add</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container xs={12} p={2} pt={0} spacing={0.5}>
                {
                    form.scheduleOff.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))
                      .map((value, index) => {
                        return (
                          <Grid item xs={12} md={12} lg={6}>
                            <Card className="custom-card-style" style={{border: '1px #0198E1 solid'}}>
                                <Grid p={1} container>
                                    <Grid item container>
                                        <Grid item xs>
                                            <Grid item>
                                                <TCLabel bold>{moment(new Date(value.date)).format("ddd, DD/MM/YYYY")}</TCLabel>                                            
                                            </Grid>
                                            <Grid item container spacing={0.5}>
                                                <Grid item>
                                                    <TCLabel bold>Off Hours :</TCLabel>
                                                </Grid>
                                                <Grid item>
                                                    <TCLabel>{`${value.hours[0]} - ${
                                                        `${Number(value.hours[value.hours.length - 1].split(':')[0]) + 1 < 10 ? `0${Number(value.hours[value.hours.length - 1].split(':')[0]) + 1}` : Number(value.hours[value.hours.length - 1].split(':')[0]) + 1}:00`
                                                    }`}</TCLabel>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} textAlign={"right"} onClick={() => {
                                            removeOffSchedule(index)
                                        }}>
                                            <Delete style={{width: 20, height: 20, color: '#E35480'}}/>
                                        </Grid>
                                    </Grid>
                                    <Grid item container>
                                      <TCLabel style={{
                                        fontSize: 10
                                      }}>Last update date :{moment(value.updatedDate).format("YYYY/MM/DD HH:mm:ss")}</TCLabel>
                                    </Grid>
                                </Grid>
                            </Card>
                          </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )

}

function checkAddHours (
  body,
  scheduleOff
) {

  var starthm = body.startHours;
  var endhm = body.endHours;
  var startDate = new Date("1970-01-01T" + starthm);
  var endDate = new Date("1970-01-01T" + endhm);

  if (Number(body.endHours.split(":")[0]) == 0) {
      endDate = new Date("1970-01-02T" + endhm);
  }
  
  var duration = moment.duration(moment(endDate).diff(moment(startDate)));
  var hours = duration.asHours();

  let startTime = Number(body.startHours.split(":")[0])
  let hoursMapped = []
  hoursMapped.push(body.startHours)
  console.log(hours)
  for (let j = 0; j < hours - 1; j ++) {
      startTime += 1
      hoursMapped.push(`${startTime < 10 ? `0${startTime}` : startTime}:00`)
  }

  console.log('here hours Mapped', hoursMapped)
  
  if (scheduleOff && scheduleOff.length > 0) {
      let isExist = false
      for (let i = 0; i < scheduleOff.length; i ++) {
          if (body.date == scheduleOff[i].date) {
              isExist = true
              scheduleOff[i].hours = hoursMapped
              scheduleOff[i].cmsUpdate = true
          }
      }
      if (isExist == false) {
          scheduleOff.push({
              date: body.date,
              day: body.day,
              hours: hoursMapped,
              cmsUpdate: true
          })
      }
  } else {
      // scheduleOff = [{
      //     date: body.date,
      //     hours: 
      // }]
      scheduleOff = [{
          date: body.date,
          day: body.day,
          hours: hoursMapped,
          cmsUpdate: true
      }]
  }
  return scheduleOff
}

function checkAdvancedDays (date) {
    if (date) {
        return moment(new Date()).add(20, 'days').toDate()
    } else {
        return moment(new Date()).add(20, 'days').format('YYYY-MM-DD')
    }
}