import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// material-ui
import { Box, Button, Card, Grid, Input, Typography, TextareaAutosize, TextField, Switch, FormControlLabel, Checkbox } from '@mui/material';

import { TCInput, TCSelect } from 'components/TopCourtComponents/Input/TopCourtInput';
import { TCLabel, TCTitleLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';
import { getAdminList } from 'services/adminServices';
import { getPlayers, loadPlayerList } from 'services/playerServices';
import { createVoucher } from 'services/voucherServices';
import { courtTypePackageOption, validityMonthOption } from 'constant/constant';
import { Confirm } from 'helper/Alert';
import { useNavigate } from 'react-router';


export default function AddVoucher() {
    const [form, setForm] = useState({
        courtType: null,
        session: null,
        user: null,
        requestBy: null,
        nominal: '',
        expiryDate: null,
        totalVoucher: null,
        isRedeemed: false,
        validity: null,
        smsDetail: "Congratulations! Here's Voucher Code for your Free Session: voucher_code. Redeem it before expired_date. (top-court.com)"
    })

    const [ joiForm, setJoiForm ] = useState({
        courtType: form.courtType ? null : "*",
        session: form.session ? null : "*",
        totalVoucher: form.totalVoucher ? null : "*",
        expiryDate: form.expiryDate ? null : "*",
        validity: form.validity ? null : "*"
    })

    const navigation = useNavigate()

    const onChange = (e) => {
        setForm(prev => ({
            ...prev,
            ...e
        }))
    }

    useEffect(() => {
        setJoiForm({
            courtType: form.courtType ? null : "*",
            session: form.session ? null : "*",
            totalVoucher: form.totalVoucher ? null : "*",
            expiryDate: form.expiryDate ? null : "*",
            validity: form.validity ? null : "*"
        })
    }, [form])

    const loadAdminOption = async (input) => {
        if (input.length < 3) {
            return
        }
        try {
            let { data, error } = await getAdminList({
                name: input
            });
            if (error) throw error
            if (data) {
                if (data.length <= 0) return
                return data.map(value => {
                    return {
                        label: value.name,
                        value: value._id
                    }
                })
            }
        } catch (error) {
            console.log(error.message || error)
            return ([])
        }
    }
    const loadUserOption = async (input) => {
        if (input.length < 3) {
            return
        }
        try {
            let { data, error } = await loadPlayerList({
                query: input
            });
            if (error) throw error
            if (data) {
                if (data.length <= 0) return
                return data.map(value => {
                    return {
                        label: value.name,
                        value: value._id
                    }
                })
            }
        } catch (error) {
            console.log(error.message || error)
            return ([])
        }
    }

    const addVoucher = async () => {
        try {
            let body = {
                ...form
            }
            if(body.user){
                body.user = body.user.value
            }
            if(body.requestBy){
                body.requestBy = body.requestBy.value
            }
            if(body.courtType) {
                body.courtType = body.courtType.value
            }
            if(body.validity) {
                body.validity = body.validity.value
            }
            
            let { data, error } = await createVoucher(body)
            if (error) throw error
            if (data) {
                navigation('/vouchers')
            }
        } catch (error) {
            Confirm("", error)
        }
    }

    const checkDisabled = () => {
        return joiForm.courtType || joiForm.courtType || joiForm.session || joiForm.totalVoucher 
        || joiForm.expiryDate || joiForm.validity
    }

    return (
        <>
            <Grid item container xs={12} md={12} lg={12}>
                <Grid xs={12} md={6} lg={6} sx={{ p: 1 }}>
                    <Card sx={{ mb: 3 }}>
                        <Box
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: 3
                            }}
                        >
                            <Grid container sx={{
                                p: 2
                            }}>
                                {/* <Grid item xs={12} md={12} sx={{ p: 1 }}>
                                    <TCLabel>Request By*</TCLabel>
                                    <AsyncSelect
                                        placeholder={'Select Admin...'}
                                        async
                                        defaultOptions
                                        loadOptions={loadAdminOption}
                                        value={form.requestBy} onChange={(e) => { onChange({ requestBy: e }) }}
                                    />
                                </Grid> */}
                                <Grid item xs={12} md={12} sx={{ p: 1 }}>
                                    <TCLabel>Court Type {joiForm.courtType ? <span style={{color: '#E35480'}}>{`${joiForm.courtType}`}</span> : ""}</TCLabel>
                                    <Select 
                                        value={form.courtType}
                                        onChange={(e) => {
                                            console.log(e)
                                            onChange({courtType: e})
                                        }}
                                        style={{width: '100%'}}
                                        options={courtTypePackageOption}/>
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ p: 1 }}>
                                    <TCInput label={"Session"} type={"number"} value={form.session} 
                                        showAlert={joiForm.session}
                                        onChange={(e) => onChange({ session: e.currentTarget.value })} />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ p: 1 }}>
                                    <TCInput label={"Total Voucher"} type={"number"} value={form.totalVoucher} 
                                    showAlert={joiForm.totalVoucher}
                                    onChange={(e) => onChange({totalVoucher: e.currentTarget.value})} />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ p: 1 }}>
                                    <TCInput 
                                    label={"Expiry Date"}
                                    type={"date"} value={form.expiryDate} 
                                    showAlert={joiForm.expiryDate}
                                    onChange={(e) => onChange({ expiryDate: e.currentTarget.value })} />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{p: 1}}>
                                    <TCSelect 
                                        label={
                                            <TCLabel>{"Session Validity"} {joiForm.validity ? <span style={{color: '#E35480'}}>{`${joiForm.validity}`}</span> : ""}</TCLabel>
                                        }
                                        value={form.validity}
                                        onChange={(e) => {
                                            onChange({validity: e})
                                        }}
                                        options={validityMonthOption}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ p: 1 }}>
                                    <TCLabel>Assigned User</TCLabel>
                                    <AsyncSelect
                                        placeholder={'Select User...'}
                                        isClearable
                                        async
                                        defaultOptions
                                        loadOptions={loadUserOption}
                                        value={form.user} onChange={(e) => { onChange({ user: e }) }}
                                    />
                                </Grid>
                                {
                                    form.user &&
                                    <Grid item container xs={12} md={12} sx={{ p: 1 }}>
                                        <Grid item xs={12}>
                                            <TCInput type={"textarea"} rows={3} label={"Notify SMS to User"}
                                                value={form.smsDetail}
                                                onChange={(e) => {
                                                    onChange({smsDetail: e.currentTarget.value})
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                            <Grid container direction={"row"} sx={{
                                pl: 3
                            }}>
                                <Button variant="contained" 
                                disabled={checkDisabled()}
                                onClick={addVoucher}>Create</Button>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}
