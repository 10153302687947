import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { Box, Card, Grid, Typography, Avatar, Button, Switch } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import { getCounters } from 'services/SettingServices';
import { getCategories } from 'services/categoryServices';
// project imports


export default function Categories({ }) {
    const [rows, setRows] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        try {
            let { data, error } = await getCategories();
            if (error) throw error
            if (data) {
                setRows(data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Button sx={{ mb: 2 }} variant="contained" disableElevation onClick={() => {
                navigate("/categories/add")
            }}>Add Category</Button>
            <Card sx={{ mb: 3 }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                {/* <TableCell>Type</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                rows.length > 0 && rows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell scope="row">
                                            <span className='span-link' onClick={() => {
                                                navigate(`/categories/edit/${row._id}`)
                                            }}>{row.name}</span></TableCell>
                                        {/* <TableCell scope="row">{row.address}</TableCell> */}
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </>
    )
}