import React, { Component } from 'react';

import { Chart } from 'chart.js';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

var chart = null;
// export const Month = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];

// export const ChartColor = {
//     backgroundColor: '#FE5522',
//     borderColor: '#FE5522',
//     borderWidth: 1
// }
class ReportChart extends Component {
    componentWillReceiveProps = (props) => {
        const defaultProps = { ...ReportChart.defaultProps };
        if (props.cData) {
            if (chart) {
                chart.destroy();
            }
            let ctx = document.getElementById('chartcanvas').getContext('2d');
            chart = new Chart(ctx, {
                type: props.cType ? props.cType : defaultProps.cType,
                data: props.cData || {},
                options: {
                    ...defaultProps.cOptions,
                    ...props.cOptions ? props.cOptions : {},
                }
            })
        }

    }
    render() {
        return (
            <Grid container item xs={12} style={{ width: '100%' }}>
                {/* chart */}
                {
                    !this.props.hideChart &&
                    <Grid item xs={12} md={6}>
                        <canvas id="chartcanvas"></canvas>
                    </Grid>
                }
                {/* right */}
                <Grid item xs={12} md={6}>
                    {this.props.children && this.props.children}
                </Grid>
            </Grid>

        );
    }
}
ReportChart.propTypes = {
    cType: PropTypes.string,
    cData: PropTypes.object,
    cOptions: PropTypes.object,
}
ReportChart.defaultProps = {
    cType: 'bar',
    cOptions: {
        legend: {
            display: false,
        },
        scales: {
            xAxes: [{
                barPercentage: 0.4
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                }
            }]
        },
    }
}

export default ReportChart;