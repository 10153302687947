import React, { useState, useEffect} from "react"

import { Button, Card, CardContent, Grid } from "@mui/material"
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput"
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel"

import { courtTypeOption } from "constant/constant"

import Select from "react-select";

function CourtListComponent ({
    data,
    index,
    saveCourt
}) {

    const [ courtForm, setCourtForm ] = useState({
        name: "",
        price: 0,
        ...data,
        type: data.courtType ? getOptionObject({ value: data.courtType, options: courtTypeOption}) : "",
    })

    const onChangeForm = (e) => {
        setCourtForm(prev => ({
            ...prev,
            ...e
        }))
    }

    useEffect(() => {
        saveCourt(courtForm, index)
    }, [courtForm])
    
    return (
        <Card sx={{
            mt: 1,
            boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;"
          }}>
            <div style={{padding: 10}}>
                <div style={{
                    width: '100%',
                    textAlign: 'right'
                }}>{data._id ? "Delete" : "X"}</div>
                <Grid container xs={12}>
                    <Grid xs={6} sx={{p: 1}}>
                        <TCLabel>Court Name</TCLabel>
                        <TCInput value={data.name} onChange={(e) => {
                            onChangeForm({name: e.currentTarget.value})
                        }} placeHolder="Input court name"></TCInput>
                    </Grid>
                    <Grid xs={6} sx={{p: 1}}>
                        <TCLabel>Court Type</TCLabel>
                        <Select value={courtForm.type} 
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            onChange={(e) => {
                            console.log(e)
                            onChangeForm({
                                type: e
                            })
                        }} options={courtTypeOption}/>
                    </Grid>
                </Grid>
                {/* <TCLabel>{currencyFormat(data.price)}</TCLabel> */}
            </div>
        </Card>
    )
}

function getOptionObject ({
    value, options
}) {
    let selectedValue = null
    for (let i = 0; i < options.length; i ++) {
        if (options[i].value === value) {
            selectedValue = options[i]
        }
    }
    return selectedValue
}

export default CourtListComponent