import React, { useEffect, useState } from 'react';

import Grid from "@mui/material/Grid";

import { addDays, addMonths, subDays } from 'date-fns';

import Calendar from 'react-calendar';
import moment from 'moment';

import { TCTitleLabel, TCLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import EventIcon from '@mui/icons-material/Event';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import 'react-calendar/dist/Calendar.css';
import { getCourtsSchedule, getPlaceDetail } from 'services/placeServices';
import { fontSize } from '@mui/system';

function TCCalendar ({
    onChangeForm
}) { 
    
    const [ placeId, setPlaceId ] = useState("653f5d9006520fccd4ab4604")
    const [selectedDefaultDate, setSelectedDefaultDate ] = useState(new Date())

    const [ courts, setCourts ] = useState([])
    const [ availableHours, setAvailableHours ] = useState(Hours)

    const [ hourList, setHourList ] = useState([])
    const [ openCalendar, setOpenCalendar ] = useState(false)

    const [ form, setForm ] = useState({
        selectedDate: toLocalGMT(new Date()),
        date: toGMT7(new Date()),
        month: toGMT7(new Date(), 'MM'),
        year: toGMT7(new Date(), 'YYYY'),
        court: null,
        hours: [],
        totalSessions: 0,
        totalPrice: 0,
    })

    const [ user, setUser ] = useState(null)
    const [ booking, setBooking ] = useState([])

    useEffect(() => {
        loadCourts()
    }, [])

    const checkBookingHours = (date, court) => {
        let dateString = toGMT7(date, 'YYYY-MM-DD')
        court = form.court
        let bookingInfo = {
            hours: [], 
            totalSessions: 0,
            totalPrice: 0
        }
        for (let i = 0; i < booking.length; i++) {
            let courtId = (booking[i].court && booking[i].court._id) ? booking[i].court._id.toString() : booking[i].court.toString()
            if (booking[i].date == dateString && courtId == court.toString()) {
                // hours = booking[i].hours;
                bookingInfo.hours = booking[i].hours
                bookingInfo.totalSessions = booking[i].totalSessions
                bookingInfo.totalPrice = booking[i].totalPrice
                break;
            }
        }
        return bookingInfo
    }

    useEffect(() => {
        if (form.court) { 
            getBookingsDateList()
        }
    }, [form.date, form.court])

    useEffect(() => {
        console.log('here form', form)
        onChangeForm(form)
    }, [form])

    useEffect(() => {
        let bookingHours = checkBookingHours(new Date(form.date))
        onChange({
            ...bookingHours
        });
    }, [booking])

    const onChange = (e) => {
        setForm(prev => ({
            ...prev,
            ...e
        }))
    }

    const loadCourts = async () => {
        try {
            let { data, error } = await getPlaceDetail(placeId)
            console.log('here data', data)
            setCourts(data.courts)
            setForm(prev => ({
                ...prev,
                court: prev.court ? prev.court : data.courts[0]._id ,
                courtData: prev.courtData ? prev.courtData : data.courts[0] ,
            }))
        } catch (error) {
            console.log(error)
        }
    }

    const getBookingsDateList = async () => {
        try {
            console.log(form)
            let { data, error } = await getCourtsSchedule(form.court, form.date)
            if (error) { throw error }
            if (data) setAvailableHours(data.hours)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div style={{width: '100%'}}>
                <Grid item container justifyContent={"flex-start"} >
                    <Grid item xs={2.75} textAlign={"center"}>
                        <div className={form.month === momentFormat(selectedDefaultDate, 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                            onClick={() => {
                                onChange({
                                    month: momentFormat(selectedDefaultDate, 'MM'),
                                    year: momentFormat(selectedDefaultDate, 'YYYY')
                                })
                            }}
                        >
                            {momentFormat(selectedDefaultDate, 'MMM YY')}
                        </div>
                    </Grid>
                    <Grid item xs={2.75} textAlign={"center"}>
                        <div className={form.month === momentFormat(addMonths(selectedDefaultDate, 1), 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                            onClick={() => {
                                onChange({
                                    month: momentFormat(addMonths(selectedDefaultDate, 1), 'MM'),
                                    year: momentFormat(addMonths(selectedDefaultDate, 1), 'YYYY')
                                })
                            }}
                        >
                            {momentFormat(addMonths(selectedDefaultDate, 1), 'MMM YY')}
                        </div>
                    </Grid>
                    <Grid item xs={2.75} textAlign={"center"}>
                        <div className={form.month === momentFormat(addMonths(selectedDefaultDate, 2), 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                            onClick={() => {
                                onChange({
                                    month: momentFormat(addMonths(selectedDefaultDate, 2), 'MM'),
                                    year: momentFormat(addMonths(selectedDefaultDate, 2), 'YYYY')
                                })
                            }}
                        >
                            {momentFormat(addMonths(selectedDefaultDate, 2), 'MMM YY')}
                        </div>
                    </Grid>
                    <Grid item xs={2.75} textAlign={"center"}>
                        <div className={form.month === momentFormat(addMonths(selectedDefaultDate, 3), 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                            onClick={() => {
                                onChange({
                                    month: momentFormat(addMonths(selectedDefaultDate, 3), 'MM'),
                                    year: momentFormat(addMonths(selectedDefaultDate, 3), 'YYYY')
                                })
                            }}
                        >
                            {momentFormat(addMonths(selectedDefaultDate, 3), 'MMM YY')}
                        </div>
                    </Grid>
                    <Grid item xs={1} textAlign={"center"} justifyContent={"center"}>
                        <div 
                            // className={form.month === momentFormat(addMonths(new Date(), 3), 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                            className='calendar-custom-header'
                            style={{fontSize: 15, padding: 0}}
                            onClick={() => {
                                setOpenCalendar(true)
                            }}
                        >
                            <div style={{marginTop: 5}}>
                                <EventIcon sx={{fontSize: 19.5}}/>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Calendar 
                    className={"react-calendar"}
                    onChange={(e) => {
                        let bookingInfo = checkBookingHours(e)
                        onChange({
                            selectedDate: toLocalGMT(e),
                            date: toGMT7(e), 
                            ...bookingInfo,
                            // hours: hours, 
                            // totalSessions: 0,
                            // totalPrice: 0
                        });
                    }}
                    tileClassName={(({date}) => {
                        if (moment(date).format('YYYY-MM-DD') == moment(form.selectedDate).format('YYYY-MM-DD')) {
                            return;
                        }
                        if (hourList.indexOf(moment(date).format('YYYY-MM-DD')) >= 0) {
                            return 'dotted'
                        }
                    })}
                    minDate={new Date()}
                    // maxDate={new Date(moment(addDays(new Date(), user ? (user.package && user.package.advancedDays) ? user.package.advancedDays : 31 : 31)).endOf('month'))} // Count by Days
                    // maxDate={new Date(moment(addMonths(new Date(), user ? (user.activeBenefits && user.activeBenefits.advancedMonths) ? user.activeBenefits.advancedMonths : 0 : 0)).endOf('month'))} // Count by Months
                    defaultValue={form.selectedDate} 
                    value={form.selectedDate}
                    showNavigation={false}
                    // activeStartDate={new Date(`${form.month}-01-${form.year}`)}
                    activeStartDate={new Date(form.year, (form.month - 1), 1)}
                />
            </div>
            <div style={{border: '0.5px solid #3447671F', height: '0.5px', marginTop: 10, width: '100%'}}/>
            <Grid container item xs={12} md={12} lg={12} style={{paddingLeft: 16, paddingRight: 16}}>
                <CourtComponent courtInfo={[]} form={form} courts={courts} onChange={onChange} checkBookingHours={checkBookingHours}/>
                <HoursComponent availableHours={availableHours} onChange={onChange} form={form} isNotActiveCalendar={selectedDefaultDate}/>
            </Grid>
        </>
    )
}

function CourtComponent ({
    courtInfo,
    form,
    courts,
    onChange,
    checkBookingHours
}) {

    return (
        <Grid container>
            <Grid item mt={2} mb={1} xs={12} md={12} lg={12}>
                <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                    <div style={{marginLeft: 0, display: 'flex', justifyContent: 'center'}}>
                        <TCLabel bold>Court</TCLabel>
                    </div>
                </div>
            </Grid>
            {
                courtType.map( (type, index) => {
                    return (
                        <Grid xs={4} mb={2} item container style={{
                            border: '1px dashed #808080',
                            borderRight: (index == courtType.length - 1) && '1px dashed #808080',
                            padding: 4,
                            borderRadius: 10
                        }}>
                            <Grid xs={12} item textAlign={"center"}>
                                <TCLabel>{type.label}</TCLabel>
                            </Grid>
                            <Grid item container style={{paddingTop: 3}} justifyContent={"center"}>
                            {
                                courts.map( value => {
                                    if (value.courtType === type.value)  {
                                        return (
                                            <Grid item>
                                                <div className={form.court === value._id ? 'selection-selected' : 'selection-available'} style={{textAlign: 'center', padding: 2,
                                                    paddingLeft: 8, paddingRight: 8, 
                                                    minWidth: 25, marginRight: 2, borderRadius: 8}}
                                                    onClick={() => {
                                                        let courtInfo = checkBookingHours(new Date(form.date), value._id)
                                                        onChange({
                                                            court: value._id, 
                                                            courtData: value,
                                                            ...courtInfo
                                                        })
                                                    }}
                                                >
                                                    {value.name}
                                                </div>
                                            </Grid>
                                        )
                                    }
                                })
                            }
                            </Grid>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

function HoursComponent ({
    form,
    onChange,
    availableHours,
    isNotActiveCalendar
}) {

    const updateSelectedTime = (value) => {

        let index = form.hours.indexOf(value.label);
        let newForm = form.hours;
        let sessions = form.totalSessions;
        let total = form.totalPrice;
        
        if (value.active && index < 0) {
            
            newForm = [ ...form.hours, value.label ];
            sessions += 1;
            total += value.nominee;

        } else if (index >= 0) {
            
            newForm.splice(index, 1);
            sessions -= 1;
            total -= value.nominee;

        }

        onChange({hours: newForm, totalSessions: sessions, totalPrice: total})
    }

    return (
        <Grid container>
            <Grid mb={1} item>
                <TCLabel bold>Time</TCLabel>
            </Grid>
            <Grid item container spacing={2}>
            {
                availableHours.map( value => {
                    return (
                        <Grid item xs={2}>
                            <div className={
                                    value.tournamentBooked ? 'selection-booked-others' : 
                                    value.idle ? 'selection-idle' : 
                                    value.booked ? value.ownBooked ? 'selection-booked-own' : 
                                    value.classBooked ? 'selection-booked-own' : 'selection-booked' : 
                                    value.active ? 
                                        form.hours.indexOf(value.label) >= 0 ? 
                                        'selection-selected' : 
                                        'selection-available' 
                                    : 'selection-disabled'
                                } 
                                style={{textAlign: 'center', padding: 5, 
                                    minWidth: 25, minHeight: 50, borderRadius: 8
                                }}
                                onClick={() => {
                                    if (value.idle || value.booked) { 
                                        // Confirm("", value.idle ? "Awaiting payment. Please check again" : value.ownBooked ? "My booking" : value.classBooked ? "Class Schedule" : "Court is booked")
                                        return; }
                                    updateSelectedTime(value)
                                }}
                            >
                                {/* <MKTypography variant="small" fontWeight="regular" color="inherit">{value.label}</MKTypography> */}
                                <div>{value.label}</div>
                                {
                                    // value.tournamentBooked ? <EmojiEventsIcon sx={{fontSize: 10}}/> : 
                                    value.tournamentBooked ? <EventAvailableIcon sx={{fontSize: 10}}/> : 
                                    value.idle ? <AccessTimeIcon sx={{fontSize: 10}}/> : 
                                    value.ownBooked && value.classBooked ? <span>
                                        <EventAvailableIcon sx={{fontSize: 10}}/>
                                        <SportsTennisIcon sx={{fontSize: 10}}/>
                                    </span> :
                                    value.ownBooked ? <EventAvailableIcon sx={{fontSize: 10}}/> : 
                                    value.classBooked ? <SportsTennisIcon sx={{fontSize: 10}}/> : 
                                    value.price &&
                                    // <MKTypography variant="body2" fontWeight="regular" color="inherit">{value.price}</MKTypography>
                                    <div>{value.price}</div>
                                }
                            </div>
                        </Grid>
                    )
                })
            }
            </Grid>
        </Grid>
    )
}

export const toUTC = (d, moment = false, format = 'YYYY-MM-DD') => {
    let offSetCount = d.getTimezoneOffset()
    if (offSetCount < 0) {  
        if (moment) { return moment(new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000)).format(format) }
        else { return new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000)}   
    } else {
        if (moment) { return moment(new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000)).format(format) }
        else { return new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000)}   
    }
}

export const toGMT7 = (d, format = 'YYYY-MM-DD') => {
    // let offSetCount = d.getTimezoneOffset()
    console.log('here moment info', `${new Date(d).getFullYear()}-${new Date(d).getMonth()}-${new Date(d).getDate()}`)
    // return moment(d.toISOString()).format(format)
    if (format == 'YYYY-MM-DD') {
        let currMonth = new Date(d).getMonth() + 1
        return `${new Date(d).getFullYear()}-${(currMonth < 10 ? `0${currMonth}` : currMonth )}-${new Date(d).getDate()}`
    } else {
        return moment(d.toISOString()).format(format)
    }
    // return `${new Date(d).getFullYear()}-${new Date(d).getMonth() + 1}-${new Date(d).getDate()}`
    // if (offSetCount > 0) {
    //     // console.log('GMT-check, offsetCount < 0', moment(new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000)).utcOffset(7).format(format))
    //     // console.log('GMT-check, offsetCount < 0 local', moment(toLocalGMT(d)).utcOffset(7).format(format))
    //     // return moment(new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000)).utcOffset(7).format(format)
    //     return moment(d.toISOString()).format(format)
    // } else {
    //     // console.log('GMT-check, offsetCount > 0', moment(new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000)).utcOffset(7).format(format))
    //     // console.log('GMT-check, offsetCount > 0 local', moment(toLocalGMT(d)).utcOffset(7).format(format))
    //     // return moment(new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000)).utcOffset(7).format(format)
    //     return moment(d.toISOString()).format(format)
    // }
}

export const momentFormat = (d, format = 'YYYY-MM-DD') => {
    return moment(d).format(format)
}

export const toLocalGMT = (d) => {
    return moment(d).toDate()
}

// Old - Cannot Handle UTC -
// export const toUTC = (d, moment = false, format = 'YYYY-MM-DD') => {
//     if (moment) { return moment(new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000)).format(format) }
//     else { return new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000)}
// }

// export const toGMT7 = (d, format = 'YYYY-MM-DD') => {
//     return moment(new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000)).utcOffset(7).format(format)
// }

export const Hours = [
    {
        label: "00:00",
        active: false
    },
    {
        label: "01:00",
        active: false
    },
    {
        label: "02:00",
        active: false
    },
    {
        label: "03:00",
        active: false
    },
    {
        label: "04:00",
        active: false
    },
    {
        label: "05:00",
        active: false
    },
    {
        label: "06:00",
        active: false
    },
    {
        label: "07:00",
        active: false
    },
    {
        label: "08:00",
        active: false
    },
    {
        label: "09:00",
        active: false
    },
    {
        label: "10:00",
        active: false
    },
    {
        label: "11:00",
        active: false
    },
    {
        label: "12:00",
        active: false
    },
    {
        label: "13:00",
        active: false
    },
    {
        label: "14:00",
        active: false
    },
    {
        label: "15:00",
        active: false
    },
    {
        label: "16:00",
        active: false
    },
    {
        label: "17:00",
        active: false
    },
    {
        label: "18:00",
        active: false
    },
    {
        label: "19:00",
        active: false
    },
    {
        label: "20:00",
        active: false
    },
    {
        label: "21:00",
        active: false
    },
    {
        label: "22:00",
        active: false
    },
    {
        label: "23:00",
        active: false
    }
]

export const courtType = [
    {label: "Indoor", value: "indoor"},
    {label: "Outdoor", value: "outdoor"},
    {label: "Hitting Room", value: "hitting_room"},
]

export default TCCalendar