import axios from 'axios'
import { reactLocalStorage } from 'reactjs-localstorage';
// import commonStore from "../stores/CommonStore";
// import { clearLS } from './functions/functions';

const queryParser = params => {
  let queryParams = ''
  for (let key in params) {
    if (!queryParams) {
      queryParams = queryParams.concat(`?${key}=${params[key]}`)
    } else {
      queryParams = queryParams.concat(`&${key}=${params[key]}`)
    }
  }
  return queryParams
}

// if (getCookie('ideal_current_token')) {
//   token = getCookie('ideal_current_token');
// }
const getToken = () => {
  let token = ''
  if (reactLocalStorage.get('user_token')) {
    token = reactLocalStorage.get('user_token');
  }
  // if (!token) {
  //   reactLocalStorage.clear()
  //   window.location.href = "/login"
  // }
  return token;
}
const responseError = (error, reject) => {
  if (error && error.response && error.response.data) {
    // jika menu diset custom permission tapi user role masih forbidden
    if (error.response.data.errorCode === 40301) {
      // commonStore.showErrorMessage(error.response.data.message);
      window.location.href = "/dashboard";
    }
    // check region
    if (error.response.data.errorCode === 22002) {
      // commonStore.showErrorMessage(error.response.data.message);
      window.location.href = "/dashboard";
    }
    if (error.response.data.statusCode === 403) {

    }
    if (error.response.data.statusCode === 405) {
      // clearLS();
      // deleteCookiesByName("ideal_current_token");
      // commonStore.showErrorMessage(error.response.data.message);
      window.location.href = "/login"
    }
  }

  if (error.response) {
    reject(error);
  } else {
    let err = {
      response: {
        data: `Can't Connect to Server!`
      }
    }
    reject(err);
  }
}

const defaultHeaders = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
  // 'Accept': 'application/json'
}

const Axios = {
  Get: ({ url, params, responseType, cancelToken }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url + queryParser(params),
          method: 'GET',
          headers: {
            ...defaultHeaders,
            'x-access-token': getToken()
          },
          cancelToken: cancelToken ? cancelToken.token : null,
          ...(responseType ? { responseType } : {})
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          responseError(error, reject);
        })
    })
  },
  Post: ({ url, params, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url,
          method: 'POST',
          headers: {
            ...defaultHeaders,
            'x-access-token': getToken()
          },
          data: data
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          responseError(error, reject);
          // reject(error)
        })
    })
  },
  Put: ({ url, params, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url + queryParser(params),
          method: 'PUT',
          headers: {
            ...defaultHeaders,
            'x-access-token': getToken()
          },
          data
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          responseError(error, reject);
          // reject(error)
        })
    })
  },
  Patch: ({ url, params, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url + queryParser(params),
          method: 'PATCH',
          headers: {
            ...defaultHeaders,
            'x-access-token': getToken()
          },
          data
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          responseError(error, reject);
          // reject(error)
        })
    })
  },
  Delete: ({ url, params, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url + queryParser(params),
          method: 'DELETE',
          headers: {
            ...defaultHeaders,
            'x-access-token': getToken()
          }
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          responseError(error, reject);
          // reject(error)
        })
    })
  }
}

export default Axios
