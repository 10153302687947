import React, { useState } from 'react';

import PropTypes from 'prop-types';

// material-ui
import { Box, Card, Grid, Typography, Avatar, TablePagination, Switch, Dialog, Button } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { getPlayersv2, updatePlayer } from 'services/playerServices';
import { useEffect } from 'react';
import PlayerFilter from './playerFilter';
import moment from 'moment';
import { TCLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';
import TableComponent from 'components/TopCourtComponents/Table/TableComponent';
import { capitalizeFirstLetter } from 'helper/String';

import { useDebounce } from 'use-debounce'

export default function Players ({

}) {

    const [ loading, setLoading ] = useState(false)
    const [ filter, setFilter ] = useState({
        name: '',
        phoneNumber: '',
        gender: null,
        birthYearType: {label: "<>", value: "ib"},
        birthYearStart:'',
        birthYearEnd:'',
        registerDateType: {label: "<>", value: "ib"},
        registerDateStart:'',
        registerDateEnd:'',
        userStatus: null,
        subscribeStatus: null,
        academyStatus:'',
        classType: {label: "<>", value: "ib"},
        classStart:'',
        classEnd:'',
        matchType: {label: "<>", value: "ib"},
        matchStart:'',
        matchEnd:'',
        sessionType: {label: "<>", value: "ib"},
        sessionStart:'',
        sessionEnd:'',
        isActive: null,
        load: true
    })
    const [ rows, setRows ] = useState([])
    const [ totalData, setTotalData ] = useState(0)
    const [ debouncedFilter ] = useDebounce(filter,500)
    const [ openDialog, setOpenDialog ] = useState({
        open: false,
        data: null
    })

    useEffect(() => {
        (async()=>{
            if (debouncedFilter.load == true) {
                console.log('ini cek filter',filter)
                setRows([])
                loadData()   
            }
        })()
    }, [debouncedFilter])


    useEffect(() => {
        console.log('here data info', rows)
    }, [rows])

    const loadData = async () => {
        console.log('changeFilter', filter)
        setLoading(true)
        let { data, error } = await getPlayersv2({
            ...filter,
            phoneNumber: filter.phoneNumber ? filter.phoneNumber.replace(/[^0-9]+/g, "") : '',
            gender: filter.gender ? filter.gender.value : '',
            birthYearType : filter.birthYearType ? filter.birthYearType.value : '',
            registerDateType : filter.registerDateType ? filter.registerDateType.value : '',
            classType: filter.classType ? filter.classType.value : '',
            matchType: filter.matchType ? filter.matchType.value : '',
            sessionType: filter.sessionType ? filter.sessionType.value : '',
            userStatus: filter.userStatus ? filter.userStatus.value : '',
            subscribeStatus: filter.subscribeStatus ? filter.subscribeStatus.value : '',
            birthYearStart: filter.birthYearStart ? moment(new Date(filter.birthYearStart)).format("YYYY") : "",
            birthYearEnd: filter.birthYearEnd ? moment(new Date(filter.birthYearEnd)).format("YYYY") : "",
            isActive: filter.isActive ? filter.isActive.value : '',
            page: filter.page ? filter.page : 0
        });
        console.log('here data info', data)
        setLoading(false)
        if (data) {    
            setRows( prev => [
                ...data.listPlayers
            ])
            setTotalData(data.totalData)
        }
    }

    const onChangeFilter = (e) => {
        setFilter((prev) => ({
            ...prev,
            load: true,
            ...e
        }))
    }

    const clearFilter = () => {
        setFilter({
            name: '',
            phoneNumber: '',
            gender: null,
            birthYearType: {label: "<>", value: "ib"},
            birthYearStart:'',
            birthYearEnd:'',
            registerDateType: {label: "<>", value: "ib"},
            registerDateStart:'',
            registerDateEnd:'',
            userStatus:'',
            subscribeStatus:'',
            academyStatus:'',
            classType: {label: "<>", value: "ib"},
            classStart:'',
            classEnd:'',
            matchType: {label: "<>", value: "ib"},
            matchStart:'',
            matchEnd:'',
            sessionType: {label: "<>", value: "ib"},
            sessionStart:'',
            sessionEnd:'',
            isActive: null,
            load: true,
            page: 0
        })
    }

    const setUserStatus = (data) => {
        return data.superuser ? 'Superuser' : data.coachStatus ? 'Coach' : data.sessions > 0 ? 'Subscriber' : 'Regular' 
    }

    const onChangePage = (e) => {
        setFilter(prev => ({
            ...prev,
            ...e
        }))
    }

    const valueCustomator = (row) => {
        return ({
            profile: <Avatar
                src={row.profilePic}
                aria-haspopup="true"
                color="inherit"
            />,
            name: <span className='span-link' onClick={() => { window.location.href = `/users/detail/${row._id}`}}>{row.name ? row.name : '-'}</span>,
            gender: row.gender? capitalizeFirstLetter(row.gender) : '-',
            phoneNumber: row.phoneNumber,
            packageEnd: row.packageEnd ? moment(row.packageEnd).format("YYYY/MM/DD") : row.activeBenefits ? moment(row.activeBenefits.validityStart).add(row.activeBenefits.validity, "month").format("YYYY/MM/DD") : "-",
            userStatus: setUserStatus(row),
            packageInfo: row.activeBenefits ? 
            <Grid container spacing={2} sx={{width: 200}} textAlign={"center"}>
                <Grid xs={4} item container>
                    <Grid item xs={12}><TCLabel>All Court</TCLabel></Grid>
                    <Grid item xs={12}>{row.activeBenefits.sessions.all_court}</Grid>
                </Grid>
                <Grid xs={4} item container>
                    <Grid item xs={12}><TCLabel>Outdoor</TCLabel></Grid>
                    <Grid item xs={12}>{row.activeBenefits.sessions.outdoor}</Grid>
                </Grid>
                <Grid xs={4} item container>
                    <Grid item xs={12}><TCLabel>Hitting</TCLabel></Grid>
                    <Grid item xs={12}>{row.activeBenefits.sessions.hitting_room}</Grid>
                </Grid>
            </Grid> : '-',
            sessions: row.sessions ? row.sessions : '-',
            registerDate: moment(row.createdDate).format("YYYY/MM/DD HH:mm:ss"),
            blackList: <Switch checked={!row.isActive} 
            color='error'              
            onChange={() => {
                setOpenDialog({
                    open: true,
                    data: row
                })
            }} />
        })
    }

    const updateUserBlacklistStatus = async () => {
        let { data, error } = await updatePlayer({
            _id: openDialog.data._id,
            isActive: !openDialog.data.isActive
        })
        if (data) {
            setOpenDialog({
                data: null,
                open: false
            })
            loadData()
        }
    }

    return (
        <>
            <PlayerFilter filter={filter} onChangeFilter={onChangeFilter} clearFilter={clearFilter}/>
            <TableComponent
                columns={[
                    { label: "" },
                    { label: "Name" },
                    { label: "Gender" },
                    { label: "Reg Phone Number" },
                    { label: "Pack Valid Date" },
                    { label: "User Status" },
                    { label: "Session Balance" },
                    { label: "Total Session Balance" },
                    { label: "Regis Date" },
                    { label: "Blacklist" },
                ]}
                rows={rows}
                valueCustomator={valueCustomator}
                loading={loading}
                totalData={totalData}
                page={filter.page}
                pageChange={(e) => {
                    onChangePage({page: e})
                }}

            />
            <Dialog open={openDialog.open}
                onClose={() => setOpenDialog({
                    data: null,
                    open: false
                })}
            >
                <Grid p={2} container xs={12} sx={{
                    minWidth: '20%'
                }}>
                    <Grid>
                        <TCLabel>Blacklist user ?</TCLabel>
                    </Grid>
                    <Grid container xs={12} spacing={1} pt={1} justifyContent={"right"}>
                        <Grid item>
                            <Button onClick={() => { setOpenDialog(prev => ({
                            ...prev,
                            data: null,
                            open: false })) }}>Cancel</Button>
                        </Grid>
                        {/* <Grid item>
                            <Button color='error' variant='contained' onClick={() => { rejectOrder() }} >Reject</Button>
                        </Grid> */}
                        <Grid item>
                            <Button variant='contained' style={{
                                backgroundColor: openDialog.data ? openDialog.data.isActive == true ? 
                                '#E35480' : '#0198E1' : '#0198E1'
                            }} onClick={() => { 
                                updateUserBlacklistStatus()
                            }} >{openDialog.data ? openDialog.data.isActive == true ? "Blacklist" : "Lift Blacklist Status" : "Blacklist"}</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    )
}