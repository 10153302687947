import { client, errorValidation } from "./service.js";
// import { Delete, Get, Patch, Post, Put } from "./RestClient.js";

export async function getCategories(query) {
  try {
      let response = await client.get(`/category`);
      if (response.data.statusCode !== 200) { throw response.data.data; }
      let data = response.data.data;
      return { data };
    } catch (e) {
      // return { error: e.response.data.errorCode }
      return errorValidation(e)
    }
}

export async function getCategory (id) {
  try {
    let response = await client.get(`/category/${id}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return errorValidation(e)
  } 
}

export async function createCategory(body) {
    try {
        let response = await client.post(`/category/create`, {
            ...body
        });
        if (response.data.statusCode !== 200) { throw response.data.data; }
        let data = response.data.data;
        return { data };
      } catch (e) {
        // return { error: e.response.data.errorCode }
        return errorValidation(e)
      }
}

export async function udpateCategory(body) {
  try {
      let response = await client.post(`/category/update`, {
          ...body
      });
      if (response.data.statusCode !== 200) { throw response.data.data; }
      let data = response.data.data;
      return { data };
    } catch (e) {
      // return { error: e.response.data.errorCode }
      return errorValidation(e)
    }
}