import React, { useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel, Grid, Switch } from "@mui/material";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { TCInput, TCSelect } from "components/TopCourtComponents/Input/TopCourtInput";
import { Confirm } from "helper/Alert";
import { getPlayerDetail, updatePlayer } from "services/playerServices";
import DateRangeInput from "components/TopCourtComponents/Input/DateRangeFilter";
import { advancedSchedule, userStatus, userStatusOptions } from "constant/constant";
import moment from "moment";
import { toGMT7 } from "components/TopCourtComponents/TCCalendar";
import { toastMessage } from "components/Toast/Toast";

function PlayerInfo({
    playerId,
    reload
}) {

    const [form, setForm] = useState({
        name: "",
        counter: false,
        staff: false,
        birthYear: "",
        phoneNumber: "",
        alternativePhoneNumber: "",
        description: "",
        superuser: false,
        advancedSchedule: null,
        userStatus: null
    })
    const [editPhone, setEditPhone] = useState(false)

    const [joiForm, setJoiForm] = useState({
        name: null,
        birthYear: null,
        phoneNumber: null,
    })

    useEffect(() => {
        if (playerId) {
            loadUserDetail(playerId)
        }
    }, [playerId])

    useEffect(() => {
        setJoiForm({
            name: form.name ? null : ' required',
            birthYear: form.birthYear && form.birthYear >= 1924 && form.birthYear <= 2024 ? null : ' must between 1924 and 2024',
            phoneNumber: form.phoneNumber? null : ' required',
        })
    }, [form])

    const disabledForm = () => {
        return joiForm.name || joiForm.birthYear || joiForm.phoneNumber
    }

    const filterOptions = () => {
        return userStatus.filter(option => option.value !== 'superuser' && option.value !== 'subscriber');
    };

    const onChange = (e) => {
        setForm(prev => ({
            ...prev,
            ...e
        }))
    }

    const loadUserDetail = async (e) => {
        let { data, error } = await getPlayerDetail(playerId)
        if (error) { Confirm("", error) }
        if (data) {
            let advancedScheduleSelected = null
            let currentAdvanceSchedule = data.advancedSchedule ? data.advancedSchedule :
                data.activeBenefits ? data.activeBenefits.advancedMonths > 0 ? data.activeBenefits.advancedMonths : 1 : 1
            for (let i = 0; i < advancedSchedule.length; i++) {
                if (advancedSchedule[i].value === currentAdvanceSchedule) {
                    advancedScheduleSelected = advancedSchedule[i]
                }
            }
            setForm(prev => ({
                ...prev,
                ...data,
                advancedSchedule: advancedScheduleSelected,
                userStatus: data.coachStatus ? { label: "Coach", value: "coach" }
                    : data.rallyPartnerStatus ? userStatusOptions[0]
                        : data.ballBoyStatus ? userStatusOptions[1] : userStatus[0],
                counter: data.isCounter,
                staff: data.isStaff
            }))
        }
    }

    const updateUser = async (e) => {
        let { data, error } = await updatePlayer({
            ...form,
            advancedSchedule: form.advancedSchedule ? form.advancedSchedule.value : null,
            userStatus: form.userStatus ? form.userStatus.value : null,
            isCounter: form.counter ? form.counter : false,
            isStaff: form.staff ? form.staff : false
        })
        if (error) { Confirm("", error) }
        if (data) {
            console.log('here after update - 0', data)
            toastMessage('Update player success', {
                toastId: "player-success",
                type: "success"
            });
            // Confirm("", "Update player success")
            let advancedScheduleSelected = null
            let currentAdvanceSchedule = data.data.advancedSchedule ? data.data.advancedSchedule :
                data.data.activeBenefits ? data.data.activeBenefits.advancedMonths : 1
            for (let i = 0; i < advancedSchedule.length; i++) {
                if (advancedSchedule[i].value === currentAdvanceSchedule) {
                    advancedScheduleSelected = advancedSchedule[i]
                }
            }
            setForm(prev => ({
                ...prev,
                ...data,
                advancedSchedule: advancedScheduleSelected
            }))
            reload(true)
        }
    }

    const onChangePhoneNumber = (e) =>{
        const newValue = e.target.value.replace(/[^0-9]/g, '');
        const formattedValue = newValue.length <= 15 ? '+' + newValue : form.phoneNumber;
        setForm(prev => ({
            ...prev,
            phoneNumber: formattedValue
        }))
    }


    return (
        <Grid container md={12} spacing={2} sx={{ margin: '-1rem', padding: '1rem' }}>
            <Grid item xs={12} md={5} >
                <TCInput label={"Name"} showAlert={joiForm.name} onChange={(e) => {
                    onChange(e.target.value.length <=20? { name: e.currentTarget.value }:'')
                }} value={form.name} />
            </Grid>
            <Grid item xs={12} md={2.5} >
                <TCInput showAlert={joiForm.birthYear} label={"Birth Year"} disabled={false} value={form.birthYear} onChange={(e) => onChange(e.target.value.length <=4?{ birthYear: e.target.value.replace(/[^0-9]+/ig, '')}:'' )} />
                {/* <DateRangeInput showLabel={true} label={"Birth Year"}
                    disabledRange={true}
                    startDate={form.birthYear}
                    format={"yyyy"}
                    onChangeStartDate={(e) => { onChange({ birthYear: toGMT7(e, 'YYYY') }) }}
                /> */}
            </Grid>
            <Grid item xs={12} md={2.5} >
                <FormControlLabel sx={{ paddingTop: 1.5 }} disabled={false} control={<Checkbox checked={form.gender == "male"} onChange={() => {
                   onChange({gender: "male"})
                }} />} label={<TCLabel>Male</TCLabel>} />
                <FormControlLabel sx={{ paddingTop: 1.5 }} disabled={false} control={<Checkbox checked={form.gender == "female"} onChange={() => {
                   onChange({gender: "female"})
                }} />} label={<TCLabel>Female</TCLabel>} />
                {/* <TCInput showAlert={joiForm.birthYear} label={"Gender"} disabled={false} value={form.gender} onChange={(e) => onChange(e.target.value.length <=4?{ birthYear: e.target.value.replace(/[^0-9]+/ig, '')}:'' )} /> */}
                {/* <DateRangeInput showLabel={true} label={"Birth Year"}
                    disabledRange={true}
                    startDate={form.birthYear}
                    format={"yyyy"}
                    onChangeStartDate={(e) => { onChange({ birthYear: toGMT7(e, 'YYYY') }) }}
                /> */}
            </Grid>
            <Grid item container md={5} >
                <Grid item xs md >
                    <TCInput showAlert={joiForm.phoneNumber} label={"Reg Phone Number"} disabled={editPhone ? false : true} value={form.phoneNumber} 
                        // onChange={(e) => onChange(e.target.value.length<=15?{ phoneNumber: e.target.value.replace(/[^+0-9]|(?:\+)[^0-9]+/g, '')}:'')} 
                        onChange={(e) => onChangePhoneNumber(e)}
                    />
                </Grid>
                <Grid item pt={2} pl={0.5} mr={3} sx={2} md={2}>
                    <Button variant="contained" onClick={() => setEditPhone(!editPhone)}>Edit</Button>
                </Grid>
            </Grid>
            <Grid item xs={12} md={5} >
                <TCInput label={"Alternative Phone Number"} disabled={false} value={form.alternativePhoneNumber} onChange={(e) => onChange({ alternativePhoneNumber: e.target.value.replace(/[^0-9+]+/g, '') })} />
            </Grid>
            <Grid container xs={12} item md={5} spacing={2}>
                <Grid item xs={12} md={6}>
                    <div className="customDatePickerWidth">
                        <DateRangeInput startDate={calculatePackValidDate(form)} showLabel={true} disabledRange={true}
                            disabledStart={!form.activeBenefits || (form.activeBenefits && !form.activeBenefits.validityStart)}
                            label={"Pack Valid Date"}
                            onChangeStartDate={(e) => {
                                onChange({ packageEnd: e })
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={6} >
                    <TCSelect label={"Advanced Booking"} value={form.advancedSchedule} options={advancedSchedule} onChange={(e) => {
                        if (e.value === 9999) { onChange({ advancedSchedule: e, superuser: true }) }
                        else onChange({ advancedSchedule: e, superuser: false })
                    }} />
                </Grid>
            </Grid>
            <Grid item xs={12} md={5} >
                <TCInput label={"Registered Date"} disabled={true} value={moment(form.createdDate).format('YYYY/MM/DD HH:mm:ss')} onChange={(e) => onChange({ alternativePhoneNumber: e.target.value.replace(/[^0-9+]+/g, '') })} />
            </Grid>
            <Grid item xs={12} md={5}>
                <TCLabel style={{
                    color: 'rgb(133, 133, 133)'
                }}>Blacklist User</TCLabel>
                <Switch checked={!form.isActive} color="error" onChange={() => {
                    onChange({isActive: !form.isActive})
                }} />
            </Grid>
            <Grid container item xs={12} md={5} >
                <Grid item>
                    <FormControlLabel sx={{ paddingTop: 1.5 }} disabled={true} control={<Checkbox checked={form.superuser} onChange={() => {
                        if (!form.superuser) { onChange({ superuser: !form.superuser, advancedSchedule: { value: 9999, label: "All" } }) }
                        else onChange({ superuser: !form.superuser, advancedSchedule: null })
                    }} />} label={<TCLabel>Superuser</TCLabel>} />
                    <FormControlLabel sx={{ paddingTop: 1.5 }} disabled={false} control={<Checkbox checked={form.counter}
                        onChange={() => {
                            onChange({
                                counter: !form.counter
                            })
                        }} />} label={<TCLabel>Counter</TCLabel>} />
                    <FormControlLabel sx={{ paddingTop: 1.5 }} disabled={false} control={<Checkbox checked={form.staff}
                        onChange={() => {
                            onChange({
                                staff: !form.staff
                            })
                        }} />} label={<TCLabel>Staff</TCLabel>} />
                </Grid>
                <Grid item xs>
                    <TCSelect label={"User Status"} disabled={false}
                        value={form.userStatus} options={filterOptions()} onChange={(e) => {
                            onChange({ userStatus: e })
                        }} />
                </Grid>
            </Grid>
            <Grid item xs={12} md={10} >
                <TCInput type={"textarea"} rows={4} maxRows={6} label={"Description"} onChange={(e) => {
                    onChange({ description: e.currentTarget.value })
                }} value={form.description} />
            </Grid>
            <Grid item xs={12} md={10} >
                <Button variant="contained" disabled={disabledForm()} onClick={() => { updateUser() }}>Update</Button>
            </Grid>
        </Grid>
    )
}

function calculatePackValidDate(data) {
    // console.log(data.activeBenefits.validityStart, moment(data.activeBenefits.validityStart).add(data.activeBenefits.validity, "month").format("YYYY/MM/DD"))
    return data.activeBenefits ? data.packageEnd ? moment(data.packageEnd.split('T')[0]).format('YYYY-MM-DDT17:00:00.000') : moment(data.activeBenefits.validityStart).add(data.activeBenefits.validity, "month").format('YYYY-MM-DDT17:00:00.000') : null
}

export default PlayerInfo