import React, { useState, useEffect, useMemo } from 'react';
// material-ui
import { Box, Button, Card, Grid } from '@mui/material';
import { GoogleMap, Marker, StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";

// project imports
import { getParamsFromURL } from 'helper/Params';

import AddCourtComponent from './component/addCourtComponent';
import DropZoneComponent from 'components/DropzoneComponent';
import OperationalHoursComponent from './component/operationalHoursComponent';
import { TCInput } from 'components/TopCourtComponents/Input/TopCourtInput';
import { TCLabel, TCTitleLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';

import { createPlaces, getPlaceDetail, updatePlaces } from 'services/placeServices';
import { handleUpload } from 'helper/S3';

import courtMarker from "../../../assets/images/ground40.png"
import { Confirm } from 'helper/Alert';

function AddCourts ({

}) {

  const [ bounds, setBounds ] = useState(null)
  const [ courtImages, setCourtImages ] = useState([])
  const [ form, setForm ] = useState({
    name: "",
    photos: [],
    layoutPhotos: [],
    address: "",
    geoLocation: null,
    description: "",
    courts: [],
    prices: [],
    operationalHours: [],
    showSummary: false
  })
  const [searchBox, setSearchBox] = useState(null);
  const geoLocation = {
    lat: 3.5952, lng: 98.6722
  }

  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: "AIzaSyAKrWUuRu01ZDG_y2inffSugb_e9EveAZU",
  //   libraries: ["places"]
  // });
  const center = useMemo(() => (form.geoLocation || geoLocation), [form.geoLocation]);

  const onPlacesChanged = () => {
    console.log(searchBox.getPlaces())
    setForm( prev => ({
      ...prev,
      geoLocation: {
        lat: searchBox.getPlaces()[0].geometry.location.lat(),
        lng: searchBox.getPlaces()[0].geometry.location.lng()
      }
    }))
  };
  const onSBLoad = ref => {
    setSearchBox(ref);
  };

  const deleteCourtImages = (index) => {
    let currImages = courtImages
    currImages.splice(index, 1); 
    setCourtImages(prev => ([...currImages]))
  }

  const setCourt = (e) => {
    console.log('savePrice', e)
    if (e) {
        setForm(prev => ({
            ...prev,
            ...e
        }))
    }
  }

  useEffect(() => {
    let { isEdit, params } = getParamsFromURL(window);
    if (isEdit) { getPlaceInfo(params) }
  }, [])

  useEffect(() => {
    console.log(courtImages.length)
  }, [courtImages])

  useEffect(() => {
    console.log(form)
  }, [form])

  const getPlaceInfo = async (placeId) => {
    let { data, error } = await getPlaceDetail(placeId)
    if (error) { throw error }
    if (data) { 
      console.log('here court data', data)
      setForm( prev => ({
        ...prev,
        ...data.place,
        courts: data.courts,
        prices: data.prices
      }))
      setCourtImages(prev => ([...data.place.photos]))
    }
  }

  const saveForm = async () => {
    // handleUpload
    let body = {
        ...form
    }
    if (courtImages) {
      let courtImgs = []
      for (let i = 0 ; i < courtImages.length; i ++) {
        if (courtImages[i].name) {
          let images = await handleUpload(courtImages[i])    
          courtImgs = courtImgs.concat(images)
        } else {
          courtImgs = courtImgs.concat(courtImages[i])
        }
      }
      body.photos = courtImgs
    }
    if (form.layoutPhotos) {
      let layoutImgs = []
      for (let i = 0 ; i < form.layoutPhotos.length; i ++) {
        if (form.layoutPhotos[i].name) {
          let images = await handleUpload(form.layoutPhotos[i])
          layoutImgs = layoutImgs.concat(images)
        } else {
          layoutImgs = layoutImgs.concat(form.layoutPhotos[i])
        }
      }
      body.layoutPhotos = layoutImgs
    }

    if (form.courts) {
      let courts = []
      for (let i = 0; i < form.courts.length; i ++) {
        let newBody = form.courts[i]
        newBody.type = form.courts[i].type ? form.courts[i].type.value : form.courts[i].courtType ? form.courts[i].courtType : ""
        courts.push(newBody)
      }
      body = {
        ...body,
        courts
      }
    }
    if (form.prices) {
      let prices = []
      for (let i = 0; i < form.prices.length; i ++) {
        let newBody = form.prices[i]
        newBody.type = form.prices[i].type ? form.prices[i].type.value : form.prices[i].courtType ? form.prices[i].courtType : ""
        newBody.startHours = form.prices[i].startHours ? form.prices[i].startHours.value ? form.prices[i].startHours.value : form.prices[i].startHours : ""
        newBody.endHours = form.prices[i].endHours ? form.prices[i].endHours.value ? form.prices[i].endHours.value : form.prices[i].endHours : ""
        newBody.dayString = form.prices[i].days ? form.prices[i].days.value : form.prices[i].dayString ? form.prices[i].dayString : null
        newBody.day = form.prices[i].days ? form.prices[i].days.number : form.prices[i].day ? form.prices[i].day : null
        prices.push(newBody)
      }
      body = {
        ...body,
        prices
      }
    }
    if (body._id) {
      // call update API
      let { data, error } = await updatePlaces(body)
      if (error) { console.log(error) 
        Confirm("", "Failed update place")
      }
      if (data) {
        Confirm("","Success update place")
      }
    } else {
      let { data, error } = await createPlaces(body)
      if (error) { console.log(error) 
        Confirm("","Failed add new place")
      }
      if (data) { 
        Confirm("","Success add new place")
      }
    }
  }

  return (
    <>
      <Grid container xs={12} md={12}>
        <Card sx={{ mb: 3 }}>
          <Box
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              py: 4.5,
            }}
          >
            <Grid container>
              <Grid container xs={12} md={8}>
                <Grid item container sx={{
                    p: 2
                }}>
                    <Grid item xs={12} md={12} sx={{
                        p: 1,
                    }}>
                        <TCLabel>Court Photo</TCLabel>
                        <DropZoneComponent
                          className="drop-zone"
                          uploadName='courtImage'
                          file={courtImages}
                          isMultiple={true}
                          onDrop={(files, event) => {
                                let currImages = courtImages ? courtImages : []
                                for (let i = 0; i < files.length; i ++) {
                                    currImages = currImages.concat(files[i])
                                }
                                setCourtImages(currImages)
                          }}
                        >
                          <div className='dropzone mb-0' style={{
                              textAlign: 'center',
                              minHeight: '150px'
                          }}>
                              <div style={{ padding: 10, alignItems: 'center'}}>
                                <label>
                                    <i className='fa fa-upload mr-1' />
                                    Drop image to upload
                                </label>
                                <br />
                                <label
                                    htmlFor={"courtImage"}
                                    style={{ cursor: 'pointer' }}
                                    className='dropzone-child mb-0'>
                                    or Click to Browse
                                </label>
                                <div style={{overflowX: 'auto'}}>
                                {
                                    (courtImages && courtImages.length > 0) &&
                                    <div style={{ textAlign: 'left' }}>
                                        Uploaded file : 
                                    { 
                                        courtImages.map( (value, index) => {
                                            return (
                                                <div key={`${value.name}${index}`} className='d-flex mb-1' style={{ textAlign: 'left' }}>
                                                    <img src={(!value.name && value.includes("https")) ? value : URL.createObjectURL(value)} style={{minWidth: '100px', maxHeight: '50px', objectFit: 'contain'}}
                                                        onClick={() => {
                                                            // viewImageDetail({
                                                            //     open: true,
                                                            //     url: false,
                                                            //     file: value
                                                            // })
                                                        }}
                                                    />
                                                    <label className='mt-1 mb-0 ml-1' style={{alignItems: 'flex-end'}}>
                                                        {value.name}
                                                        <span style={{marginLeft: 5, color: 'gray', cursor: 'pointer'}} onClick={() => {
                                                          deleteCourtImages(index)
                                                        }}>X</span>
                                                        {/* <i className="ml-2 fa fa-times-circle" style={{ color: 'gray', cursor: 'pointer' }} onClick={() => {
                                                            deleteCourtImages(value)
                                                        }} /> */}
                                                    </label>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                }
                                </div>
                              </div>
                          </div>
                        </DropZoneComponent>
                    </Grid>
                </Grid>
                <Grid item container direction={"row"} sx={{
                    p: 2, pt: 0
                }}>
                    <Grid xs={12} md={12} item sx={{
                        p: 1
                    }}>
                        <TCInput fullWidth={true} onChange={(e)=> {
                          setCourt({name: e.currentTarget.value})
                        }} label={"Place Name"} value={form.name}/>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{
                        p: 1
                    }}>
                        <TCInput fullWidth={true} onChange={(e)=> {
                          setCourt({address: e.currentTarget.value})
                        }} label={"Address"} value={form.address} type={'textarea'} rows={2} maxRows={2}/>
                    </Grid>
                    {/* {
                      !isLoaded ? (
                        <h1>Loading...</h1>
                      ) : (
                        <Grid item xs={12} md={12} sx={{
                            p: 1
                        }}>
                            <GoogleMap
                              mapContainerClassName="map-container"
                              center={center}
                              zoom={15}
                              mapTypeControl={false}
                            >
                              <StandaloneSearchBox
                                onPlacesChanged={onPlacesChanged}
                                onLoad={onSBLoad}
                              >
                                <input
                                  type="text"
                                  placeholder="Search location name"
                                  style={{
                                    boxSizing: `border-box`,
                                    border: `1px solid transparent`,
                                    width: `240px`,
                                    height: `40px`,
                                    padding: `0 12px`,
                                    borderRadius: `3px`,
                                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                    fontSize: `14px`,
                                    outline: `none`,
                                    textOverflow: `ellipses`,
                                    position: "absolute",
                                    left: "50%",
                                    marginTop: "10px",
                                    marginLeft: "-120px"
                                  }}
                                />
                              </StandaloneSearchBox>
                              <Marker
                                position={form.geoLocation || geoLocation}
                                icon={courtMarker}
                                draggable={true}
                                onClick={(e) => {
                                  console.log('you are clicking me')
                                }}
                                onDragEnd={(e) => {
                                  console.log('hello world', e)
                                  console.log('hello world', e.latLng.lat(), e.latLng.lng())
                                }}
                              />
                            </GoogleMap>
                        </Grid>
                      )
                    } */}
                    <Grid item xs={12} md={12} sx={{
                        p: 1
                    }}>
                        <TCInput fullWidth={true} label={"Description"} type={'textarea'} rows={4} maxRows={6} onChange={(e)=> {
                          setCourt({description: e.currentTarget.value})
                        }} value={form.description}/>
                    </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6} lg={4}>
                <Grid container direction={"row"} sx={{
                    p: 2, pt: 0
                }}>
                    <Grid item sx={{
                        p: 1,
                        mt: 1
                    }}>
                        <TCTitleLabel>Status and Operational Hours</TCTitleLabel>
                        <OperationalHoursComponent setCourt={setCourt} currOpsHours={form.operationalHours} showSummary={form.showSummary} setShowSummary={setCourt}/>
                    </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <AddCourtComponent 
              setCourt={setCourt}
              formImages={form.layoutPhotos} 
              courtInfo={form.courts} 
              priceList={form.prices}/>
          </Box>
          <Grid sx={{p:2}} justifyContent={"flex-end"} textAlign={"right"}>
            <Button variant='contained' onClick={() => { saveForm() }}>Save</Button>
          </Grid>
        </Card>
      </Grid>
    </>
  )
}

export default AddCourts;
