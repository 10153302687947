import React, { useState, useEffect } from "react";

import { Box, Button, Card, Dialog, Grid, Typography } from '@mui/material';
import { currencyFormat } from 'helper/NumberFormat';
import { orderStatusOption, orderType, orderTypeOption } from 'constant/constant';

import moment from 'moment';
import { TCLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';

import TableComponent from "components/TopCourtComponents/Table/TableComponent";
import { getOrders } from "services/orderServices";
import { useNavigate } from "react-router";
import { orderStatusMap } from "helper/OrderStatus";
import DateRangeInput from "components/TopCourtComponents/Input/DateRangeFilter";
import { TCSelect } from "components/TopCourtComponents/Input/TopCourtInput";

const initFilter = {
    startCreatedDate: null,
    endCreatedDate: null,
    createdDateVariable: { label: "<>", value: "ib" },
    // bookingId: "",
    // user: null,
    // court: null,
    // orderStatus: null,
    // startNominal: null,
    // endNominal: null,
    type: null,
    status: null,
    // nominalVariable: { label: "<>", value: "ib" },
    // productName: null,
    page: 0
}

function PlayerBooking({
    playerId
}) {

    const navigate = useNavigate()
    const [ orderData, setOrderData ] = useState([])
    const [ totalData, setTotalData ] = useState([])
    const [ filter, setFilter ] = useState(initFilter)
    const [ loading, setLoading ] = useState(false)

    useEffect(() => {
        if (playerId) {
            loadOrder(playerId)
        }
    }, [playerId])

    const onChangeFilter = (e) => {
        setFilter(prev => ({
            ...prev,
            ...e
        }))
    }

    const onChangePage = (e) => {
        setFilter(prev => ({
            ...prev,
            ...e
        }))
    }

    useEffect(() => {
        if (playerId) {
            loadOrder(playerId)
        }
    }, [filter])

    const loadOrder = async (user) => {
        setLoading(true)
        let { data, error } = await getOrders({
            startDate: filter.startCreatedDate,
            endDate: filter.endCreatedDate,
            dateVariable: filter.createdDateVariable ? filter.createdDateVariable.value : '',
            type: filter.type ? filter.type.value : '',
            orderStatus: filter.status ? filter.status.value : '',
            player: user, 
            page: filter.page,
            limit: 20,
        })
        if (error) { alert(error) }
        if (data) { 
            setOrderData(data.orders)
            setTotalData(data.totalData)
        }
        setLoading(false)
    }

    const valueCustomator = (row) => {
        return ({
            createdDate: <div onClick={() => { window.location.href = `/orders/${row._id}` }}>{moment(row.createdDate).format("YYYY/MM/DD HH:mm:ss")}</div>,
            bookingId: <TCLabel>{<div onClick={()=>console.log('ini cek rows',row)}>{row.bookingId}</div>}</TCLabel>,
            user: <TCLabel>{row.user ? row.user.name : row.createdBy ? `${row.createdBy.name} STC` : ''}</TCLabel>,
            type: <TCLabel>{orderType(row.orderType)}</TCLabel>,
            total: <TCLabel>{currencyFormat(row.total)}</TCLabel>,
            action: <div>
                {
                row.lastStatus == "failed" ? "Payment Failed" 
                : orderStatusMap(row)
                }
            </div>
        })
    }

    const clearFilter = () => {
        setFilter(initFilter)
    }

    return (
        <Grid xs={12}>
            <Grid container xs={12} spacing={2}>
                <Grid item xs={12} md={3} sx={{ mb: 1 }}>
                    <DateRangeInput
                        label={'Booking Date'}
                        showLabel={true}
                        startDate={filter.startCreatedDate}
                        endDate={filter.endCreatedDate}
                        dateRangeType={filter.createdDateVariable}
                        onChangeSelect={(e) => onChangeFilter({ createdDateVariable: e })}
                        onChangeStartDate={(e) => onChangeFilter({ startCreatedDate: e })}
                        onChangeEndDate={(e) => onChangeFilter({ endCreatedDate: e })}
                    />
                </Grid>
                <Grid item xs={12} md={3} sx={{ mb: 1 }}>
                    <TCSelect label={'Order Type'} options={orderTypeOption} value={filter.type} onChange={(e) => {
                        onChangeFilter({ type: e })
                    }} />
                </Grid>
                <Grid item xs={12} md={3} sx={{ mb: 1 }}>
                    <TCSelect label={'Status'} options={orderStatusOption} value={filter.status} onChange={(e) => {
                        onChangeFilter({ status: e })
                    }} />
                </Grid>
                <Grid item xs={12} md={0.5} sx={{ mb: 1, textAlign: 'right' }} >
                    <Button sx={{ mt: 2, border: '0.5px solid #2196f3', borderRadius: 4 }}
                        onClick={clearFilter}
                    >Clear</Button>
                </Grid>
            </Grid>
            <TableComponent
                columns={[
                    { label: "Created Date" },
                    { label: "Booking ID" },
                    { label: "User" },
                    { label: "Type" },
                    { label: "Total" },
                    { label: "Action" },
                ]}
                rows={orderData}
                valueCustomator={valueCustomator}
                loading={loading}
                totalData={totalData}
                page={filter.page}
                pageChange={(e) => {
                    onChangePage({page: e})
                }}
            />
        </Grid>
    )
}

export default PlayerBooking