import React, { useState, useEffect } from "react"

// material-ui
import { Box, Button, Card, Dialog, Grid, Typography } from '@mui/material';

// project imports

import { TCLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';
import { currencyFormat } from 'helper/NumberFormat';
import TableComponent from 'components/TopCourtComponents/Table/TableComponent';
import { TypeSelectOption } from '../../players/playerFilter';
import { useDebounce } from 'use-debounce';
import { getPaymentSummary, getPayments } from 'services/paymentService';
import moment from 'moment';
import { orderType, periodOptions, listMonth, chartColor } from 'constant/constant';

import SaleReportFilter from "./SaleReportFilter"
import TCAccordion from "components/TopCourtComponents/TCAccordion";
import { getReportSales, getReportSalesSummary } from "services/reportServices";
import { TCSelect } from "components/TopCourtComponents/Input/TopCourtInput";
import ReportChart from "components/TopCourtComponents/ChartComponent";


const styles = {
    table: {
      fontSize: 12,
      border: '1px solid #c9c9c9',
    }
}

const initFilter = {
    load: true,
    period: periodOptions[0],
    startDate: moment().startOf('month').format('YYYY-MM-DDT17:00:00.000'),
    endDate: moment().endOf('month').set({ hour: 23, minute: 59, second: 59, millisecond: 59 }).toISOString(),
    rangeDateType: TypeSelectOption[0],
    orderType: {label: "All", value: "all"},
    orderStatus: null,
    addOnType: {label: "All", value: "all"},
    item: null,
    page: 0
}


function SaleReport ({

}) {

    const [filter, setFilter] = useState(initFilter)
    const [summary, setSummary] = useState(null)
    const [totalData, setTotalData] = useState(0)
    const [rows, setRows] = useState([])
    const [cData, setcData] = useState({
        labels: [],
        datasets: []
    });
    const [cOptions, setCOptions] = useState(false)

    useEffect(() => {
        if (filter.load) { 
            loadSummary()
            loadSalesReport()   
        }
    }, [filter])

    useEffect(() => {
        if (summary) { 
            setGraph(summary)
        }
    }, [summary])

    const onChange = (e) => {
        setFilter(prev => ({
            ...prev,
            ...e
        }))
    }

    const setGraph = (data) => {
        let label = []
        let datasets = []
        let datasetData = []
        let startDate = filter.startDate ? moment(filter.startDate).month() : 0;
        let endDate = filter.endDate ? moment(filter.endDate).month() : 11;
        if (data.totalSalesPerYear && data.totalSalesPerYear.length > 1) {
            let monthlyData = []
            let yearlyData = []
            data.totalSalesPerYear.sort((a, b) => { return a._id.year - b._id.year });
            for (let i = 0; i < data.totalSalesPerYear.length; i++) {
                // let year = jdDataDetail.data.totalSalesPerYear[i]
                // label.push(year._id);
                // data.push(year.total);
                if (monthlyData.length === 0) {
                    monthlyData.push(data.totalSalesPerYear[i])
                } else {
                if (data.totalSalesPerYear[i]._id.year === data.totalSalesPerYear[i - 1]._id.year) {
                    monthlyData.push(data.totalSalesPerYear[i])
                } else {
                    yearlyData.push(monthlyData)
                    monthlyData = []
                    monthlyData.push(data.totalSalesPerYear[i])
                }
                }
                if (i === ( data.totalSalesPerYear.length - 1 )) {
                    yearlyData.push(monthlyData)
                }
            }
            label = listMonth
            for (let i = 0; i < yearlyData.length; i ++) {
                let newData = yearlyData[i]
                newData.sort((a, b) => { return a._id.month - b._id.month });
                // for (let j = 0 ; j < newData.length; j++) {
                //   data.push(newData[j]);  
                // }
                datasetData = []
                for (let j = 0 ; j < newData.length; j++) {
                    if (j === 0 && newData[j]._id.month > 1) {
                        for (let k = 0 ; k < newData[j]._id.month - 1; k++) {
                            datasetData.push(0)
                        }
                    }
                    datasetData.push(newData[j].total);
                }
                datasets.push({
                    label: newData[0]._id.year,
                    data: datasetData,
                    ...chartColor,
                })
            }
        } else if (data.totalSalesPerMonth) {
            for (let i = startDate; i <= endDate; i++) {
                label.push(listMonth[i]);
                datasetData.push(data.totalSalesPerMonth[i + 1]);
            }
            datasets.push({
                label: '',
                data: datasetData,
                ...chartColor,
            })
        }

        console.log(datasets)

        setcData({
            labels: label,
            datasets: datasets
        });

        setCOptions({
            tooltips: {
                enabled: true,
                mode: 'single',
                callbacks: {
                  label: function (tooltipItems, data) {
                    let label = [`Total Sales: ${currencyFormat(tooltipItems.value)}`]
                    if (data.datasets) {
                      let tooltipsIndex = tooltipItems.datasetIndex;
                      label.push(`${data.datasets[tooltipsIndex].label}`)
                    }
                    return label;
                  }
                }
            }
        })

    }

    const loadSalesReport = async () => {
        try {
            let { data, error } = await getReportSales({
                ...filter,
                rangeDateType: filter.rangeDateType ? filter.rangeDateType.value : '',
                period: filter.period ? filter.period.value : '',
                orderType: filter.orderType ? (filter.orderType.value == 'all') ? '' : filter.orderType.value : '',
                orderStatus: filter.orderStatus ? filter.orderStatus.value : '',
                addOnType: filter.addOnType ? (filter.addOnType.value == 'all') ? '' : filter.addOnType.value : '',
                item: filter.item ? filter.item.map(value => {return value.value}) : '',
            })
            if (error) { throw error }
            let { order, totalData } = data
            setRows(order)
            setTotalData(totalData)
        } catch (error) {
            console.log(error)
        }
    }

    const loadSummary = async () => {
        try {
            let { data, error } = await getReportSalesSummary({
                ...filter,
                rangeDateType: filter.rangeDateType ? filter.rangeDateType.value : '',
                period: filter.period ? filter.period.value : '',
                orderType: filter.orderType ? (filter.orderType.value == 'all') ? '' : filter.orderType.value : '',
                orderStatus: filter.orderStatus ? filter.orderStatus.value : '',
                addOnType: filter.addOnType ? (filter.addOnType.value == 'all') ? '' : filter.addOnType.value : '',
                item: filter.item ? filter.item.map(value => {return value.value}) : '',
            })
            if (error) { throw error }
            console.log('summary', data)
            setSummary(data)
        } catch (error) {
            console.log(error)
        }
    }

    const clearFilter = () => {
        setFilter(initFilter)
    }

    const onChangePage = (e) => {
        setFilter(prev => ({
            ...prev,
            ...e
        }))
    }

    const valueCustomator = (row) => {
        return ({
            paymentDate: <TCLabel>{moment(row.createdDate).format("YYYY/MM/DD HH:mm:ss")}</TCLabel>,
            // referenceId: <TCLabel>{row.referenceId}</TCLabel>,
            orderId: <Grid><a href={`/orders/${row._id}`} style={{color: 'black'}}>{row.bookingId}</a></Grid>,
            user: <span className='span-link' onClick={() => { window.location.href = `/users/detail/${row.user._id}`}}>{row.user ? row.user.name : '-'}</span>,
            amount: <TCLabel>{`${currencyFormat(row.total)}`}</TCLabel>,
            type: <TCLabel>{orderType(row.orderType)}</TCLabel>,
        })
    }

    return(
        <>
            <SaleReportFilter 
                filter={filter}
                onChangeFilter={onChange}
                clearFilter={clearFilter}
            />
            <TCAccordion title={
                <Grid container xs={12}>
                    <Grid item xs={12} sm={12} lg={1}><TCLabel bold>Summary</TCLabel></Grid>
                    <Grid item xs={6} sm={6} lg={2}><TCLabel bold>Total: {summary ? currencyFormat(summary.totalOrder.total) : 0}</TCLabel></Grid>
                    <Grid item xs={6} sm={6} lg={2}><TCLabel bold>Total Booking: {summary ? summary.totalOrder.sales : 0}</TCLabel></Grid>
                </Grid>
            }>
                <Grid container xs={12}>
                    <Grid xs={12}>
                        <ReportChart cData={cData} cOptions={cOptions}>
                            <Grid xs={12} p={2} pt={1}>
                                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    <div>
                                        <table style={{
                                            ...styles.table,
                                            minWidth: 220
                                        }}>
                                            <tbody>
                                                <tr>
                                                    <td className="p-1">Total Valid Booking</td>
                                                    <td>:</td>
                                                    <td className="p-1 text-right"><b>{!summary ? '. . . .' : (summary.totalOrder.sales) || 0}</b></td>
                                                </tr>
                                                <tr>
                                                    <td className="p-1">Average Nominal</td>
                                                    <td>:</td>
                                                    <td className="p-1 text-right"><b>{!summary ? '. . . .' : currencyFormat(summary.totalOrder.total / summary.totalOrder.sales) || 0}</b></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="mr-3 mt-3" style={{
                                            ...styles.table,
                                            minWidth: 220
                                        }}>
                                            <tbody>
                                            <tr>
                                                <td className="p-1">Total User Purchase</td>
                                                <td>:</td>
                                                <td className="p-1 text-right"><b>{!summary ? '. . . .' : summary.validUser || 0}</b></td>
                                            </tr>
                                            <tr>
                                                <td className="p-1">Total First Purchase User</td>
                                                <td>:</td>
                                                <td className="p-1 text-right"><b>{!summary ? '. . . .' : summary.firstPurchaseUser || 0}</b></td>
                                            </tr>
                                            <tr>
                                                <td className="p-1">Total Repeat Purchase User</td>
                                                <td>:</td>
                                                <td className="p-1 text-right"><b>{!summary ? '. . . .' : summary.repeatPurchaseUser || 0}</b></td>
                                            </tr>
                                            <tr>
                                                <td className="p-1">Repeat Purchase Rate</td>
                                                <td>:</td>
                                                <td className="p-1 text-right"><b>{!summary ? '. . . .' : summary.repeatPurchaseRate || 0}%</b></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <table className="mt-3" style={{
                                            ...styles.table,
                                            minWidth: 220
                                        }}>
                                            <tbody>
                                            <tr>
                                                <td className="p-1">Total First-time Orders</td>
                                                <td>:</td>
                                                <td className="p-1 text-right"><b>{!summary ? '. . . .' : summary.firstTimeOrders || 0}</b></td>
                                            </tr>
                                            <tr>
                                                <td className="p-1">Total Second-time Orders</td>
                                                <td>:</td>
                                                <td className="p-1 text-right"><b>{!summary ? '. . . .' : summary.secondTimeOrders || 0}</b></td>
                                            </tr>
                                            <tr>
                                                <td className="p-1">Total {`≥`} Third-time Orders</td>
                                                <td>:</td>
                                                <td className="p-1 text-right"><b>{!summary ? '. . . .' : summary.thirdTimeorMoreOrders || 0}</b></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        (filter.item && filter.item.length > 0) ?
                                        <div>
                                            <table style={{
                                                ...styles.table,
                                                minWidth: 220
                                            }}>
                                                <tbody>
                                                {filter.item.map(v => {
                                                    return (
                                                        <tr>
                                                            <td className="p-1">Session {v.label}</td>
                                                            <td>:</td>
                                                            <td className="p-1 text-right"><b>{!summary ? '. . . .' : summary[v.label.toLowerCase()] || 0}</b></td>
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                        :
                                        <div>
                                            <table style={{
                                                ...styles.table,
                                                minWidth: 220
                                            }}>
                                                <tbody>
                                                <tr>
                                                    <td className="p-1">Session BB</td>
                                                    <td>:</td>
                                                    <td className="p-1 text-right"><b>{!summary ? '. . . .' : summary.ballBoy || 0}</b></td>
                                                </tr>
                                                <tr>
                                                    <td className="p-1">Session Coach</td>
                                                    <td>:</td>
                                                    <td className="p-1 text-right"><b>{!summary ? '. . . .' : summary.coach || 0}</b></td>
                                                </tr>
                                                <tr>
                                                    <td className="p-1">Session RP</td>
                                                    <td>:</td>
                                                    <td className="p-1 text-right"><b>{!summary ? '. . . .' : summary.rallyPartner || 0}</b></td>
                                                </tr>
                                                <tr>
                                                    <td className="p-1">Session Rent</td>
                                                    <td>:</td>
                                                    <td className="p-1 text-right"><b>{!summary ? '. . . .' : summary.rent || 0}</b></td>
                                                </tr>
                                                <tr>
                                                    <td className="p-1">Buy</td>
                                                    <td>:</td>
                                                    <td className="p-1 text-right"><b>{!summary ? '. . . .' : summary.buy || 0}</b></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </div>
                            </Grid>
                        </ReportChart>
                    </Grid>
                </Grid>
            </TCAccordion>
            <div style={{marginTop: 18}}/>
            <TableComponent
                columns={[
                    { label: filter.period == periodOptions[0] ? "Order Time" : filter.period == periodOptions[1] ? "Session Date" : filter.period == periodOptions[2] ? "Complete Date" : "" },
                    { label: "Booking Id" },
                    { label: "User" },
                    { label: "Amount" },
                    { label: "Type" },
                ]}
                totalData={totalData}
                rows={rows}
                valueCustomator={valueCustomator}
                page={filter.page}
                pageChange={(e) => {
                    onChangePage({page: e})
                }}
            />
        </>
    )
}

export default SaleReport