import React, { useState, useEffect } from "react";

import { Avatar, Card, CardContent, Dialog, Grid, Icon, Tab, Tabs, Tooltip, Drawer, Button } from "@mui/material";
import { getPlayerDetail } from "services/playerServices";
import { getParamsFromURL } from "helper/Params";
import { TCLabel, TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { TabContext } from "@mui/lab";
import PlayerBooking from "./playerComponent/booking";
import PlayerInfo from "./playerComponent/info";
import PlayerSession from "./playerComponent/session";
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput";
import PlayerOTP from "./playerComponent/otp";

import EventAvailable from "@mui/icons-material/EventAvailable";
// import CalendarComponent from "components/CalendarComponent";
import { IconClock } from "@tabler/icons";
import EditHistory from "./playerComponent/editHistory";

function PlayerDetail ({

}) {

    const [ data, setData ] = useState({
        _id: null,
        profilePic: null,
        name: "",
        status: null
    })
    const [reload, setReload] = useState(false)
    const [ tabs, setTabs ] = useState(0)
    const [ openCalendar, setOpenCalendar ] = useState(false)
    const [ openDrawer, setOpenDrawer ] = useState(false)

    // load player info
    useEffect(() => {
        let { isEdit, params } = getParamsFromURL(window);
        if (params) { loadPlayerInfo(params) }
    }, [])

    useEffect(() => {
        console.log(data)
    }, [data])

    useEffect(() => {
        let { params } = getParamsFromURL(window);
        if(reload===true){
            loadPlayerInfo(params)
            setReload(false)
        }
    }, [reload])

    const loadPlayerInfo = async (id) => {
        let { data, error } = await getPlayerDetail(id)
        if (data) { setData(data) }
        if (error) { alert(error.message) }
    }

    const setUserStatus = (data) => {
        return data.superuser ? 'Superuser' : data.coachStatus ? 'Coach' : data.sessions > 0 ? 'Subscriber' : 'Regular' 
    }

    return (
        <Grid container xs={12} md={12}>
            <Grid item xs={12} md={6} style={{paddingRight: 2, paddingTop: 4}}>
                <Card style={{width: '100%'}}>
                    <CardContent>
                        <Grid container xs={12} md={12}>
                            <Grid item  style={{padding: 5}}>
                                <Avatar
                                    src={data.profilePic}
                                    // sx={{
                                    //     ...theme.typography.mediumAvatar,
                                    //     margin: '8px 0 8px 8px !important',
                                    //     cursor: 'pointer'
                                    // }}
                                    // ref={anchorRef}
                                    // aria-controls={open ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    color="inherit"
                                />
                            </Grid>
                            <Grid item xs style={{ paddingLeft: 10 }}>
                                <TCTitleLabel>{data.name}</TCTitleLabel>
                                <TCLabel>{setUserStatus(data)}</TCLabel>
                            </Grid>
                            {/* <Grid item xs style={{textAlign: 'right'}}>
                                <Tooltip title={"Player schedule"}>
                                    <EventAvailable style={{cursor: 'pointer',color: '#0198E1'}}
                                        onClick={() => {
                                            setOpenCalendar(true)
                                            setOpenDrawer(false)
                                        }}
                                    />
                                </Tooltip>
                            </Grid> */}
                            {/* Pindah ke header */}
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={4}>
                                    <TCInput label={"Total Session"} disabled={true} value={data.sessions || 0}/>
                                </Grid>
                                <Grid item container xs={8} spacing={2}>
                                    <Grid item xs={4}>
                                        <TCInput label={"All Court"} disabled={true} value={data.activeBenefits ? data.activeBenefits.sessions.all_court : 0}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TCInput label={"Outdoor"} disabled={true} value={data.activeBenefits ? data.activeBenefits.sessions.outdoor : 0}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TCInput label={"Hitting"} disabled={true} value={data.activeBenefits ? data.activeBenefits.sessions.hitting_room : 0}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Pindah ke header */}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6} style={{paddingLeft: 2, paddingTop: 4}}>
                <Card style={{width: '100%', height: '100%'}}>
                    <CardContent>
                        <TCTitleLabel>Player Stats</TCTitleLabel>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={12} style={{paddingTop: 4}}>
                <Card style={{width: '100%', height: '100%'}}>
                    <Tabs
                        // orientation="vertical"
                        orientation="horizontal"
                        variant="scrollable"
                        value={tabs}
                        onChange={(e, newValue) => {
                            setTabs(newValue)
                        }}
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                    >
                        {/* check here */}
                        <Tab label="Details"/>
                        <Tab label="Sessions"/>
                        <Tab label="Order"/>
                        <Tab label="Match"/>
                        <Tab label="Class"/>
                        <Tab label="Academy"/>
                        <Tab label="OTP"/>
                    </Tabs>
                    {
                        tabs == 0 &&
                        <TabContext index={0}>
                            <CardContent>
                                <PlayerInfo playerId={data._id} reload={setReload}/>
                            </CardContent>
                        </TabContext>
                    }
                    {
                        tabs == 1 &&
                        <TabContext index={1}>
                            <CardContent>
                                <PlayerSession playerId={data._id}/>
                            </CardContent>
                        </TabContext>
                    }
                    {
                        tabs == 2 &&
                        <TabContext index={2}>
                            <CardContent>
                                <PlayerBooking playerId={data._id}/>
                            </CardContent>
                        </TabContext>
                    }
                    {
                        tabs == 3 &&
                        <TabContext value={tabs} index={3}>
                            <CardContent>
                                <TCTitleLabel>Player Matches</TCTitleLabel>
                            </CardContent>
                        </TabContext>
                    }
                    {
                        tabs == 6 &&
                        <TabContext value={tabs} index={3}>
                            <CardContent>
                                <PlayerOTP playerId={data._id} manualOTP={data.manualOTP}/>
                            </CardContent>
                        </TabContext>
                    }
                </Card>
            </Grid>
            {/* <Dialog open={openCalendar} onClose={() => {
                setOpenCalendar(false)
            }}>
                <CalendarComponent />
            </Dialog> */}
            <Drawer anchor="right" open={openDrawer} onClose={() => {
                setOpenDrawer(false)
            }}>
                <EditHistory userId={data._id} setClose={() => { setOpenDrawer(false) }}/>
            </Drawer>
            {
                (data && data._id) &&
                <Button
                    style={{
                        position: 'fixed',
                        right: window.innerWidth < 500 ? 0 : -30,
                        top: (window.innerWidth < 500) ? 60 : '',
                        bottom: (window.innerWidth < 500) ? '' : '55%',
                        maxWidth: (window.innerWidth < 500) ? '' : 250,
                        minWidth: (window.innerWidth < 500) ? 20 : '',
                        transform: (window.innerWidth < 500) ? '' : 'rotate(-90deg)',
                        color: 'white',
                        backgroundColor: '#0198E1',
                        zIndex: 300,
                    }}
                    onClick={() => {
                        setOpenDrawer(true)
                    }}
                >
                    {window.innerWidth < 500 ? <IconClock/> : "Edit History"}
                </Button>
            }
        </Grid>
    )
}

export default PlayerDetail