import React from "react";

import { Box, Button, Card, Dialog, Grid, Typography } from '@mui/material';
import { TCInput, TCSelect } from "components/TopCourtComponents/Input/TopCourtInput";
import { getPlayers } from "services/playerServices";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { availableOption, discountType, orderStatusOption, orderTypeOption } from "constant/constant";
import NumberRangeInput from "components/TopCourtComponents/Input/NominalRangeFilter";
import DateRangeInput from "components/TopCourtComponents/Input/DateRangeFilter";

export default function CouponFilter({
    filter,
    onChangeFilter,
    clearFilter
}) {
    return (
        <Card sx={{ mb: 1 }}
            className="custom-card-style">
            <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} md={2} sx={{ mb: 1 }}>
                        <DateRangeInput 
                            disabledRange={true}
                            showLabel={true}
                            label={'Created Date'}
                            placeholderStart={"Created Date"}
                            startDate={filter.createdDate}
                            // endDate={filter.expiryDateEnd}
                            // dateRangeType={filter.expiryDateType}
                            // onChangeSelect={(e)=>onChangeFilter({ expiryDateType: e })}
                            onChangeStartDate={(e)=>onChangeFilter({ createdDate: e })}
                        />
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ mb: 1 }}>
                        <TCInput placeHolder="Name" onChange={(e) => onChangeFilter({ name: e.currentTarget.value })} value={filter.name} label={"Name"} />
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ mb: 1 }}>
                        <TCInput placeHolder="Promo Code" onChange={(e) => onChangeFilter({ codePromo: e.currentTarget.value.toUpperCase() })} value={filter.codePromo} label={"Promo Code"} />
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ mb: 1 }}>
                        <TCSelect label={'Discount Type'} options={discountType} isClearable={true} value={filter.discountType} onChange={(e) => {
                            onChangeFilter({ discountType: e })
                        }} />
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ mb: 1 }}>
                        <TCSelect label={'Status'}options={availableOption} isClearable={true} value={filter.status} onChange={(e) => {
                            onChangeFilter({ status: e })
                        }} />
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ mb: 1 }}>
                        <DateRangeInput 
                            disabledRange={true}
                            showLabel={true}
                            label={'Expiry Date'}
                            placeholderStart={"Expired Date"}
                            startDate={filter.expiredDate}
                            // endDate={filter.expiryDateEnd}
                            // dateRangeType={filter.expiryDateType}
                            // onChangeSelect={(e)=>onChangeFilter({ expiryDateType: e })}
                            onChangeStartDate={(e)=>onChangeFilter({ expiredDate: e })}
                        />
                    </Grid>
                    <Grid item xs={12} md={10} sx={{ mb: 1, textAlign:'right' }} >
                    <Button sx={{mt: 2, border: '0.5px solid #2196f3', borderRadius: 4}}
                            onClick={clearFilter}
                        >Clear</Button>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    )
}
