import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";
import { FormatServerDate, client, errorValidation } from "./service.js";

export async function getPlayers({
    name='',
    phoneNumber='',
    gender='',
    birthYearType,
    birthYearStart,
    birthYearEnd,
    registerDateType,
    registerDateStart,
    registerDateEnd,
    userStatus,
    subscribeStatus,
    academyStatus,
    classType,
    classStart,
    classEnd,
    matchType,
    matchStart,
    matchEnd,
    sessionType,
    sessionStart,
    sessionEnd,
    isActive
}) {
    try {
        let players = await axios.Get({
            url: `${adminUrl}/players`,
            params:{
                name: name || '',
                phoneNumber: phoneNumber || '',
                gender: gender || '',
                birthYearType: birthYearType || '',
                birthYearStart: birthYearStart || '',
                birthYearEnd: birthYearEnd || '',
                registerDateType: registerDateType || '',
                registerDateStart: registerDateStart || '',
                registerDateEnd: registerDateEnd || '',
                userStatus: userStatus || '',
                subscribeStatus: subscribeStatus || '',
                academyStatus: academyStatus || '',
                classType: classType || '',
                classStart: classStart || '',
                classEnd: classEnd || '',
                matchType: matchType || '',
                matchStart: matchStart || '',
                matchEnd: matchEnd || '',
                sessionType: sessionType || '',
                sessionStart: sessionStart || '',
                sessionEnd: sessionEnd || '',
                isActive: (isActive == true || isActive == false) ? isActive : ''
            }
        });

        if (players.statusCode !== 200) {
            return { error: players.message };
        }

        return { data: players.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function getPlayersv2({
    name='',
    phoneNumber='',
    gender='',
    birthYearType,
    birthYearStart,
    birthYearEnd,
    registerDateType,
    registerDateStart,
    registerDateEnd,
    userStatus,
    subscribeStatus,
    academyStatus,
    classType,
    classStart,
    classEnd,
    matchType,
    matchStart,
    matchEnd,
    sessionType,
    sessionStart,
    sessionEnd,
    isActive,
    page
}) {
    try {
        let players = await axios.Get({
            url: `${adminUrl}/players-v2`,
            params:{
                name: name || '',
                phoneNumber: phoneNumber || '',
                gender: gender || '',
                birthYearType: birthYearType || '',
                birthYearStart: birthYearStart || '',
                birthYearEnd: birthYearEnd || '',
                registerDateType: registerDateType || '',
                registerDateStart: FormatServerDate(registerDateStart) || '',
                registerDateEnd: registerDateEnd || '',
                userStatus: userStatus || '',
                subscribeStatus: subscribeStatus || '',
                academyStatus: academyStatus || '',
                classType: classType || '',
                classStart: classStart || '',
                classEnd: classEnd || '',
                matchType: matchType || '',
                matchStart: matchStart || '',
                matchEnd: matchEnd || '',
                sessionType: sessionType || '',
                sessionStart: sessionStart || '',
                sessionEnd: sessionEnd || '',
                isActive: (isActive == true || isActive == false) ? isActive : '',
                page: page || 0
            }
        });

        if (players.statusCode !== 200) {
            return { error: players.message };
        }

        return { data: players.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function loadPlayerList({
    query = ""
}) {
    try {
        let players = await axios.Get({
            url: `${adminUrl}/players-select`,
            params:{
                query: query || ""
            }
        });

        if (players.statusCode !== 200) {
            return { error: players.message };
        }

        return { data: players.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function getPlayerDetail(id) {
    try {
        let players = await axios.Get({
            url: `${adminUrl}/player/${id}`,
        });

        if (players.statusCode !== 200) {
            return { error: players.message };
        }

        return { data: players.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function updatePlayer(body) {
    try {
        let response = await client.post(`${adminUrl}/player-update`, {
            ...body
        });

        if (response.data.statusCode !== 200) {
            throw response.data
        }

        return { data: response.data };
    } catch (e) {
        return errorValidation(e)
    }
}

export async function getPlayerSession(id) {
    try {
        let sessions = await axios.Get({
            url: `${adminUrl}/session-balance?user=${id}`,
        });

        if (sessions.statusCode !== 200) {
            return { error: sessions.message };
        }

        return { data: sessions.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function getManualOTP(_id) {
    try {
        let response = await axios.Get({
            url: `${adminUrl}/get-otp?user=${_id}`,
        });

        if (response.statusCode !== 200) {
            return { error: response.message };
        }

        return { data: response.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function updateManualOTP(body) {
    try {
        let response = await client.post(`${adminUrl}/player/update-otp`, {
            ...body
        });
        if (response.data.statusCode !== 200) {
            throw response.data
        }
        return { data: response.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function getPlayerProfileHistory ({
    userId
}) {
    try {
        let response = await client.get(`${adminUrl}/players/profile-history?userId=${userId}`);
        if (response.data.statusCode !== 200) {
            throw response.data
        }
        return { data: response.data };
    } catch (e) {
        return { error: e.response.data };
    }
}