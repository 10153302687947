import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";
// import { Delete, Get, Patch, Post, Put } from "./RestClient.js";

export async function getPackages({
    name, sessionType, sessionStart, sessionEnd, priceType, priceStart, priceEnd
}) {
    try {
        let packages = await axios.Get({
            url: `${adminUrl}/packages`,
            params: {
                name: name || '',
                sessionType: sessionType || '',
                sessionStart: sessionStart || '',
                sessionEnd: sessionEnd || '',
                priceType: priceType || '',
                priceStart: priceStart || '',
                priceEnd: priceEnd || '',
            }
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function createPackage(body) {
    try {
        let packages = await axios.Post({
            url: `${adminUrl}/create-package`,
            data: body
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function loadPackage(id) {
    try {
        let packages = await axios.Get({
            url: `${adminUrl}/package/${id}`,
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function updatePackage(body) {
    try {
        let packages = await axios.Post({
            url: `${adminUrl}/update-package`,
            data: body
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data }
    }
}