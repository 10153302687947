import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import { reactLocalStorage } from 'reactjs-localstorage';
import { Navigate } from 'react-router-dom';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const isLoggedIn = reactLocalStorage.get('user_token');

const AuthenticationRoutes = {
  path: '/',
  element: !isLoggedIn ? <MinimalLayout /> : <Navigate to="/dashboard" />,
  children: [
    {
      path: '/login',
      element: <AuthLogin3 />
    },
    {
      path: '/pages/register/register3',
      element: <AuthRegister3 />
    }
  ]
};

export default AuthenticationRoutes;
