import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { Box, Card, Grid, Typography, Avatar, Button, Switch } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getCounters } from 'services/counterServices';
import { TCLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';
import TableComponent from 'components/TopCourtComponents/Table/TableComponent';
import CounterFilter from './counterFilter';
// project imports

const initFilter = {
    name: '',
    counterId: '',
    isActive: null
}

export default function Counters({ }) {
    const [rows, setRows] = useState([
        {
            _id: 'b198239g19bo12730909',
            counterId: 'testing',
            name: 'testing counter',
            address: 'jl. sutomo nomor 69 medan timur, kota medan',
            isActive: true
        },
    ])
    const [filter, setFilter] = useState(initFilter)

    useEffect(() => {
        loadData()
    }, [filter])

    const loadData = async () => {
        try {
            let { data, error } = await getCounters({
                name: filter.name ? filter.name : '',
                counterId: filter.counterId ? filter.counterId : '',
                isActive: filter.isActive ? filter.isActive.value : ''
            });
            if (error) throw error
            if (data) {
                setRows(data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const valueCustomator = (row) => {
        return ({
            name: <span className='span-link' onClick={() => { window.location.href = `/counter/edit/${row._id}` }}>{row.name}</span>,
            image: <TCLabel>{row.address}</TCLabel>,
            type: <Switch checked={row.isActive} />
        })
    }

    const onChangeFilter = (e) => {
        setFilter(prev => ({
            ...prev,
            ...e
        }))
    }

    const clearFilter = () => {
        setFilter(initFilter)
    }

    return (
        <>
            <Button sx={{ mb: 2 }} variant="contained" disableElevation onClick={() => {
                window.location.href = "/counter/add"
            }}>Add Counter</Button>
            <CounterFilter filter={filter} onChangeFilter={onChangeFilter} clearFilter={clearFilter} />
            <TableComponent
                columns={[
                    { label: "Name" },
                    { label: "Address" },
                    { label: "Status" },
                ]}
                rows={rows}
                valueCustomator={valueCustomator}
            />
        </>
    )
}