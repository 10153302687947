import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";
// import { Delete, Get, Patch, Post, Put } from "./RestClient.js";

import { FormatServerDate, client, errorValidation } from "./service.js";

export async function getOrders(query = {
    page: 0,
    limit: 20,
    player: '',
    orderStatus: '',
    bookingId: '',
    type: '',
    startDate: '',
    endDate: '',
    dateVariable: ''
}) {
    try {
        let {
            startDate, endDate, dateVariable, page, limit, player, orderStatus, bookingId, type
        } = query
        let orders = await axios.Get({
            url: `${adminUrl}/orders-v2?page=${page}&limit=${limit}&startDate=${FormatServerDate(startDate) || ""}&endDate=${endDate || ""}&dateVariable=${dateVariable || ""}&player=${player || ""}&orderStatus=${orderStatus || ""}&bookingId=${bookingId || ""}&type=${type || ""}`,
        });

        if (orders.statusCode !== 200) {
            return { error: orders.message };
        }

        return { data: orders.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function getOrderDetail(_id) {
    try {
        let orders = await axios.Get({
            url: `${adminUrl}/order/${_id}`,
        });

        if (orders.statusCode !== 200) {
            return { error: orders.message };
        }

        return { data: orders.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function updateOrder(body) {
    try {
        let response = await client.post(`/order-update`, {
            ...body
        });
        if (response.data.statusCode !== 200) { throw response.data.data; }
        let data = response.data.data;
        return { data };
    } catch (e) {
        // return { error: e.response.data.errorCode };
        return errorValidation(e)
    }
}

export async function confirmValuePackPayment(_id, adminDiscount) {
    try {
        let orders = await axios.Post({
            url: `${adminUrl}/orders-payment/confirm-valuepack`,
            data: {
                _id: _id,
                adminDiscount
            }
        });

        if (orders.statusCode !== 200) {
            return { error: orders.message };
        }

        return { data: orders.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function confirmBookingPayment(_id, adminDiscount) {
    try {
        let orders = await axios.Post({
            url: `${adminUrl}/orders-payment/confirm-booking`,
            data: {
                _id: _id,
                adminDiscount
            }
        });

        if (orders.statusCode !== 200) {
            return { error: orders.message };
        }

        return { data: orders.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function confirmItemOrderPayment(_id, adminDiscount) {
    try {
        let orders = await axios.Post({
            url: `${adminUrl}/orders-payment/confirm-order`,
            data: {
                _id: _id,
                adminDiscount
            }
        });

        if (orders.statusCode !== 200) {
            return { error: orders.message };
        }

        return { data: orders.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function rejectPayment(_id) {
    try {
        let orders = await axios.Post({
            url: `${adminUrl}/orders-payment/reject-payment`,
            data: {
                _id: _id
            }
        });

        if (orders.statusCode !== 200) {
            return { error: orders.message };
        }

        return { data: orders.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function createOrder (body) {
    try {
        let response = await client.post(`/order-create`, {
            ...body
        });
        if (response.data.statusCode !== 200) { throw response.data.data; }
        let data = response.data.data;
        return { data };
    } catch (e) {
        // return { error: e.response.data.errorCode };
        return errorValidation(e)
    }
}