import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { useNavigate } from 'react-router';

function QRReader ({

}) {
    const [data, setData] = useState('No result');
    const [ openQR, setOpenQR ] = useState(false)

    const navigate = useNavigate()

    return (
        <>
            <Grid xs={6}>
                <Grid>
                    <Button  
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            setOpenQR(!openQR)
                        }}
                        size="large"
                    >{!openQR ? "Open" : "Close"}</Button>
                </Grid>
                {
                    openQR &&
                    <QrReader
                        onResult={(result, error) => {
                            if (!!result) {
                                setData(result?.text);
                                let data = result?.text.split('-')
                                // navigate('/')
                            }
                    
                            if (!!error) {
                                console.info(error);
                            }
                        }}
                        containerStyle={{ width: 200, height: 200 }}
                    />
                }
            </Grid>
        </>
    );
}

export default QRReader