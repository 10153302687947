import React from "react";

import { Box, Button, Card, Dialog, Grid, Typography } from '@mui/material';
import { TCInput, TCSelect } from "components/TopCourtComponents/Input/TopCourtInput";
import { getPlayers } from "services/playerServices";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { activeOption, orderStatusOption, orderTypeOption } from "constant/constant";

export default function CounterFilter({
    filter,
    onChangeFilter,
    clearFilter
}) {
    const loadUserOption = async (input) => {
        if (input.length < 3) {
            return
        }
        try {
            let { data, error } = await getPlayers({
                name: input
            });
            if (error) throw error
            if (data) {
                if (data.length <= 0) return
                return data.map(value => {
                    return {
                        label: value.name,
                        value: value._id
                    }
                })
            }
        } catch (error) {
            console.log(error.message || error)
            return ([])
        }
    }

    return (
        <Card sx={{ mb: 1 }}
            className="custom-card-style">
            <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                {/* filter date, booking id, user, type, status */}
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} md={3} sx={{ mb: 1 }}>
                        <TCInput onChange={(e) => onChangeFilter({ name: e.currentTarget.value })} value={filter.name} label={"Name"} />
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ mb: 1 }}>
                        <TCInput onChange={(e) => onChangeFilter({ counterId: e.currentTarget.value })} value={filter.counterId} label={"Counter ID"} />
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ mb: 1 }}>
                        <TCLabel>Status</TCLabel>
                        <TCSelect options={activeOption} value={filter.isActive} onChange={(e) => {
                            onChangeFilter({ isActive: e })
                        }} />
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ mb: 1, textAlign:'right' }} >
                        <Button sx={{mt: 2, border: '0.5px solid #2196f3', borderRadius: 4}}
                            onClick={clearFilter}
                        >Clear</Button>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    )
}
