import React, { useEffect, useState } from "react"
import { Box, Button, Card, Grid } from "@mui/material"
import { TCLabel, TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import TCCalendar from "components/TopCourtComponents/TCCalendar"
import { TCInput, TCSelect } from "components/TopCourtComponents/Input/TopCourtInput"

import DeleteIcon from '@mui/icons-material/Delete';
import { createOrder } from "services/orderServices"
import { Confirm } from "helper/Alert"
import { courtTypeOption } from "constant/constant"

const options = [
    { value: "tournament" , label: "Others"},
    { value: "class" , label: "Booking"}
]

function CreateOrder ({

}) {

    const [ form, setForm ] = useState({
        type: null,
        eventName: "",
        customPrice: null,
        additionalFee: null,
        adminDiscount: null
    })
    const [ listForms, setListForms ] = useState([])
    const [ forms, setForms ] = useState(null)
    const [ joiForm, setJoiForm ] = useState({
        type: null
    })

    useEffect(() => {
        setJoiForm({
            type: !form.type ? "*" : null,
            eventName: (form.type && form.type.value == "tournament") && !form.eventName ? "*" : null
        })
    }, [form])

    // useEffect(() => {
    // }, [forms])

    const onChange = (e) => {
        setForm(prev => ({
            ...prev,
            ...e
        }))
    }

    const addBookings = () => {
        let formList = listForms
        // check if exist
        let exist = false
        for (let i = 0; i < formList.length; i++) {
            // bubbleSort
            if ((formList[i].date == forms.date)
                && (formList[i].court == forms.court)
            ) {
                formList[i].hours = forms.hours
                exist = true
            }
            formList[i].hours = bubbleSort(formList[i].hours)
        }
        if (!exist) {
            console.log('ini cek form',forms)
            formList.push(forms)
        }
        formList = JSON.parse(JSON.stringify(formList))
        setListForms(prev => (
            [...formList]
        ))
    }

    const deleteBookings = (e) => {
        listForms.splice(e, 1)
        setListForms(prev => (
            [...listForms]
        ))
    }

    const createOrders = async () => {
        try {
            let body = { 
                type: form.type ? form.type.value : null,
                placeId: "653f5d9006520fccd4ab4604",
                eventName: form.eventName,
                customPrice: form.customPrice || calculatePrice(),
                additionalFee: form.additionalFee,
                adminDiscount: form.adminDiscount
            }

            let { data, error } = await createOrder({
                ...body,
                schedule: listForms
            })

            if (data) {
                console.log('ini cek data',data)
                Confirm("", "Order created");
                window.location.reload()
            }

            console.log(data)
            
        } catch (e) {
            console.log('ini cek error',e)
        }
    }

    useEffect(() => {
        calculatePrice()
    }, [listForms])

    const calculatePrice = () => {
        let value = 0
        for (let i = 0; i < listForms.length; i ++) {
            value += listForms[i].totalPrice
        }
        setForm(prev => ({
            ...prev,
            customPrice: value
        }))
        return value
    }

    const convertCourtType = (value) =>{
        let courtType = courtTypeOption.find(option=>option.value===value)
        if(courtType){
            return courtType.label
        } else{
            return null
        }
    }

    return (
        <Grid container xs={12} md={8} lg={6}>
            <Card sx={{
                padding: 2
            }}>
                <Box sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Grid pb={2}>
                        <TCSelect label={"Type"} showAlert={joiForm.type} options={options}
                            onChange={(e) => {
                                onChange({type: e})
                            }}
                        />
                    </Grid>
                    {
                        (form.type && form.type.value == "tournament") &&
                        <Grid pb={2}>
                            <TCInput
                                label={"Event Name"}
                                showAlert={joiForm.eventName}
                                value={form.eventName}
                                onChange={(e) => {
                                    onChange({eventName: e.currentTarget.value})
                                }}
                            />
                        </Grid>
                    }
                    <Grid pb={2}>
                        <TCCalendar onChangeForm={(e) => {
                            setForms(e)
                        }} />
                    </Grid>
                    <Grid pl={2} pr={2} pb={2} textAlign={"right"}>
                        {forms &&
                            <Button 
                            variant="contained" 
                            disabled={!forms.selectedDate || forms.hours.length<1 || !forms.courtData.name}
                            onClick={() => {
                                addBookings()

                            }}>Add Time</Button>
                        }
                    </Grid>
                    <Grid pb={2} pl={2} pr={2}>
                        <Grid container>
                            <Grid item pb={1}><TCLabel>Schedule List</TCLabel></Grid>
                            {
                                listForms.length == 0 &&
                                <Grid item><TCLabel style={{ 
                                    color: 'red'
                                }}>*</TCLabel></Grid>
                            }
                        </Grid>
                        { 
                            listForms.map((value, index) => {
                                return (
                                    <Card elevation={1} sx={{p: 2, mb: 1}}>
                                        <Grid container>
                                            <Grid item xs={10} container>
                                                <Grid item>
                                                    <TCLabel bold>{value.date}</TCLabel>
                                                </Grid>
                                                <Grid item pl={1}>
                                                    <TCLabel bold>{value.courtData ? `Court ${convertCourtType(value.courtData.courtType)} ${value.courtData.name}` : ""}</TCLabel>
                                                </Grid>
                                                <Grid item container>
                                                    {
                                                        value.hours.map((h, index) => {
                                                            return <TCLabel style={{marginRight: 3, fontSize: 12}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)} 
                                                                ${index < (value.hours.length - 1) ? " | " : ""}`}</TCLabel>
                                                        })
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={2} textAlign={"right"} sx={{cursor:'pointer'}}
                                                onClick={() => {
                                                    deleteBookings(index)
                                                }}
                                            >
                                                <DeleteIcon sx={{fontSize: 12, color: 'red'}}/>
                                            </Grid>
                                        </Grid>
                                    </Card>)
                            })
                        }
                    </Grid>
                    <Grid pb={2} pl={2} pr={2}>
                        <TCInput 
                            label={"Total"}
                            value={form.customPrice}
                            disabled={true}
                            onChange={(e) => {
                                let value = e.currentTarget.value
                                if (!value) {
                                    onChange({customPrice: value})
                                    return
                                }
                                if (isNaN(value * 1)) {
                                    value = form.customPrice
                                } else {
                                    value = (value *1)
                                    if (value <= 0) {
                                        value = "0"
                                    }
                                }
                                onChange({customPrice: value})
                            }}
                        />
                    </Grid>
                    <Grid pb={2} pl={2} pr={2}>
                        <TCInput 
                            label={"Additional Fee"}
                            value={form.additionalFee}
                            disabled={false}
                            onChange={(e) => {
                                let value = e.currentTarget.value
                                if (!value) {
                                    onChange({additionalFee: value})
                                    return
                                }
                                if (isNaN(value * 1)) {
                                    value = form.additionalFee
                                } else {
                                    value = (value *1)
                                    if (value <= 0) {
                                        value = "0"
                                    }
                                }
                                onChange({additionalFee: value})
                            }}
                        />
                    </Grid>
                    <Grid pb={2} pl={2} pr={2}>
                        <TCInput 
                            label={"Discount"}
                            value={form.adminDiscount}
                            onChange={(e) => {
                                let value = e.currentTarget.value
                                if (!value) {
                                    onChange({adminDiscount: value})
                                    return
                                }
                                if (isNaN(value * 1)) {
                                    value = form.adminDiscount
                                } else {
                                    value = (value *1)
                                    if (value > form.customPrice) {
                                        value = form.customPrice
                                    }
                                    if (value <= 0) {
                                        value = "0"
                                    }
                                }
                                onChange({adminDiscount: value})
                            }}
                        />
                    </Grid>
                    <Grid p={2}>
                        <Button disabled={
                            joiForm.type || joiForm.eventName || listForms.length == 0
                        } variant="contained"
                        onClick={() => {
                            createOrders()
                        }}
                        >Create</Button>
                    </Grid>
                </Box>
            </Card>
        </Grid>
    )
}

function bubbleSort(arr) {
    var i, j;
    var len = arr.length;
    var isSwapped = false;
    for (i = 0; i < len; i++) {
        isSwapped = false;
        for (j = 0; j < (len - i - 1); j++) {
            if (arr[j] > arr[j + 1]) {
                var temp = arr[j]
                arr[j] = arr[j + 1];
                arr[j + 1] = temp;
                isSwapped = true;
            }
        }
        if (!isSwapped) {
            break;
        }
    }
    return arr
}

export const timeStringFormat = (e) => {
    let value = e
    return `${value < 10 ? `0${value}` : value}:00`
}

export default CreateOrder
