import React, { useState, useEffect } from "react";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function SlideAlertAction ({
    title,
    subTitle,
    cancelAction = () => {},
    okAction = () => {},
    cancelActionTitle,
    okActionTitle,
    isOpen,
    setIsOpen,
    type
}) {

    const handleClose = () => {
        setIsOpen(false)
    }

    return (
        <Dialog
            fullWidth={false}
            maxWidth={'sm'}
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                style: {backgroundColor: '#FFFFFFF2', minWidth: '50%', textAlign: 'center'}
            }}
          >
            <div style={{paddingTop: 12, marginLeft: 2, marginRight: 2}}>
                <TCTitleLabel>{title}</TCTitleLabel>
            </div>
            <DialogContent style={{ paddingTop: 10}}>
              <DialogContentText id="alert-dialog-slide-description" style={{ marginLeft: 2, marginRight: 2 }}>
                <TCLabel>{subTitle}</TCLabel>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{padding: 0}}>
                <div style={{display: 'flex', width: '100%'}}>
                    {
                        type !== "confirm" &&
                        <div 
                            onClick={() => cancelAction()} 
                            style={{fontSize: 10, width: '50%', textAlign: 'center', color: 'red', borderRight: '0.01em solid #344767', borderTop: '0.01em solid #344767'}}>
                            <div style={{padding: 6}}><TCLabel>{cancelActionTitle ? cancelActionTitle : "Cancel"}</TCLabel></div>
                        </div>
                    }
                    <div
                        onClick={() => okAction()} 
                        style={{width: type !== "confirm" ? '50%' : '100%', textAlign: 'center', color: '#7dcfb6', borderTop: '0.01em solid #344767'}}>
                        <div style={{padding: 6}}><TCLabel style={{fontWeight: 'bold'}}>{okActionTitle ? okActionTitle : "OK"}</TCLabel></div>
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default SlideAlertAction;