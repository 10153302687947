import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";
// import { Delete, Get, Patch, Post, Put } from "./RestClient.js";

import { FormatServerDate, client, errorValidation } from "./service.js";

export async function getPayments(query = {
    page: 0,
    limit: 0,
    paymentDateStart: '',
    paymentDateEnd: '',
    paymentDateType: '',
    provider: '',
    type: '',
    amountMin: '',
    amountMax: '',
    amountType: '',
    imeiRepeated: '',
    referenceId: '',
}) {
    try {
        let {
            page, limit, paymentDateStart, paymentDateEnd, paymentDateType, provider, type, amountMin, amountMax, amountType,imeiRepeated, referenceId
        } = query
        let payments = await axios.Get({
            url: `${adminUrl}/report/payments?page=${page}&limit=${limit || 0}&paymentDateStart=${FormatServerDate(paymentDateStart)}&paymentDateEnd=${paymentDateEnd}&paymentDateType=${paymentDateType}&provider=${provider}&type=${type}&amountMin=${amountMin}&amountMax=${amountMax}&amountType=${amountType}&bookingId=${referenceId}`,
        });

        if (payments.statusCode !== 200) {
            return { error: payments.message };
        }

        return { data: payments.data };
    } catch (e) {
        return { error: e.response.data };
    }
}
export async function getPaymentSummary(query = {
    paymentDateStart: '',
    paymentDateEnd: '',
    paymentDateType: '',
    provider: '',
    type: '',
    amountMin: '',
    amountMax: '',
    amountType: '',
    imeiRepeated: '',
    referenceId: '',
}) {
    try {
        let {
            paymentDateStart, paymentDateEnd, paymentDateType, provider, type, amountMin, amountMax, amountType,imeiRepeated, referenceId
        } = query
        let payments = await axios.Get({
            url: `${adminUrl}/report/payments-summary?paymentDateStart=${FormatServerDate(paymentDateStart)}&paymentDateEnd=${paymentDateEnd}&paymentDateType=${paymentDateType}&provider=${provider}&type=${type}&amountMin=${amountMin}&amountMax=${amountMax}&amountType=${amountType}&bookingId=${referenceId}`,
        });

        if (payments.statusCode !== 200) {
            return { error: payments.message };
        }

        return { data: payments.data };
    } catch (e) {
        return { error: e.response.data };
    }
}
