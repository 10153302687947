import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function getAdminList({searchQuery}) {
    try {
        let packages = await axios.Get({
            url: `${adminUrl}/admins`,
            params: {
                searchQuery: searchQuery || ''
            }
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}
export async function getAdmin(id) {
    try {
        let packages = await axios.Get({
            url: `${adminUrl}/admin/${id}`,
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function updateAdminStatus(body) {
    try {
        console.log('here body', body)

        let packages = await axios.Post({
            url: `${adminUrl}/admin-status-update`,
            data: body
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function createAdmin(body) {
    try {
        let packages = await axios.Post({
            url: `${adminUrl}/create-admin`,
            data: body
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function updateAdmin(body) {
    try {
        console.log('here body', body)

        let packages = await axios.Post({
            url: `${adminUrl}/admin-update`,
            data: body
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function deleteAdmin() {
    try {
        let packages = await axios.Delete({
            url: `${adminUrl}/admin-delete`,
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}