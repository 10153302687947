import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";
// import { Delete, Get, Patch, Post, Put } from "./RestClient.js";

export async function getAddons({
    name, type, stockType, stockMin, stockMax, isActive
}) {
    try {
        let packages = await axios.Get({
            url: `${adminUrl}/add-ons/list`,
            params: {
                name: name || '',
                type: type || '',
                stockType: stockType || '',
                stockMax: stockMax || '',
                stockMin: stockMin || '',
                isActive: isActive || '',
            }
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function getAddon(itemId) {
    try {
        let packages = await axios.Get({
            url: `${adminUrl}/add-ons/${itemId}`,
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function getServices(type) {
    try {
        let packages = await axios.Get({
            url: `${adminUrl}/add-ons/service?type=${type}`,
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function getAddOnCoach(name) {
    try {
        let coaches = await axios.Get({
            url: `${adminUrl}/add-ons/coach?name=${name}`,
        });

        if (coaches.statusCode !== 200) {
            return { error: coaches.message };
        }

        return { data: coaches.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function createAddon(body) {
    try {
        let packages = await axios.Post({
            url: `${adminUrl}/add-ons/create`,
            data: { ...body }
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function updateAddon(body) {
    try {
        let packages = await axios.Post({
            url: `${adminUrl}/add-ons/update`,
            data: body
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function updateAddonService(body) {
    try {
        let packages = await axios.Post({
            url: `${adminUrl}/add-ons/service`,
            data: body
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function getHistory(id) {
    try {
        console.log('ini cek id', id)
        let packages = await axios.Get({
            url: `${adminUrl}/add-ons/stock/balance/productId=${id}`,
            // url: `${adminUrl}/add-ons/stock/balance`,
            // data: {
            //     productId: id 
            // }
        });
        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }
        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}