import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";
// import { Delete, Get, Patch, Post, Put } from "./RestClient.js";

export async function getPlaces({
    queryParams
}) {
    try {
        let places = await axios.Get({
            url: `${adminUrl}/place-list`,
            params:{
                queryParams: queryParams || ''
            }
        });
        
        if (places.statusCode !== 200) {
            return { error: places.message };
        }

        return { data: places.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function createPlaces(data) {
    try {
        let places = await axios.Post({
            url: `${adminUrl}/place-create`,
            data: data
        });

        if (places.statusCode !== 200) {
            return { error: places.message };
        }

        return { data: places.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function getPlaceDetail(id) {
    try {
        let place = await axios.Get({
            url: `${adminUrl}/place/${id}`,
        });

        if (place.statusCode !== 200) {
            return { error: place.message };
        }
        return { data: place.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function updatePlaces(data) {
    try {
        let place = await axios.Post({
            url: `${adminUrl}/place-update`,
            data: data
        });

        if (place.statusCode !== 200) {
            return { error: place.message };
        }
        return { data: place.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function getCourts(placeId) {
    try {
        let place = await axios.Get({
            url: `${adminUrl}/courts?place=${placeId}`,
        });

        if (place.statusCode !== 200) {
            return { error: place.message };
        }
        return { data: place.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function getCourtsSchedule(court, day) {
    try {
        let place = await axios.Get({
            url: `${adminUrl}/courts-schedule?court=${court}&day=${day}`,
        });

        if (place.statusCode !== 200) {
            return { error: place.message };
        }
        return { data: place.data };
    } catch (e) {
        return { error: e.response.data };
    }
}