import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";
// import { Delete, Get, Patch, Post, Put } from "./RestClient.js";

export async function getCoupons({
    createdDate, expiredDate, name, promo, type, isActive
}) {
    try {
        let packages = await axios.Get({
            url: `${adminUrl}/coupons`,
            params: {
                createdDate: createdDate || '',
                name: name || '',
                promo: promo || '',
                type: type || '',
                isActive: isActive || '',
                expiredDate: expiredDate || '',
            }
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function getCoupon(id) {
    try {
        let packages = await axios.Get({
            url: `${adminUrl}/coupon/${id}`,
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function createCoupons(body) {
    try {
        let packages = await axios.Post({
            url: `${adminUrl}/coupon-create`,
            data: body
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data.message };
    }
}

export async function updateCoupon(body) {
    try {
        let packages = await axios.Post({
            url: `${adminUrl}/coupon-update`,
            data: body
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data.message };
    }
}

export async function getAutoPopupService() {
    try {
        let packages = await axios.Get({
            url: `${adminUrl}/coupon-auto-popup`
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}