import React, { useEffect, useState } from "react"

import { Box, Button, Card, Dialog, Grid, Typography } from '@mui/material';
import { TCInput, TCSelect } from "components/TopCourtComponents/Input/TopCourtInput";
import { AddonTypeOption, orderStatusOption, orderTypeOption, periodOptions } from "constant/constant";
import NumberRangeInput from "components/TopCourtComponents/Input/NominalRangeFilter";
import DateRangeInput from "components/TopCourtComponents/Input/DateRangeFilter";
import AsyncSelect from 'react-select/async';
import { getAddons } from 'services/addonServices';


function SaleReportFilter ({
    filter,
    onChangeFilter,
    clearFilter
}) {

    const [ addOnOptions, setAddOnOptions ] = useState([])

    useEffect(() => {
        loadAddOnOptions()
    }, [filter.orderType])

    const loadAddOnOptions = async () => {
        let { data, error } = await getAddons({
            type: filter.orderType? filter.orderType.value == "all" ? '' : filter.orderType.value : '',
            isActive: true
        })
        if (error) { return [] }
        if (data) {
            // loop data
            let newData = []
            for (let i = 0; i < data.length; i ++) {
                newData.push({
                    label: data[i].name,
                    value: data[i]._id
                })
            }
            setAddOnOptions(newData)
        }
        return []
    }

    return (
        <Card sx={{ mb: 1 }}
            className="custom-card-style">
            <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                {/* filter date, booking id, user, type, status */}
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} md={1.5} sx={{ mb: 1 }}>
                        <TCSelect label={'Period'} placeHolder={'Select Provider'} options={periodOptions} value={filter.period} onChange={(e) => onChangeFilter({ period: e })} />
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ mb: 1 }}>
                        <DateRangeInput
                            label={'Range'}
                            showLabel={true}
                            startDate={filter.startDate}
                            endDate={filter.endDate}
                            dateRangeType={filter.rangeDateType}
                            onChangeSelect={(e) => onChangeFilter({ rangeDateType: e })}
                            onChangeStartDate={(e) => onChangeFilter({ startDate: e })}
                            onChangeEndDate={(e) => onChangeFilter({ endDate: e })}
                        />
                    </Grid>
                    {/* <Grid item xs={12} md={2} sx={{ mb: 1 }}>
                        <TCInput label={"Booking ID"} placeHolder={'Booking ID'} onChange={(e) => onChangeFilter({ referenceId: e.currentTarget.value })} value={filter.referenceId} />
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ mb: 1 }}>
                        <TCSelect label={'Provider'} placeHolder={'Select Provider'} options={providerOption} value={filter.provider} onChange={(e) => onChangeFilter({ provider: e })} />
                    </Grid> */}
                    <Grid item xs={12} md={2} sx={{ mb: 1 }}>
                        <TCSelect label={'Status'} placeHolder={'Select Type'} options={orderStatusOption} value={filter.orderStatus} onChange={(e) => onChangeFilter({ orderStatus: e })} />
                    </Grid>
                    <Grid item xs={12} md={1.5} sx={{ mb: 1 }}>
                        <TCSelect label={'Type'} placeHolder={'Select Type'} options={[
                            {label: "All", value: "all"},
                            ...orderTypeOption]} value={filter.orderType} onChange={(e) => {
                                let others = {}
                                if (e.value != "service") {
                                    others = {
                                        addOnType: {label: "All", value: "all"},
                                        item: null
                                    }
                                }
                                onChangeFilter({ 
                                    ...others,
                                    orderType: e,
                                })
                            }} />
                    </Grid>
                    <Grid item container xs={12} md={3} spacing={1} sx={{ mb: 1 }}>
                        {/* <Grid item xs={4}>
                            <TCSelect label={'Add On'} placeHolder={'Select Type'} disabled={true} options={[
                                {label: "All", value: "all"},
                                ...AddonTypeOption]
                            } value={filter.addOnType} onChange={(e) => onChangeFilter({ addOnType: e })} />
                        </Grid> */}
                        <Grid item xs={12}>
                            <TCSelect
                                label={"Add On"}
                                disabled={filter.orderType.value != "service" && filter.orderType.value != "rent" && filter.orderType.value != "buy"}
                                placeholder={'AddOn...'}
                                isClearable
                                options={addOnOptions}
                                isMulti={true}
                                value={filter.item} onChange={(e) => { onChangeFilter({ item: e }) }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs sx={{ mb: 1, textAlign: 'right' }} >
                        <Button sx={{ mt: 2, border: '0.5px solid #2196f3', borderRadius: 4 }}
                            onClick={clearFilter}
                        >Clear</Button>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    )
}

export default SaleReportFilter