import { Close } from "@mui/icons-material"
import { AppBar, Card, Grid, IconButton, Toolbar } from "@mui/material"
import { TCLabel, TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { getPlayerProfileHistory } from "services/playerServices"

function EditHistory ({
    userId,
    setClose
}) {

    const [ historyData, setHistoryData ] = useState([])

    useEffect(() => {
        loadUserProfileHistory()
    }, [])

    const loadUserProfileHistory = async () => {
        let { data, error } = await getPlayerProfileHistory({
            userId
        })
        if (error) { console.log(error) }
        if (data) { 
            console.log('here data', data)
            setHistoryData(data.data) 
        }
    }

    return (
        <div
            style={{
              minWidth: (window.innerWidth < 500) ? '100vw' : '40vw',
              maxWidth: (window.innerWidth < 500) ? '100vw' : '40vw',
              overflow: 'auto'
            }}>
            <AppBar style={{
              maxWidth: (window.innerWidth < 500) ? '100vw' : '40vw',
              backgroundColor: '#0198E1'
            }}>
              <Toolbar style={{ maxHeight: '55px', minHeight: '55px' }}>
                <IconButton edge="start" color="inherit" aria-label="close" onClick={() => {
                  setClose()
                }}>
                  <Close />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Grid xs={12} p={2} pt={8}>
            {
                historyData && historyData.map((value, index) => {
                    return (<Card style={{ marginBottom: 10, border: '1px solid rgba(0, 0, 0, 0.1)' }} key={index}>
                        <Grid xs={12} p={1}>
                            <div style={{fontSize: 10, color: 'grey'}}>{moment(value.createdDate).format('YYYY/MM/DD HH:mm:ss')}</div>
                            {value.admin && <div style={{fontSize: 12, fontWeight: 'bold'}}>{`${value.admin.name} edit Status User`}</div>}
                            <div style={{display: 'flex'}}>
                                {
                                <div style={{width: '50%'}} key={`${value._id}oldValue`}>
                                    <div style={{fontSize: 10, color: 'grey'}}>
                                        Old Value:
                                    </div>
                                    {
                                        value.oldValue.length > 0 &&
                                        Object.keys(value.oldValue[0])[0] ? 
                                        value.oldValue.map((userInfoValue, index) => {
                                            return (
                                            <div style={{fontSize: 12}} key={`${index}userInfoOldValue`}>
                                                {
                                                  (Object.keys(userInfoValue)[0] != null || Object.keys(userInfoValue)[0] != undefined) ?
                                                      logValueCustomator(userInfoValue, false, true)
                                                  :
                                                      logValueCustomator(value.newUserInfo[index], true, true)
                                                }
                                            </div>
                                            )
                                        })
                                    :
                                        value.newValue.map((userInfoValue, index) => {
                                            return (
                                            <div style={{fontSize: 12}} key={`${index}userInfoOldValue`}>
                                                {logValueCustomator(userInfoValue , true, true)}
                                            </div>
                                            )
                                        })
                                    }
                                </div>
                                }
                                <div style={{width: '50%'}} key={`${value._id}newValue`}>
                                <div style={{fontSize: 10, color: 'grey'}}>
                                    New Value:
                                </div>
                                {
                                    value.newValue.length > 0 &&
                                    value.newValue.map((userInfoValue, index) => {
                                        return (
                                            <div style={{fontSize: 12}} key={`${index}userInfoNewValue`}>
                                            {logValueCustomator(userInfoValue, false, false)}
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        </Grid>
                    </Card>)
                })
            }
            {
                (!historyData || historyData.length == 0) &&
                <Grid xs={12} >
                    <TCLabel bold>No Data</TCLabel>
                </Grid>
            }
            </Grid>
        </div>
    )
    
}

function logValueCustomator(value, empty = false, oldValue = false) {
  let keysCustomated = Object.keys(value)[0]
  let valueCustomated = value[Object.keys(value)[0]]
  let data = []
  if (Object.keys(value)[0] === "name") {
    keysCustomated = "Name"
    data.push(<div key={Object.keys(value)[0]}>{keysCustomated} : {valueCustomated ? valueCustomated : 'No value'}</div>)
  }
  if (Object.keys(value)[0] === "birthYear") {
    keysCustomated = "Birth Year"
    data.push(<div key={Object.keys(value)[0]}>{keysCustomated} : {valueCustomated ? valueCustomated : 'No value'}</div>)
  }
  if (Object.keys(value)[0] === "alternativePhoneNumber") {
    keysCustomated = "Alternative Phone"
    data.push(<div key={Object.keys(value)[0]}>{keysCustomated} : {valueCustomated ? valueCustomated : 'No value'}</div>)
  }
  if (Object.keys(value)[0] === "password") {
    keysCustomated = "Password"
    data.push(<div key={Object.keys(value)[0]}>{keysCustomated} : {valueCustomated ? valueCustomated : 'No value'}</div>)
  }
  if (Object.keys(value)[0] === "isActive") {
    console.log('here check value',keysCustomated, Object.keys(value)[0], value)
    keysCustomated = "Blacklist"
    data.push(<div key={Object.keys(value)[0]}>{keysCustomated} : {valueCustomated.toString() == "true" ? "True" : "False"}</div>)
  }
  if (Object.keys(value)[0] === "isCounter") {
    console.log('here check value',keysCustomated, Object.keys(value)[0], value)
    keysCustomated = "Counter"
    data.push(<div key={Object.keys(value)[0]}>{keysCustomated} : {valueCustomated.toString() == "true" ? "True" : "False"}</div>)
  }
  if (Object.keys(value)[0] === "isStaff") {
    console.log('here check value',keysCustomated, Object.keys(value)[0], value)
    keysCustomated = "Staff"
    data.push(<div key={Object.keys(value)[0]}>{keysCustomated} : {valueCustomated.toString() == "true" ? "True" : "False"}</div>)
  }
  if (Object.keys(value)[0] === "packageEnd") {
    console.log('here check value',keysCustomated, Object.keys(value)[0], value)
    keysCustomated = "Pack Valid Date"
    data.push(<div key={Object.keys(value)[0]}>{keysCustomated} : {valueCustomated ? valueCustomated : 'No value'}</div>)
  }
  if (Object.keys(value)[0] === "advancedSchedule") {
    console.log('here check value',keysCustomated, Object.keys(value)[0], value)
    keysCustomated = "Advanced Booking"
    data.push(<div key={Object.keys(value)[0]}>{keysCustomated} : {valueCustomated ? valueCustomated : 'No value'}</div>)
  }
  if (Object.keys(value)[0] === "description") {
    keysCustomated = "Description"
    if (empty && valueCustomated) {
      data.push(<div key={Object.keys(value)[0]}>{keysCustomated} : {valueCustomated ? valueCustomated : 'No value'}</div>)
    } else if (!oldValue && valueCustomated) {
      data.push(<div key={Object.keys(value)[0]}>{keysCustomated} : {valueCustomated ? valueCustomated : 'No value'}</div>)
    } else {
      return;
    }
  }
  // if ((empty && !valueCustomated) || (!oldValue && !valueCustomated)) {
  //   return;
  // }
  if (empty) {
    return (
      <div>
        {keysCustomated} : No value
      </div>
    )
  }
  // `${keysCustomated} : ${valueCustomated}`
  // return (
  //   <div>
  //     {keysCustomated} : {valueCustomated ? valueCustomated : 'No value'}
  //   </div>
  // )
  return (data)
}

export default EditHistory