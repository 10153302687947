// import { getUserInfo, getCourtInfo, setLocalBookingForm, getLocalBookingForm, deleteLocalBookingForm } from 'helper/localStorage';
// import { getCourtInformation, getCourtSchedule, getScheduleCart, 
//     addScheduleCart, deleteScheduleCart, submitOrder, setValuePack, getUserCartSummary } from 'service/bookingService';
// import moment from 'moment';
// import { getMe } from 'service/authService';
// import { getCouponPopup, checkCoupon } from 'service/couponService';
// import { Confirm } from 'helper/showAlert';

export const validation = {
    isEmailAddress:function(str) {
        if (!str) { return true }
        var pattern =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return pattern.test(str);  // returns a boolean
    },
    isNotEmpty:function (str) {
        var pattern =/\S+/;
        return pattern.test(str);  // returns a boolean
    },
    isNumber:function(str) {
        if (!str) { return true }
        var pattern = /^\d+\.?\d*$/;
        return pattern.test(str);  // returns a boolean
    },
    userName:function(str) {
        if (!str) { return true }
        return str.length <= 21;  // returns a boolean
    },
    phoneNumber:function(str) {
        if (!str) { return true }
        var pattern = /^\d+\.?\d*$/;
        return pattern.test(str) && str.length <= 13;  // returns a boolean
    },
    isSame:function(str1,str2){
        return str1 === str2;
    }
};   