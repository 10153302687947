import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { TCLabel } from '../Label/TopCourtLabel';
import { TCInput, TCSelect } from './TopCourtInput';

export const TypeSelectOption = [
    { value: "ib", label: "<>" },
    { value: "eq", label: "=" },
    { value: "lt", label: "<" },
    { value: "gt", label: ">" },
];

class NumberRangeInput extends Component {

    // ---onChange
    // -number range type
    onChangeSelect = value => {
        const { onChangeSelect, start, end, onChangeEnd } = this.props;
        if (value.value !== 'ib') {
            onChangeEnd('', this.getLoad(value, start, end));
        }
        onChangeSelect(value, this.getLoad(value, start, end));
    }

    // -start
    onChangeStart = event => {
        const { value } = event.target;
        const { onChangeStart, numberRangeType, end } = this.props;
        onChangeStart(value, this.getLoad(numberRangeType, value, end));
    }
    // -end
    onChangeEnd = event => {
        const { value } = event.target;
        const { onChangeEnd, numberRangeType, start } = this.props;
        onChangeEnd(value, this.getLoad(numberRangeType, start, value));
    }
    // func helper
    getLoad = (numberRangeType, start, end) => {
        // helper for check range
        let load = true;

        if (numberRangeType.value === 'ib') {
            // if one is empty
            if (!start || !end) { load = false; }
        } else {
            if (!start) { load = false; }
        }
        return load;
    }

    render() {
        // styles
        const defaultStyles = {
            flexLayout: {
                display: 'flex',
                flexFlow: 'wrap',
                margin: '0 -1.5px',
            },
            label: {
            },
            selectType: {
                dropdownIndicator: () => ({
                    display: 'none'
                }),
            },
            numberRangeType: {
                flex: '0.3 1 40px',
                padding: '0 1.5px',
            },
            start: {
                flex: '1 1 90px',
                padding: '0 1.5px',
            },
            end: {
                flex: '1 1 90px',
                padding: '0 1.5px',
            }
        };
        if (window.innerWidth > 1280) {
            defaultStyles.selectType = {
                dropdownIndicator: (base) => ({
                    ...base,
                    padding: 0,
                }),
            };
            defaultStyles.numberRangeType = {
                flex: '0.5 1 50px',
                padding: '0 1.5px',
            }
        }
        // props
        const {
            typeSelectOption,
            numberRangeType,
            start,
            end,
            label,
            placeholderStart,
            placeholderEnd,
            customStyle,
            disabledVariable,
            disabledStart,
            disabledEnd,
            showLabel = false,
            isInvalid,
            // show hide
            hideEnd = false,
            allowZero = false
        } = this.props;

        const style = customStyle ? {
            ...defaultStyles,
            ...customStyle
        }
            :
            defaultStyles;

        return (
            <React.Fragment>
                {showLabel && (<TCLabel style={{...style.label, color: "rgb(133, 133, 133)"}}>{label}</TCLabel>)}
                <div style={style.flexLayout}>
                    <div style={style.numberRangeType}>
                        <TCSelect
                            isClearable={false}
                            name="selectType"
                            isDisabled={disabledVariable}
                            styles={{
                                indicatorSeparator: (base) => ({
                                    ...base,
                                    display: "none",
                                }),
                                dropdownIndicator: (base) => ({
                                    ...base,
                                    display: "none",
                                }),
                            }}
                            placeholder={"Type"}
                            value={numberRangeType}
                            options={typeSelectOption}
                            onChange={this.onChangeSelect}
                            isInvalid={isInvalid}
                        />
                    </div>
                    <div style={style.start} title={placeholderStart}>
                        <TCInput
                            name="start"
                            className={`form-error ${isInvalid ? 'invalid' : ''}`}
                            disabled={disabledStart}
                            placeHolder={placeholderStart}
                            onChange={this.onChangeStart}
                            value={(start || (allowZero && start == 0)) ? start : ''}
                            autoComplete={"off"}
                        />
                    </div>
                    {!hideEnd && (
                        <div style={style.end} title={placeholderEnd}>
                            <TCInput
                                name="end"
                                className={`form-error ${isInvalid ? 'invalid' : ''}`}
                                placeHolder={placeholderEnd}
                                onChange={this.onChangeEnd}
                                value={end ? end : ''}
                                disabled={(numberRangeType.value !== 'ib') || disabledEnd}
                                autoComplete={"off"}
                            />
                        </div>
                    )}
                </div>
            </React.Fragment>
        )
    }
}
NumberRangeInput.propTypes = {
    // value
    numberRangeType: PropTypes.object,
    start: PropTypes.any,
    end: PropTypes.any,
    // changes
    onChangeSelect: PropTypes.func,
    onChangeStart: PropTypes.func.isRequired,
    onChangeEnd: PropTypes.func,
    // title, text desc
    customStyle: PropTypes.object, // to override
    label: PropTypes.string,
    placeholderStart: PropTypes.string,
    placeholderEnd: PropTypes.string,
}
NumberRangeInput.defaultProps = {
    typeSelectOption: TypeSelectOption,
    numberRangeType: TypeSelectOption[0],
    label: 'Nominal',
    placeholderStart: 'Start',
    placeholderEnd: 'End',
}
export default NumberRangeInput;
