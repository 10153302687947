import React from "react"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TCLabel } from "./Label/TopCourtLabel";

function TCAccordion ({
    id,
    ariaControls,
    title,
    children
}) {
    return (
        <Accordion disableGutters>
            <AccordionSummary
                sx={{
                    margin: 0,
                    minHeight: 0
                }}
                expandIcon={<ArrowDropDownIcon />}
                aria-controls={ariaControls}
                id={id}
            >
                {title}
            </AccordionSummary>
            <AccordionDetails>
                {children && children}
            </AccordionDetails>
        </Accordion>
    )
}

export default TCAccordion