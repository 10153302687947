function orderStatusMap (data) {
    if (data.orderStatus) {
        let message = "Awaiting Payment"
        let { orderStatus, orderType } = data;
        if (orderStatus[0].date != null) { message = "Awaiting Payment" }
        if (orderStatus[1].date != null) { message = "Paid" }
        // if (orderStatus[1].date != null && orderType == 'sell') { message = "Counter Pick Up" }
        // if (orderStatus[1].date != null && orderType == 'rent') { message = "Rent" }
        if (orderStatus[1].date != null && orderType == 'rent' && data.lastStatus == "rent") { message = "Rent" }
        if (orderStatus[2].date != null) { message = "Completed" }
        if (orderStatus[3].date != null) { message = "Payment Failed" }
        return message
    } else return 'Awaiting Payment'
}

export { orderStatusMap }