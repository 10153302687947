import React, { useEffect, useState } from 'react';

// material-ui
import { Box, Button, Card, Grid, Input, Typography, TextareaAutosize, TextField, Switch, FormControlLabel, Checkbox } from '@mui/material';

// project imports
import { TCInput } from 'components/TopCourtComponents/Input/TopCourtInput';
import { TCLabel, TCTitleLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';
import { getParamsFromURL } from 'helper/Params';
// import { getCounter, updateCounter } from 'services/SettingServices';
import { createCategory, getCategory, udpateCategory } from 'services/categoryServices';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

export default function AddCategories() {

    const [form, setForm] = useState({
        name: '',
        defaultPrice: ''
    })
    const [joiForm, setJoiForm] = useState({
        name: null,
        defaultPrice:null
    })

    const navigate = useNavigate()

    useEffect(() => {
        let { params } = getParamsFromURL(window);
        if (params) getCategoryDetail(params)
    }, [])

    
    useEffect(() => {
        setJoiForm({
            name: form.name ? null : ' required',
            defaultPrice: form.defaultPrice && form.defaultPrice>0? null : ' must be greater than 0'
        })
    }, [form])

    const disabledForm = () => {
        return joiForm.name || joiForm.defaultPrice
    }

    const onChange = (e) => {
        setForm(prev => ({
            ...prev,
            ...e
        }))
    }

    const getCategoryDetail = async (id) => {
        try {
            let { data, error } = await getCategory(id)
            if (error) throw error
            if (data) {
                setForm(data)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const updateOrCreate = () => {
        if (form._id) {
            update()
        } else {
            create()
        }
    }

    const create = async () => {
        try {
            let body = { ...form }
            let { data, error } = await createCategory(body)
            if (error) throw error
            if (data) {
                setForm(data)
                toast('Add Category success', {
                    toastId: "category-success",
                    type: "success"
                });
                console.log('success')
                navigate('/categories')
            }
        } catch (error) {
            console.log(error)
        }
    }

    const update = async () => {
        try {
            let body = { ...form }
            let { data, error } = await udpateCategory(body)
            if (error) throw error
            if (data) {
                setForm(data)
                console.log('success')
                toast('Update Category success', {
                    toastId: "category-success",
                    type: "success"
                });
                // navigate('/categories')
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Grid item container xs={12} md={12} lg={12}>
                <Grid xs={12} md={6} lg={6} sx={{ p: 1 }}>
                    <Card sx={{ mb: 3 }}>
                        <Box sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: 3
                        }}
                        >
                            <Grid container sx={{
                                p: 2
                            }}>
                                {/* <Grid xs={12} md={12} sx={{ p: 1 }}>
                                    <TCTitleLabel>{form._id ? 'Update Counter' : 'Add New Counter'}</TCTitleLabel>
                                </Grid> */}
                                <Grid item container xs={12} md={12}>
                                    <Grid xs={12} md={12} sx={{ p: 1 }}>
                                        <TCInput value={form.name} label={"Category Name*"}
                                            showAlert={joiForm && joiForm.name} onChange={(e) => onChange({ name: e.currentTarget.value.trimStart() })} />
                                    </Grid>
                                    <Grid xs={12} md={12} sx={{ p: 1 }}>
                                        <TCInput value={form.defaultPrice} label={"Default Price*"} showAlert={joiForm && joiForm.defaultPrice}
                                            onChange={(e) => onChange({ defaultPrice: e.currentTarget.value.replace(/[^0-9]+/ig, '') })} />
                                    </Grid>
                                    {/* <Grid xs={2} md={2} sx={{ p: 1 }} >
                                        <TCLabel style={{ marginLeft: 10 }}>Status</TCLabel>
                                        <Switch checked={form.isActive} onChange={() => onChange({ isActive: !form.isActive })} />
                                    </Grid> */}
                                </Grid>
                            </Grid>
                            <Grid container direction={"row"} sx={{
                                pl: 3
                            }}>
                                <Button
                                    variant="contained"
                                    onClick={() => { updateOrCreate() }}
                                    disabled={disabledForm()}
                                >
                                    {form._id ? "Update" : "Add"}
                                </Button>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}