import { toast } from 'react-toastify'

export const toastMessage = (
    message,
    config = {}
) => {
    return toast(message, {
        ...config,
        autoClose: 1500,
        hideProgressBar: true,
        theme: "colored"
    })
}