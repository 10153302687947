function getParamsFromURL(window) {
    let isEdit = false
    let params = window.location.href.split('/')
    params = params[params.length - 1]
    
    if (params && params == 'add') {
      isEdit = false
      params = null
    } else { isEdit = true }

    return { isEdit, params }
}

export { getParamsFromURL }