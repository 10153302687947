import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function getCounters({
    name, counterId, isActive
}) {
    try {
        let packages = await axios.Get({
            url: `${adminUrl}/counter/list`,
            params: {
                name: name || '',
                counterId: counterId || '',
                isActive: isActive || ''
            }
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }
        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function getCounter(id) {
    try {
        let packages = await axios.Get({
            url: `${adminUrl}/counter/${id}`,
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function createCounter(body) {
    try {
        let packages = await axios.Post({
            url: `${adminUrl}/counter/add`,
            data: { ...body }
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}

export async function updateCounter(body) {
    try {
        let packages = await axios.Post({
            url: `${adminUrl}/counter/update`,
            data: { ...body }
        });

        if (packages.statusCode !== 200) {
            return { error: packages.message };
        }

        return { data: packages.data };
    } catch (e) {
        return { error: e.response.data };
    }
}