import React, { useState, useEffect } from "react";

import { dayOptions, timeOptions, operationalHours } from "constant/constant";
import { Grid, Switch } from "@mui/material";
import Select from 'react-select';
import { maxWidth } from "@mui/system";
import { TCLabel, TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";

import moment from "moment";

function ScheduleHoursComponent({
    setCourt,
    currOpsHours,
    showSummary,
    setShowSummary,
    exceptionHours = []
}) {
    const [disableSummary, setDisableSummary] = useState(false)
    const [ status, setStatus ] = useState(true)
    const [ reload, setReload ] = useState(Date.now())
    const [ opsHours, setOpsHours ] = useState(operationalHours)

    useEffect(() => {
        if (currOpsHours.length > 0) {
            let newOpsHours = checkOpsHours(currOpsHours)
            setOpsHours(newOpsHours)
        }
    }, [currOpsHours])

    // useEffect(()=>{
    //     setShowSummary({showSummary: false})
    // },[disableSummary===false])

    useEffect(() => {
        // checkOpsHours()
    }, [opsHours, reload])

    const onChangeOperationalHours = (e, index) => {
        let currData = opsHours[index];
        currData = {
            ...currData,
            ...e
        }
        let newOps = opsHours;
        newOps.splice(index, 1, currData)
        let newOpsHours = checkOpsHours(newOps)
        setOpsHours(prev => (newOpsHours))
        setCourt({
            operationalHours: newOps
        })
        setReload(Date.now())
    }

    const checkOpsHours = (operationalHours) => {
        operationalHours.forEach((value, index) => {
            let startHours = checkHours(value.day, null, false)
            let endHours = checkHours(value.day, value.operationalHours.start, true)
            value.startHoursOptions = startHours.newHoursOpt
            value.endHoursOptions = endHours.newHoursOpt
            value.disabled = startHours.disable
        })
        return operationalHours
    }

    const checkHours = (day, startHour, isEndHours) => {
        if (isEndHours) {
            let hours = []
            let start = false
            for (let i = 0; i < timeOptions.length; i ++) {
                if (start) { hours.push(timeOptions[i]) }
                if (timeOptions[i].value == startHour) { start = true }
            }
            return checkAvailableHours(day, hours, true, true)
        } else {
            return checkAvailableHours(day, timeOptions, true, false)
        }
    }

    const checkAvailableHours = (e, hours, disableEnd, openEnd) => {
        let similiar = false
        for (let i = 0; i < exceptionHours.length; i ++) {
            if (moment(exceptionHours[i].date).format("dddd") == e) {
                let newHours = []
                let disable = false
                for (let j = 0; j < hours.length; j++) {
                    if (exceptionHours[i].hours.indexOf(hours[j].value) >= 0) {
                        if (openEnd) {
                            newHours.push({
                                ...hours[j],
                                isDisabled: true
                            })
                        } else {
                            if (disable) {
                                newHours.push({
                                    ...hours[j],
                                    isDisabled: true
                                })
                            } else {
                                newHours.push(hours[j])
                            }
                        }
                        disable = true
                    } else if (disableEnd && disable) {
                        if (openEnd) {
                            let sortHours = bubbleSort(exceptionHours[i].hours)
                            let hoursJ = Number(hours[j].value.split(':')[0])
                            let endHours = Number(sortHours[sortHours.length - 1].split(':')[0])
                            console.log('check hours', hoursJ, endHours)
                            if (hoursJ >= (endHours + 1)) {
                                newHours.push(hours[j])
                            } else {
                                newHours.push({
                                    ...hours[j],
                                    isDisabled: true
                                })
                            }
                        } else {
                            disable = true
                            newHours.push({
                                ...hours[j],
                                isDisabled: true
                            })
                        }
                    } else {
                        if (openEnd) {
                            let sortHours = bubbleSort(exceptionHours[i].hours)
                            let hoursJ = Number(hours[j].value.split(':')[0])
                            let endHours = Number(sortHours[sortHours.length - 1].split(':')[0])
                            console.log('check hours', hoursJ, endHours)
                            if (hoursJ >= (endHours + 1)) {
                                newHours.push(hours[j])
                            } else {
                                newHours.push({
                                    ...hours[j],
                                    isDisabled: true
                                })
                            }
                        } else {
                            newHours.push(hours[j])
                        }
                    }
                }
                similiar = true
                console.log('Check Available Hours', newHours)
                return { newHoursOpt: newHours, disable }
            }
        }
        if (!similiar) {
            return { newHoursOpt: hours, disable: false }
        }
        // setListHours
    }

    return (
        <Grid pb={2}>
            {
                opsHours.map( (value, index) => {
                    return(
                        <Grid sx={{
                            mt:1
                        }}>
                            <TCLabel>{value.day}</TCLabel>
                            <div style={{display: 'flex'}}>
                                <Grid sx={{
                                    minWidth: 100,
                                    maxWidth: 100,
                                    mr: 1
                                }}>
                                    <Select isDisabled={!status || !value.isActive} value={{
                                        label: value.operationalHours.start,
                                        value: value.operationalHours.start,
                                    }} onChange={(e) => {
                                        console.log(e)
                                        onChangeOperationalHours({operationalHours: {
                                            start: e.value,
                                            end: value.operationalHours.end
                                        }}, index)
                                    }} options={value.startHoursOptions || timeOptions}/>
                                </Grid>
                                <Grid sx={{
                                    minWidth: 100,
                                    maxWidth: 100
                                }}>
                                    <Select isDisabled={!status || !value.isActive} value={{
                                        label: value.operationalHours.end,
                                        value: value.operationalHours.end,
                                    }} onChange={(e) => {
                                        console.log(e)
                                        onChangeOperationalHours({operationalHours: {
                                            start: value.operationalHours.start,
                                            end: e.value
                                        }}, index)
                                    }} options={value.endHoursOptions || timeOptions}/>
                                </Grid>
                                <Switch 
                                    disabled={value.disabled}
                                    checked={value.isActive} onChange={() => onChangeOperationalHours({isActive: !value.isActive}, index)}/>
                            </div>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

function bubbleSort(arr) {
    var i, j;
    var len = arr.length;
    var isSwapped = false;
    for (i = 0; i < len; i++) {
        isSwapped = false;
        for (j = 0; j < (len - i - 1); j++) {
            if (arr[j] > arr[j + 1]) {
                var temp = arr[j]
                arr[j] = arr[j + 1];
                arr[j + 1] = temp;
                isSwapped = true;
            }
        }
        if (!isSwapped) {
            break;
        }
    }
    return arr
}

export default ScheduleHoursComponent