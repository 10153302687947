// assets
import { IconKey, IconBallTennis, IconUsers, IconDashboard, IconCashBanknote } from '@tabler/icons';

// constant
const icons = {
    IconKey,
    IconBallTennis,
    IconUsers,
    IconDashboard,
    IconCashBanknote
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const admins = {
    id: 'admins',
    title: '',
    caption: '',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: true
        },
        {
            id: 'admins',
            title: 'Admin',
            type: 'item',
            url: '/admins',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'adminadd',
                    title: 'Add',
                    type: 'item',
                    url: '/add',
                },
                {
                    id: 'adminedit',
                    title: 'Edit',
                    type: 'item',
                    url: '/edit',
                },
            ]
        },
        {
            id: 'users',
            title: 'User',
            type: 'item',
            url: '/users',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'userdetail',
                    title: 'Detail',
                    type: 'item',
                    url: '/detail',
                },
            ]
        },
        {
            id: 'orders',
            title: 'Order',
            type: 'item',
            url: '/orders',
            icon: icons.IconBallTennis,
        },
        {
            id: 'payment',
            title: 'Payment',
            type: 'item',
            url: '/payments',
            icon: icons.IconCashBanknote,
        },
        {
            id: 'marketing',
            title: 'Marketing',
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'packages',
                    title: 'Value Pack',
                    type: 'item',
                    url: '/packages',
                    icon: icons.IconBallTennis,
                    children: [
                        {
                            id: 'packageadd',
                            title: 'Add',
                            type: 'item',
                            url: '/add',
                        },
                        {
                            id: 'packageedit',
                            title: 'Edit',
                            type: 'item',
                            url: '/edit',
                        },
                    ]
                },
                {
                    id: 'coupons',
                    title: 'Promo Code',
                    type: 'item',
                    url: '/coupons',
                    icon: icons.IconBallTennis,
                    children: [
                        {
                            id: 'couponadd',
                            title: 'Add',
                            type: 'item',
                            url: '/add',
                        },
                        {
                            id: 'couponedit',
                            title: 'Edit',
                            type: 'item',
                            url: '/edit',
                        },
                    ]
                },
                {
                    id: 'vouchers',
                    title: 'Voucher',
                    type: 'item',
                    url: '/vouchers',
                    icon: icons.IconBallTennis,
                    children: [
                        {
                            id: 'voucheradd',
                            title: 'Add',
                            type: 'item',
                            url: '/add',
                        },
                        {
                            id: 'voucheredit',
                            title: 'Edit',
                            type: 'item',
                            url: '/edit',
                        },
                    ]
                },
            ]
        },
        {
            id: 'addon',
            title: 'Add-On',
            type: 'item',
            url: '/addon',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'addonadd',
                    title: 'Add',
                    type: 'item',
                    url: '/add',
                },
                {
                    id: 'addonedit',
                    title: 'Edit',
                    type: 'item',
                    url: '/edit',
                },
            ]
        },
        {
            id: 'coaches',
            title: 'Coach',
            type: 'item',
            url: '/coaches',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'coachadd',
                    title: 'Add',
                    type: 'item',
                    url: '/add',
                },
                {
                    id: 'coachedit',
                    title: 'Edit',
                    type: 'item',
                    url: '/edit',
                },
            ]
        },
        {
            id: 'classes',
            title: 'Class & Event',
            type: 'item',
            url: '/classes',
            icon: icons.IconUsers,
        },
        // {
        //     id: 'report',
        //     title: 'Reports',
        //     type: 'collapse',
        //     icon: icons.IconUsers,
        //     children: [
        //         {
        //             id: 'reports',
        //             title: 'Sale Report',
        //             type: 'item',
        //             url: '/sale-report',
        //             icon: icons.IconBallTennis,
        //         }
        //     ]
        // },
    ]
};

export default admins;
