import { reactLocalStorage } from 'reactjs-localstorage';
import { Action, Confirm } from 'helper/Alert';
import { getUserInfo } from 'helper/LocalStorage';
import { adminUrl } from "../config/config.js";
import moment from 'moment';

const Axios = require('axios');

export const getToken = () => {
  let token = ''
  if (reactLocalStorage.get('user_token')) {
    token = reactLocalStorage.get('user_token');
  }
  // if (!token) {
  //   reactLocalStorage.clear()
  //   window.location.href = "/login"
  // }
  return token;
}

export const client = Axios.create({
  baseURL: adminUrl,
  // access: getMAC('en0'),
  headers: {
    'Content-Type': 'application/json',
    'x-access-token': getToken()
  },
  timeout: 10000,
});

export const errorValidation = (e) => {
  try {
    if (e.response.status === 405) { 
      // deleteUserInfo()
      Action("", "Uh oh .. you are logged out because of multiple login. Please login again.", () => {
        window.location.href="/login"
      })
    }
    if (e.response.status === 403) { 
      // deleteUserInfo()
    }
    console.log('here error', e.response)
    return { error: e.response.data.status };
  } catch (error) {
    // toast('There is problem with server connection', {
    //   toastId: "error-api",
    //   type: "error"
    // });
    Confirm("", "There is problem with server connection")
    return { error: 'There is problem with server connection'}
  }
}

export const FormatServerDate = (date) => {
  // 2024-07-14T16:00:00.000Z
  if (!date) { return null }
  let splitDate = date.split('T')
  console.log('here check date information', splitDate, moment(splitDate[0]).format('YYYY-MM-DD') ,moment(splitDate[0]).format('YYYY-MM-DDT17:00:00.000'))
  return moment(splitDate[0]).add(-1, 'day').format('YYYY-MM-DDT17:00:00.000')
}

// export const login = async function (body) {
//   try {
//     let response = await client.post('/login', {
//       phoneNumber: body.phoneNumber || ""
//     });
//     if (response.data.statusCode !== 200) { throw response.data.data; }
//     let data = response.data.data;
//     return { data };
//   } catch (e) {
//     return { error: e.response.data.errorCode };
//   }
// }

// export const registerUser = async function(body) {
//   try {
//     let response = await client.post(`/register-user/${body._id}`, {
//       name: body.name || "",
//       phoneNumber: body.phoneNumber || "",
//       gender: body.gender || "", // Male
//     });
//     if (response.data.statusCode !== 200) { throw response.data.data; }
//     let data = response.data.data;
//     return { data };
//   } catch (e) {
//     return { error: e.response.data.errorCode };
//   }
// };

