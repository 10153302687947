import React from "react";

import { Box,  Grid } from '@mui/material';
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput";

export default function AdminFilter({
    filter,
    onChangeFilter,
}) {

    return (
        <Box sx={{ justifyContent: 'center', alignItems: 'right' }}>
            <Grid container justifyContent={'flex-end'}>
                <Grid item xs={12} md={4} sx={{ mb: 2 }}>
                    <TCInput placeHolder={'Search...'} onChange={(e) => onChangeFilter({ searchQuery: e.currentTarget.value })} value={filter.searchQuery} />
                </Grid>
            </Grid>
        </Box>
    )
}