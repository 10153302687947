import React from "react";

import { Box,  Grid } from '@mui/material';
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput";

export default function CourtFilter({
    filter,
    onChangeFilter,
}) {

    return (
        <Box sx={{ justifyContent: 'center', alignItems: 'right' }}>
            <Grid container justifyContent={'flex-end'}>
                <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                    <TCInput placeHolder={'Search'} onChange={(e) => onChangeFilter(e.currentTarget.value )} value={filter} />
                </Grid>
            </Grid>
        </Box>
    )
}