import React, { useEffect, useState } from 'react';

// material-ui
import { Box, Button, Card, Grid, Input, Typography, TextareaAutosize, TextField, Switch, FormControlLabel, Checkbox } from '@mui/material';

// project imports
import { TCInput } from 'components/TopCourtComponents/Input/TopCourtInput';
import { TCLabel, TCTitleLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';
import { getParamsFromURL } from 'helper/Params';
import { createCounter, getCounter, updateCounter } from 'services/counterServices';

export default function AddCounter() {
    const [form, setForm] = useState({
        _id: '',
        counterId: '',
        name: '',
        address: '',
        password: '',
        phoneNumber: '',
        isActive: true
    })
    const [confirmPassword, setConfirmPassword] = useState('')

    useEffect(() => {
        let { params } = getParamsFromURL(window);
        if (params) getCounterDetail(params)
    }, [])

    const onChange = (e) => {
        setForm(prev => ({
            ...prev,
            ...e
        }))
    }

    const getCounterDetail = async (id) => {
        try {
            let { data, error } = await getCounter(id)
            if (error) throw error
            if (data) {
                setForm(data)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const updateOrCreate = () => {
        if (form._id) {
            update()
        } else {
            create()
        }
    }

    const create = async () => {
        try {
            if (form.password && confirmPassword) {
                let body = { ...form }
                let { data, error } = await createCounter(body)
                if (error) throw error
                if (data) {
                    setForm(data)
                    console.log('success')
                }
            }
            else {
                console.log('Required password')
                return
            }
        } catch (error) {
            console.log(error)
        }
    }

    const update = async () => {
        try {
            let body = { ...form }
            let { data, error } = await updateCounter(body)
            if (error) throw error
            if (data) {
                setForm(data)
                console.log('success')
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Grid item container xs={12} md={12} lg={12}>
                <Grid xs={12} md={6} lg={6} sx={{ p: 1 }}>
                    <Card sx={{ mb: 3 }}>
                        <Box sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: 3
                        }}
                        >
                            <Grid container sx={{
                                p: 2
                            }}>
                                <Grid xs={12} md={12} sx={{ p: 1 }}>
                                    <TCTitleLabel>{form._id ? 'Update Counter' : 'Add New Counter'}</TCTitleLabel>
                                </Grid>
                                <Grid item container xs={12} md={12}>
                                    <Grid xs={10} md={10} sx={{ p: 1 }}>
                                        <TCInput disabled={getParamsFromURL(window).params ? true : false}
                                            label={"Counter ID"} onChange={(e) => onChange({ counterId: e.currentTarget.value })} value={form.counterId} />
                                    </Grid>
                                    <Grid xs={2} md={2} sx={{ p: 1 }} >
                                        <TCLabel style={{ marginLeft: 10 }}>Status</TCLabel>
                                        <Switch checked={form.isActive} onChange={() => onChange({ isActive: !form.isActive })} />
                                    </Grid>
                                </Grid>
                                <Grid xs={12} md={12} sx={{ p: 1 }}>
                                    <TCInput label={"Counter Name"} onChange={(e) => onChange({ name: e.currentTarget.value })} value={form.name} />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ p: 1 }}>
                                    <TCInput label={"Phone Number"} value={form.phoneNumber} onChange={(e) => onChange({ phoneNumber: e.currentTarget.value })} />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ p: 1 }}>
                                    <TCInput value={form.description} fullWidth={true} label={"Address"} type={'textarea'} onChange={(e) => onChange({ description: e.currentTarget.value })} />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ p: 1 }}>
                                    <TCInput label={"Password"} type={"password"} value={form.stock} onChange={(e) => onChange({ password: e.currentTarget.value })} />
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ p: 1 }}>
                                    <TCInput label={"Confirm Password"} type={"password"} value={confirmPassword} onChange={(e) => setConfirmPassword(e.currentTarget.value)} />
                                </Grid>
                            </Grid>
                            <Grid container direction={"row"} sx={{
                                pl: 3
                            }}>
                                <Button
                                    variant="contained"
                                    onClick={() => { updateOrCreate() }}
                                    disabled={form.password === confirmPassword ? false : true}
                                >
                                    {form._id ? "Update Counter" : "Add Counter"}
                                </Button>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

