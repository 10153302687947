import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Players from 'views/pages/players/players';
import Courts from 'views/pages/courts/courts';
import AddCourts from 'views/pages/courts/addCourt';
import PackageList from 'views/pages/package/packageList';
import AddPackage from 'views/pages/package/addPackage';
import Orders from 'views/pages/orders/orders';
import Coupon from 'views/pages/coupons/coupons';
import AddCoupon from 'views/pages/coupons/addCoupon';
import Admins from 'views/pages/admins/admins';
import PlayerDetail from 'views/pages/players/playerDetail';
import AddOns from 'views/pages/addon/addons';
import AddAddOn from 'views/pages/addon/addAddon';
import Vouchers from 'views/pages/vouchers/vouchers';
import AddVoucher from 'views/pages/vouchers/addVoucher';
import AddClass from 'views/pages/classes/addClass';
import Counters from 'views/pages/settings/counters/counters';
import AddCounter from 'views/pages/settings/counters/addCounter';
import AddAdmin from 'views/pages/admins/addAdmin';
import Categories from 'views/pages/settings/categories/categories';
import AddCategories from 'views/pages/settings/categories/addCategories';
import QRReader from 'views/pages/orders/qrReader';
import OrderDetail from 'views/pages/orders/orderDetail';
import CreateOrder from 'views/pages/orders/createOrder';
import Payments from 'views/pages/payment/payments';
import SaleReport from 'views/pages/report/SaleReport/SaleReport';
import { reactLocalStorage } from 'reactjs-localstorage';

const Coaches = Loadable(lazy(() => import('views/pages/coaches/coaches')));
// const AddCoaches = Loadable(lazy(() => import('views/pages/coaches/addCoaches')));
const Classes = Loadable(lazy(() => import('views/pages/classes/classes')));

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/pages/dashboard/Dashboard')));

// ==============================|| MAIN ROUTING ||============================== //

const isLoggedIn = reactLocalStorage.get('user_token');

const AdminRoutes = {
  path: '/',
  element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
  children: [
    {
        path: '/',
        element: <DashboardDefault />
    },
    {
        path: 'dashboard',
        children: [
            {
                path: '',
                element: <DashboardDefault />
            }
        ]
    },
    {
        path: 'courts',
        element: <Courts/>
    },
    {
        path: 'courts/add',
        element: <AddCourts/>
    },
    {
        path: 'courts/edit/:courtsId',
        element: <AddCourts/>
    },
    {
        path: 'orders',
        element: <Orders/>
    },
    {
        path: 'orders/create-order',
        element: <CreateOrder/>
    },
    {
        path: 'orders/:id',
        element: <OrderDetail/>
    },
    {
        path: 'users',
        element: <Players/>
    },
    {
        path: 'users/detail/:playerId',
        element: <PlayerDetail/>
    },
    {
        path: 'coaches',
        element: <Coaches/>
    },
    {
        path: 'classes',
        element: <Classes />
    },
    {
        path: 'classes/add',
        element: <AddClass />
    },
    {
        path: 'classes/edit/:classId',
        element: <AddClass />
    },
    {
        path: 'payments',
        element: <Payments />
    },
    {
        path: 'admins',
        element: <Admins/>
    },
    {
        path: 'admins/add',
        element: <AddAdmin/>
    },
    {
        path: 'admins/edit/:adminId',
        element: <AddAdmin/>
    },
    {
        path: 'packages',
        element: <PackageList/>
    },
    {
        path: 'packages/add',
        element: <AddPackage/>
    },
    {
        path: 'packages/edit/:packageId',
        element: <AddPackage/>
    },
    {
        path: 'coupons',
        element: <Coupon/>
    },
    {
        path: 'coupons/add',
        element: <AddCoupon/>
    },
    {
        path: 'coupons/edit/:couponId',
        element: <AddCoupon/>
    },
    {
        path: 'addon',
        element: <AddOns/>
    },
    {
        path: 'addon/add',
        element: <AddAddOn/>
    },
    {
        path: 'addon/edit/:addonId',
        element: <AddAddOn/>
    },
    {
        path: 'vouchers',
        element: <Vouchers/>
    },
    {
        path: 'vouchers/add',
        element: <AddVoucher/>
    },
    {
        path: 'counter',
        element: <Counters/>
    },
    {
        path: 'counter/add',
        element: <AddCounter/>
    },
    {
        path: 'counter/edit/:counterId',
        element: <AddCounter/>
    },
    {
        path: 'categories',
        element: <Categories/>
    },
    {
        path: 'categories/add',
        element: <AddCategories/>
    },
    {
        path: 'categories/edit/:categoryId',
        element: <AddCategories/>
    },
    {
        path: 'qr-read',
        element: <QRReader/>
    },
    {
        path: 'sale-report',
        element: <SaleReport />
    },
  ]
};

export default AdminRoutes;