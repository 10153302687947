import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";
// import { Delete, Get, Patch, Post, Put } from "./RestClient.js";

import { FormatServerDate, client, errorValidation } from "./service.js";

export async function getReportSales(query = {
    page: 0,
    limit: 0,
    period: '',
    startDate: '',
    endDate: '',
    rangeDateType: '',
    orderType: '',
    orderStatus: '',
    addOnType: '',
    item: '',
}) {
    try {
        let {
            page, limit, period, startDate, endDate, rangeDateType, orderType, orderStatus, addOnType, item
        } = query
        let payments = await axios.Get({
            url: `${adminUrl}/report/sales?page=${page}&limit=${limit || 0}&startDate=${FormatServerDate(startDate)}&endDate=${endDate}&rangeDateType=${rangeDateType}&period=${period}&orderType=${orderType}&orderStatus=${orderStatus}&addOnType=${addOnType}&item=${item}`,
        });

        if (payments.statusCode !== 200) {
            return { error: payments.message };
        }

        return { data: payments.data };
    } catch (e) {
        return { error: e.response.data };
    }
}
export async function getReportSalesSummary(query = {
    period: '',
    startDate: '',
    endDate: '',
    rangeDateType: '',
    orderType: '',
    orderStatus: '',
    addOnType: '',
    item: '',
}) {

    try {
        let {
            period, startDate, endDate, rangeDateType, orderType, orderStatus, addOnType, item
        } = query
        let payments = await axios.Get({
            url: `${adminUrl}/report/sales-summary?startDate=${FormatServerDate(startDate)}&endDate=${endDate}&rangeDateType=${rangeDateType}&period=${period}&orderType=${orderType}&orderStatus=${orderStatus}&addOnType=${addOnType}&item=${item}`,
        });

        if (payments.statusCode !== 200) {
            return { error: payments.message };
        }

        return { data: payments.data };
    } catch (e) {
        return { error: e.response.data };
    }
}
