import React from "react";

import { Box, Button, Card, Dialog, Grid, Typography } from '@mui/material';
import { TCInput, TCSelect } from "components/TopCourtComponents/Input/TopCourtInput";
import { orderStatusOption, orderTypeOption, providerOption } from "constant/constant";
import NumberRangeInput from "components/TopCourtComponents/Input/NominalRangeFilter";
import DateRangeInput from "components/TopCourtComponents/Input/DateRangeFilter";

export default function PaymentFilter({
    filter,
    onChangeFilter,
    clearFilter
}) {
    return (
        <Card sx={{ mb: 1 }}
            className="custom-card-style">
            <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                {/* filter date, booking id, user, type, status */}
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} md={3} sx={{ mb: 1 }}>
                        <DateRangeInput
                            label={'Payment Date'}
                            showLabel={true}
                            startDate={filter.paymentDateStart}
                            endDate={filter.paymentDateEnd}
                            dateRangeType={filter.paymentDateType}
                            onChangeSelect={(e) => onChangeFilter({ paymentDateType: e })}
                            onChangeStartDate={(e) => onChangeFilter({ paymentDateStart: e })}
                            onChangeEndDate={(e) => onChangeFilter({ paymentDateEnd: e })}
                        />
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ mb: 1 }}>
                        <TCInput label={"Booking ID"} placeHolder={'Booking ID'} onChange={(e) => onChangeFilter({ referenceId: e.currentTarget.value })} value={filter.referenceId} />
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ mb: 1 }}>
                        <TCSelect label={'Provider'} placeHolder={'Select Provider'} options={providerOption} value={filter.provider} onChange={(e) => onChangeFilter({ provider: e })} />
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ mb: 1 }}>
                        <TCSelect label={'Type'} placeHolder={'Select Type'} options={orderTypeOption} value={filter.type} onChange={(e) => onChangeFilter({ type: e })} />
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ mb: 1 }}>
                        <NumberRangeInput
                            showLabel={true}
                            label={"Amount"}
                            placeholderStart={'Min'}
                            placeholderEnd={'Max'}
                            allowZero={false}
                            numberRangeType={filter.amountType}
                            start={filter.amountMin}
                            end={filter.amountMax}
                            onChangeSelect={(e) => onChangeFilter({ amountType: e })}
                            onChangeStart={(e) => onChangeFilter({ amountMin: e.replace(/[^0-9]+/ig, '') })}
                            onChangeEnd={(e) => onChangeFilter({ amountMax: e.replace(/[^0-9]+/ig, '') })}
                        />
                    </Grid>
                    <Grid item xs sx={{ mb: 1, textAlign: 'right' }} >
                        <Button sx={{ mt: 2, border: '0.5px solid #2196f3', borderRadius: 4 }}
                            onClick={clearFilter}
                        >Clear</Button>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    )
}