import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { Box, Card, Grid, Typography, Avatar, Button, Switch } from '@mui/material';

// project imports

import { getVouchers } from 'services/voucherServices';
import moment from 'moment';
import { TCLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';
import TableComponent from 'components/TopCourtComponents/Table/TableComponent';
import VoucherFilter from './voucherFilter';
import { TypeSelectOption } from 'components/TopCourtComponents/Input/DateRangeFilter';

const initFilter = {
    voucherCode: '',
    usedBy: null,
    expiryDateType: TypeSelectOption[0],
    expiryDateStart: null,
    expiryDateEnd: null,
    status: null
}

export const courtTypeObj = {
    all_court: "All Court",
    indoor: "Indoor",
    outdoor: "Outdoor",
    hitting_room: "Hitting"
}

export default function Vouchers({

}) {
    const [rows, setRows] = useState([])
    const [filter, setFilter] = useState(initFilter)

    useEffect(() => {
        loadData()
    }, [filter])

    // useEffect(() => {

    // }, [rows])

    // function GoToPage({ page
    // }) {
    //     const navigate = useNavigate()
    //     navigate(page)
    // }

    const loadData = async () => {
        try {
            let { data, error } = await getVouchers({
                code: filter.voucherCode ? filter.voucherCode : '',
                usedBy: filter.usedBy ? filter.usedBy.value : '',
                expiryDateType: filter.expiryDateType ? filter.expiryDateType.value : '',
                expiryDateStart: filter.expiryDateStart ? filter.expiryDateStart : '',
                expiryDateEnd: filter.expiryDateEnd ? filter.expiryDateEnd : '',
                isRedeemed: filter.status ? (filter.status.value === true ? false : true) : ''
            });
            if (error) throw error
            if (data) {
                setRows(data)
            }
        } catch (error) {
            console.log('ini cek error', error)
        }
    }
    const valueCustomator = (row) => {
        return ({
            createdDate: <TCLabel>{moment(row.createdDate).format("DD/MM/YYYY HH:mm:ss")}</TCLabel>,
            code:<TCLabel>{row.voucherCode}</TCLabel>,
            session:<TCLabel>{`${courtTypeObj[row.courtType]} ${row.session}`}</TCLabel>,
            sessionValidity:<TCLabel>{`Month + ${row.validity ? row.validity : 3}`}</TCLabel>,
            status: <TCLabel>{row.isRedeemed ? "Not Available" : "Available"}</TCLabel>,
            assignedUser: <TCLabel>{row.user ? row.user.name : "-"}</TCLabel>,
            expiryDate: <TCLabel>{moment(row.expiryDate).format("DD/MM/YYYY")}</TCLabel>,
            usedBy: <TCLabel>{(row.redeemDate && row.user) ? row.user.name : "-"}</TCLabel>,
            redeemDate: <TCLabel>{row.redeemDate ? moment(row.redeemDate).format("DD/MM/YYYY HH:mm:ss") : "-"}</TCLabel>,
        })
    }

    const onChangeFilter = (e) => {
        setFilter(prev => ({
            ...prev,
            ...e
        }))
    }

    const clearFilter = () => {
        setFilter(initFilter)
    }

    return (
        <>
            <Button sx={{ mb: 2 }} variant="contained" disableElevation onClick={() => {
                window.location.href = "/vouchers/add"
            }}>Create Voucher</Button>
            <VoucherFilter filter={filter} onChangeFilter={onChangeFilter} clearFilter={clearFilter} />
            <TableComponent
                columns={[
                    { label: "Created Date"},
                    { label: "Voucher Code" },
                    { label: "Session" },
                    { label: "Session Validity" },
                    { label: "Status" },
                    { label: "Assigned User" },
                    { label: "Expiry Date" },
                    { label: "User" },
                    { label: "Redeem Date" },
                ]}
                rows={rows}
                valueCustomator={valueCustomator}
            />
        </>
    )
}