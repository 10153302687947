import React, { useState, useEffect } from 'react';

// material-ui
import { Box, Card, Grid, Typography, Avatar, Button, Switch } from '@mui/material';

// project imports

import { getAddons } from 'services/addonServices';
import { AddonTypeOption, activeOption } from 'constant/constant';
import { currencyFormat } from 'helper/NumberFormat';
import TableComponent from 'components/TopCourtComponents/Table/TableComponent';
import { TCLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';
import { TypeSelectOption } from 'components/TopCourtComponents/Input/NominalRangeFilter';
import AddonFilter from './addonFilter';

const initFilter = {
    name: '',
    type: null,
    stockType: TypeSelectOption[0],
    stockMin: '',
    stockMax: '',
    type: [AddonTypeOption[0]],
    status: activeOption[1]
}

export default function AddOns({

}) {

    const filterAddOns = localStorage.getItem("filter_add_ons") ? JSON.parse(localStorage.getItem("filter_add_ons")) : null
    const [rows, setRows] = useState([])
    const [filter, setFilter] = useState(filterAddOns ? filterAddOns : initFilter)

    useEffect(() => {
        localStorage.setItem("filter_add_ons", JSON.stringify(filter))
        loadData()
    }, [filter])

    const loadData = async () => {
        try {
            let { data, error } = await getAddons({
                name: filter.name? filter.name : '',
                type: filter.type? filter.type.map(v => { return v.value }) : '',
                stockType: filter.stockType? filter.stockType.value: '',
                stockMin: filter.stockMin? filter.stockMin : '',
                stockMax: filter.stockMax? filter.stockMax: '',
                isActive: filter.status? filter.status.value: ''
            });
            if (error) throw error
            if (data) {
                setRows(data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const valueCustomator = (row) => {
        return ({
            name: <span className='span-link' onClick={() => { window.location.href = `/addon/edit/${row._id}` }}>{row.name}</span>,
            image: <img style={{ maxWidth: 100, maxHeight: 50 }} src={row.itemImages}></img>,
            type: <TCLabel>{AddonTypeOption.map((v) => { if (v.value === row.type) return v.label })}</TCLabel>,
            price: <TCLabel>{currencyFormat(row.price)}</TCLabel>,
            stock: <TCLabel>{row.stock}</TCLabel>,
            status: <Switch checked={row.isActive} />,
        })
    }

    const onChangeFilter = (e) => {
        setFilter(prev => ({
            ...prev,
            ...e
        }))
    }

    const clearFilter = () => {
        setFilter(initFilter)
    }

    return (
        <>
            <Button sx={{ mb: 2 }} variant="contained" disableElevation onClick={() => {
                window.location.href = "/addon/add"
            }}>Create Add-On</Button>
            <AddonFilter filter={filter} onChangeFilter={onChangeFilter} clearFilter={clearFilter} />
            <TableComponent
                columns={[
                    { label: "Name" },
                    { label: "Image" },
                    { label: "Type" },
                    { label: "Price" },
                    { label: "Stock" },
                    { label: "Status" },
                ]}
                rows={rows}
                valueCustomator={valueCustomator}
            />
        </>
    )
}