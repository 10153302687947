import React from "react";

import { Box, Button, Card, Dialog, Grid, Typography } from '@mui/material';
import { TCInput, TCSelect } from "components/TopCourtComponents/Input/TopCourtInput";

import DateRangeInput from "components/TopCourtComponents/Input/DateRangeFilter";
import NumberRangeInput from "components/TopCourtComponents/Input/NominalRangeFilter";
import { genderOptions, optionStatus, userStatus } from "constant/constant";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";

export const TypeSelectOption = [
    { value: "ib", label: "<>" },
    { value: "eq", label: "=" },
    { value: "lt", label: "<" },
    { value: "gt", label: ">" },
];

function PlayerFilter ({
    filter,
    onChangeFilter,
    clearFilter
}) {

   return (
    <Card sx={{
        mb: 1
    }}>
        <Box
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
                {/* filter date, booking id, user, type, status */}
                <Grid container columnSpacing={2} rowSpacing={1} sx={{
                    p: 2,
                }}>
                    <Grid item xs={12} md={2}>
                        <TCInput placeHolder="Name" onChange={(e) => onChangeFilter({name: e.currentTarget.value})} value={filter ? filter.name : ''} label={"Name"}/>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TCInput placeHolder="Phone" onChange={(e) => onChangeFilter({phoneNumber: e.currentTarget.value})} value={filter ? filter.phoneNumber : ''} label={"Reg Phone Number"}/>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TCSelect placeHolder="Gender" onChange={(e) => onChangeFilter({gender: e})} value={filter ? filter.gender : ''} label={"Gender"} options={genderOptions}/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        {/* <TCInput placeHolder="Filter Birth Year" onChange={(e) => onChange({gender: e.currentTarget.value})} value={filter ? filter.name : ''} label={"Birth Year"}/> */}
                        <DateRangeInput showLabel={true} label={"Birth Year"}
                            startDate={filter.birthYearStart}
                            dateRangeType={filter.birthYearType}
                            endDate={filter.birthYearEnd}
                            format={"yyyy"}
                            onChangeSelect={(event, load) => onChangeFilter({birthYearType: event})}
                            onChangeStartDate={(date, load) => { onChangeFilter({birthYearStart: date}) }}
                            onChangeEndDate={(date, load) => { onChangeFilter({birthYearEnd: date}) }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        {/* <TCInput placeHolder="Filter Register Date" onChange={(e) => onChange({gender: e.currentTarget.value})} value={filter ? filter.name : ''} label={"Register Date"}/> */}
                        <DateRangeInput showLabel={true} label={"Register Date"}
                            startDate={filter.registerDateStart}
                            dateRangeType={filter.registerDateType}
                            endDate={filter.registerDateEnd}
                            onChangeSelect={(event, load) => onChangeFilter({registerDateType: event, load})}
                            onChangeStartDate={(date, load) => { onChangeFilter({registerDateStart: date, load}) }}
                            onChangeEndDate={(date, load) => { onChangeFilter({registerDateEnd: date, load}) }}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TCSelect placeHolder="User Status" onChange={(e) => onChangeFilter({userStatus: e})} value={filter ? filter.userStatus : ''} options={userStatus} label={"User Status"}/>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TCSelect placeHolder="Subscribe Status" onChange={(e) => onChangeFilter({subscribeStatus: e})} value={filter ? filter.subscribeStatus : ''} options={optionStatus} label={"Subscribe Status"}/>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TCSelect placeHolder="Academy Status" onChange={(e) => onChangeFilter({academyStatus: e})} value={filter ? filter.academyStatus : ''} options={optionStatus} label={"Academy Status"}/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        {/* <TCInput placeHolder="Filter Class Status" onChange={(e) => onChange({status: e.currentTarget.value})} value={filter ? filter.name : ''} label={"Class Status"}/> */}
                        <NumberRangeInput 
                            showLabel={true} 
                            label={"Class Status"}
                            allowZero={true}
                            numberRangeType={filter.classType}
                            start={filter.classStart}
                            end={filter.classEnd}
                            onChangeSelect={(e, load) => onChangeFilter({ classType: e, load })}
                            onChangeStart={(e, load) => onChangeFilter({ classStart:  e, load })}
                            onChangeEnd={(e, load) => onChangeFilter({ classEnd: e, load })}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        {/* <TCInput placeHolder="Filter Match Status" onChange={(e) => onChange({status: e.currentTarget.value})} value={filter ? filter.name : ''} label={"Match Status"}/> */}
                        <NumberRangeInput showLabel={true} label={"Match Status"}
                            allowZero={true}
                            numberRangeType={filter.matchType}
                            start={filter.matchStart}
                            end={filter.matchEnd}
                            onChangeSelect={(e, load) => onChangeFilter({ matchType: e, load })}
                            onChangeStart={(e, load) => onChangeFilter({ matchStart:  e, load })}
                            onChangeEnd={(e, load) => onChangeFilter({ matchEnd: e, load })}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        {/* <TCInput placeHolder="Session Balance" onChange={(e) => onChange({gender: e.currentTarget.value})} value={filter ? filter.name : ''} label={"Session Balance"}/> */}
                        <NumberRangeInput showLabel={true} label={"Session Balance"}
                            allowZero={true}
                            numberRangeType={filter.sessionType}
                            start={filter.sessionStart}
                            end={filter.sessionEnd}
                            onChangeSelect={(e, load) => onChangeFilter({ sessionType: e, load })}
                            onChangeStart={(e, load) => onChangeFilter({ sessionStart:  e, load })}
                            onChangeEnd={(e, load) => onChangeFilter({ sessionEnd: e, load })}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TCSelect placeHolder="Blacklist" onChange={(e) => onChangeFilter({isActive: e})} value={filter ? filter.isActive : ''} 
                            options={[
                                {label: "Yes", value: false},
                                {label: "No", value: true},
                            ]} label={"Blacklist"}/>
                    </Grid>
                    <Grid item xs={12} md={7} textAlign={"right"}>
                        {/* <TCInput placeHolder="Session Balance" onChange={(e) => onChange({gender: e.currentTarget.value})} value={filter ? filter.name : ''} label={"Session Balance"}/> */}
                        <Button
                            onClick={() => {
                                clearFilter()
                            }}
                            sx={{mt: 2, border: '0.5px solid #2196f3', borderRadius: 4}}
                        >Clear</Button>
                    </Grid>
                    {/* <Grid item xs={12} md={2}>
                        <TCInput placeHolder="Filter Gender" onChange={(e) => onChange({name: e.currentTarget.value})} value={filter ? filter.name : ''} label={"Regist Date"}/>
                    </Grid> */}
                </Grid>
        </Box>
    </Card>
   ) 
}

export default PlayerFilter